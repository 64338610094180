/**
 * @file
 * Block Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.block {
  .content {
  }
}

/* ---------------- CUSTOM -----------------*/
/*
  .block-[configuration.provider] { }
  .block-[plugin_id] { }
*/

#block-ppr-header-footer-refonte-header-footer-top-menu,
#block-ppr-header-footer-refonte-header-footer-search-top,
#block-ppr-header-footer-refonte-header-footer-lg-switch,
#block-ppr-header-footer-header-footer-stock {
  display: block;
}

#block-ppr-header-footer-header-footer-stock {
  background-color: #000;
  bottom: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  width: 177px;
  z-index: 999;
  .stock-block {
    margin-right: 40px;
    margin-top: 0;
    p {
      color: #ffffff;
      margin: 0;
    }
    .stock-block-value {
      font-family: 'gotham-black', Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: normal;
    }
  }
  .content {
    padding-top: 66px;
    @media screen and (max-width: 1439px) {
      padding-top:46px;
    }
  }
}

#block-ppr-header-footer-refonte-header-footer-lg-switch,
#block-ppr-header-footer-header-footer-header-socials {
  .content {
    padding-top: 60px;
    @media screen and (max-width: 1439px) {
      padding-top:40px;
    }
  }
}
@media screen and (max-width: 767px) {
  #block-ppr-header-footer-header-footer-stock,
  #block-ppr-header-footer-refonte-header-footer-lg-switch {
    display: none;
  }
}

#block-ppr-header-footer-refonte-header-footer-top-menu {
  .content ul {
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
    @media screen and (min-width: 1025px) and (max-width: 1279px) {
      text-align: left;
      /*li{
         margin-left: 2%;
         padding-left: 0;
         padding-right: 0;
         a{
           font-size: 11px;
         }
       }*/
    }
    @media screen and (min-width: 769px) and (max-width: 1023px) {
      li {
        margin-left: 5%;
        a {
          &.selectedMenu {
            padding-bottom: 17px;
          }
        }
      }
      &.menu {
        li ul.maintainHover {
          li {
            ul {
              li {
                a {
                  font-size: 13px;
                }

              }
            }
          }
          li.governance {
            margin-bottom: 80px;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      background-color: #fff;
      text-align: left;
      ul.menu li {
        padding: 0;
        overflow-y: hidden;
        &:hover {
          padding: 0;
        }
        a {
          padding: 10px 0 20px 0;
          &:hover {
            background: none;
            padding: 10px 0 20px 0;
          }
        }
        ul.maintainHover {
          display: none !important;
          padding: 0 !important;
        }
        &.open {
          overflow-y: visible;
          ul  {
            width: auto;
            display: block !important;
            position: relative;
            top: auto;
            left: auto;
            height: auto;
            padding: 0;
            margin: 0;
            border-top: 0 none;
            background-image: none;
          }
        }
      }
      li {
        float: none !important;
        margin: 0;
        width: auto;
        display: block;
        padding: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        a {
          padding: 30px 0 30px 0;
          display: block;
          font-family: gotham-medium;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;
          margin: 0;
          white-space: normal;
        }
      }
    }
  }
  ul.menu {
    li {
      ul.maintainHover {

        li a {
          font-size: 16px;
          ul {
            margin-top: 12px;
          }
        }
        @media screen and (min-width: 1025px) and (max-width: 1279px) {
          li.governance {
            margin-bottom: 75px;
          }
        }
      }
      @media screen and (min-width: 769px) and (max-width: 1023px) {
        &:first-of-type {
          margin-left: 0;
        }
      }
      @media screen and (max-width: 767px) {
        &.open {
          ul.maintainHover {
            width: auto;
            display: block;
            position: relative;
            top: auto;
            left: auto;
            height: auto;
            padding: 0;
            margin: 0;
            border-top: 0 none;
            background-image: none;
          }
          .content {
            display: block;
            position: absolute;
            top: 79px;
            left: 0;
            z-index: 999;
            background: #ffffff url("../images/background.png");
            width: 100%;
          }
        }
        ul.maintainHover {
          display: none;
          li {
            width: auto;
            display: block;
            padding: 0;
            float: none !important;
            margin: 0;
            a {
              padding: 10px 20px 20px 20px;
              display: block;
              font-family: gotham-medium;
              font-size: 14px;
              text-transform: uppercase;
              margin: 0;
              &.selectedMenu {
                background: none;
                padding-bottom: 30px;
              }
            }
            ul {
              margin-top: 0;
            }
            &.menu-reporting, &.menu-governance {
              position: relative;
              top: initial;
              left: initial;
              width: auto;
              margin-left: initial;
            }
            &.menu-reporting {
              right: 0;
            }
            &.menu-videos {
              bottom: 17%;
              left: 0;
            }
            &.menu-foundation {
              bottom: 15%;
              left: 0;
            }
          }
          #regulated-information {
            position: relative;
            width: auto;
            margin-left: 0;
            bottom: 0;
          }
        }
        &:hover {
          ul {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      #menu-sustainability {
        ul.maintainHover li {
          float: none;
          margin: 0;
        }
      }

      ul {
        a {
          color: #7f7e7e;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    background: url("../images/mobile-menu-little-refonte.png") no-repeat 10px 20px;
    width: 65px;
    height: 80px;
    margin-left: 10px;

    /*width: 148px;
    height: 124px;
    margin-left: 20px;*/
    cursor: pointer;
    float: left;
    left: 0;
    .content {
      display: none;
    }
    &.open {
      background: url("../images/mobile-menu-close-little-refonte.png") no-repeat 10px 20px;
      .content {
        display: block;
        position: absolute;
        top: 79px;
        left: -20px;
        z-index: 999;
        background: #ffffff url(../images/background.png);
        width: calc(100% + 20px);
        & > ul.menu {
          margin-left: 19px;
          width: calc(100% - 19px);
          li.open {
            ul {
              display: block;
            }
          }
        }
        & > div.social {
          margin-left: 19px;
          width: calc(100% - 19px);

          ul {
            background: #0d0d0d -690px 0;
            text-align: center;
            padding: 0  0 0 40px;
            box-sizing: border-box;
            li {
              padding: 20px 40px 20px 0 !important;
              display: inline-block;
              box-sizing: border-box;
              a {
                background: url("../images/kering-refonte-sprite.png") 295px 110px;
                color: transparent;
                display: block;
                height: 30px;
                text-indent: -9999px;
                width: 26px;
                padding: 0;
              }
              &.twitter {
                a {
                  background-position: 190px 110px;
                }
              }
              &.sinaweibo {
                a {
                  background-position: 331px 242px;
                  height: 21px;
                  padding-top: 0;
                  width: 41px;
                }
              }
              &.youku a {
                background-position: 388px 350px;
                height: 26px;
                width: 22px;
              }
              &.instagram a {
                background-position: 242px 110px;
              }
              &.linkedin a {
                background-position: 137px 110px;
              }
              &.youtube a {
                background-position: 84px 110px;
              }
            }
          }
        }
      }
    }

    #menu-luxury {
      width: auto;
      float: none;
      ul li {
        float: none;
        width: 100%;
      }
    }
    #menu-sport-lifestyle, #menu-kering-eyewear {
      width: auto;
      padding: 0;
      margin: 0;
    }
  }
}
#block-ppr-header-footer-header-footer-header-socials {
  position: absolute;
  right: -171px;
  width: 500px;
  z-index: 800;
  height: 100%;

  @media screen and (max-width: 767px) {
    display: none;
  }
  ul {
    li {
      margin: 0 0 0 24px;
      padding: 0;
      a {
        background-image: url("../images/kering-refonte-sprite.png");
        color: transparent;
        display: block;
        height: 30px;
        text-indent: -9999px;
        width: 26px;
      }
      &.facebook a {
        background-position: 295px 107px;
      }
      &.twitter a {
        background-position: 190px 107px;
      }
      &.sinaweibo a {
        background-position: 408px 348px;
        height: 24px;
        padding-top: 0;
        width: 41px;
      }
      &.youku a {
        background-position: 332px 242px;
        height: 24px;
        width: 41px;
      }
      &.instagram a {
        background-position: 242px 107px;
      }
      &.linkedin a {
        background-position: 137px 107px;
      }
      &.youtube a {
        background-position: 84px 107px;
      }
    }
  }
}
#block-ppr-header-footer-refonte-header-footer-lg-switch {
  width: 550px;
  right: -310px;
  z-index: 800;
}

#block-bean-brand-block {
  padding: 120px 20px 60px 0;
  box-sizing: border-box;
  max-width: 70%;
  margin: 0;
  @media screen and (max-width: 1023px) {
    padding: 60px 20px 50px 0;
  }
}

@media screen and (max-width: 767px) {
  .i18n-zh-hans #block-ppr-header-footer-refonte-header-footer-top-menu.open .content {
    top: 120px;
  }
}