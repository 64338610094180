/**
 * @file
 * Footer Styling
 */

/* ---------------- DEFAULT -----------------*/
footer {
  color: #fff;
  background-color: #000;
  min-height: 245px;
  width: 100%;
  /*pied de page*/
  .footer-wrapper {
    position: relative;
    margin: 0 auto;
    padding-left: 65px;
    padding-top: 65px;
    box-sizing: border-box;
    position: relative;
    width: 1122px;
    max-width: 100%;
    @media screen and (max-width: 1023px) and (min-width: 768px) {
      width: 82%;
      padding-left: 0;
    }
    @media screen and (max-width: 767px) {
      height: 245px;
      padding-top:0;
      padding-left: 0;
    }
    &:before {
      bottom: 117px;
      content: "";
      height: 39px;
      position: absolute;
      right: 26px;
      width: 119px;
      background-image: url("../images/logo-kering-footer.png");
      background-size: 100% auto;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        right: auto;
        left: 56.5%;
      }
      /*bottom: 360px;
      right: 0;*/
      @media screen and (max-width: 767px) {
        top: 144px !important;
        left: 0;
        position: absolute;
      }
    }
    &.footer-zh-hans {
      #block-ppr-header-footer-header-footer-footer-socials {
        top: 135px;
        right: 0;
        ul {
          li {
            margin: 0 0 0 24px;
            padding: 0;
            &.facebook, &.instagram, &.twitter, &.youtube {
              display: none;
            }
          }
        }
      }
      &:before {
        height: 42px;
        width: 128px;
        top: 65px;
        right: 0;
        left: auto;
        bottom: auto;
      }
      #block-simplenews-410 {
        position: absolute;
        right: 0;
        top: 278px;
        &:before {
          background: url("../images/kering-refonte-sprite.png") 191px 343px;
          bottom: 12px;
          content: "";
          height: 92px;
          position: absolute;
          right: 0;
          width: 92px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          left: 52%;
          bottom: 242px;
          margin-left: 12px;
          &.block-simplenews {
            h3 {
              margin-right: 10px;
              font-size: 8px;
            }
            #newsletter-register {
              margin-right: 10px;
              font-size: 8px;
            }
          }
        }
      }
    }
    &.footer-ja {
      #block-ppr-header-footer-header-footer-footer-socials {
        display: none;
      }
      #block-simplenews-410 {
        top: 80px;
      }
      &:before {
        top: 65px;
        right: 0;
        left: auto;
        bottom: auto;
      }
    }
    //footer-socials
    #block-ppr-header-footer-header-footer-footer-socials {
      bottom: 74px;
      position: absolute;
      right: 15px;
      ul, ul li {
        list-style: none none;
        margin: 0;
        padding: 0;
      }
      ul li {
        display: inline-block;
        margin: 0 10px;
        a {
          background-image: url(../images/kering-refonte-sprite.png);
          color: transparent;
          display: block;
          height: 18px;
          text-indent: -9999px;
          width: 18px;
        }
        &.facebook {
          a {
            background-position: 298px 345px;
            &:hover {
              background-position: 179px -98px;
            }
          }
        }

        &.twitter {
          a {
            background-position: 298px 258px;
            &:hover {
              background-position: 193px -117px;
            }
          }
        }
        &.sinaweibo {
          a {
            background-position: 388px 346px;
            width: 20px;
          }
        }
        &.youku {
          a {
            background-position: 331px 241px;
            width: 39px;
          }
        }
        &.instagram {
          a {
            background-position: 298px 326px;
            &:hover {
              background-position: 191px -161px;
            }
          }
        }
        &.linkedin {
          a {
            background-position: 298px 306px;
            &:hover {
              background-position: 171px -140px;
            }
          }
        }
        &.youtube {
          a {
            background-position: 298px 284px;
            &:hover {
              background-position: 191px -182px;
            }
          }
        }
      }
    }
    /*footer-legals*/
    .footer-legals {
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 10px;
      margin: 90px 0 53px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .footer-links {
      position: absolute;
      right: 10px;
      bottom: 35px;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          height: 12px;
          padding-left: 15px;
          padding-right: 15px;
          vertical-align: middle;
          a {
            font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
            font-size: 8px;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            &:hover {
              color: #ebe9ea;
            }
          }
          &:nth-child(2) {
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
          }
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
        left: 51.5%;
        bottom: 175px;
        ul {
          padding-left: 0;
        }
      }
    }
    .footer-nav-block4 {
      display: none;
    }
    /*footer-newsletter*/
    fieldset.footer-newsletter {
      border: 1px solid;
      fieldset.footer-newsletter {
        input[type="submit"] {
          background: #000 url("../images/kering-refonte-sprite.png") 363px 343px;
          border: medium none;
          color: transparent;
          height: 15px;
          margin-right: 10px;
          padding: 0;
          width: 13px;
          &:hover {
            background-position: 191px -205px;
          }
        }
        #newsletter-register {
          background-color: #000;
          border: medium none;
          color: #fff;
          position: relative;
          font-family: 'gotham-light', Helvetica, Arial, sans-serif;
          font-size: 10px;
          padding-bottom: 10px;
          padding-left: 10px;
          padding-top: 10px;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      &:before {
        left: 54%;
        bottom: 260px;
        right: 0;
      }
      #block-ppr-header-footer-header-footer-footer-menu {
        > .content > ul {
          > .expanded {
            margin-top: 70px;
          }
          > #menu-group, > #menu-brand, > #menu-sustainability {
            margin-top: 0;
          }
        }
      }
      #block-ppr-header-footer-header-footer-footer-socials {
        left: 52%;
        bottom: 210px;
      }
      &.footer-zh-hans {
        #block-ppr-header-footer-header-footer-footer-menu {
          ul {
            margin-right: 128px;
            li {
              margin-top: 0;
              margin-bottom: 15px;
              width: 126px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 10px auto 0;
      &:before {
        bottom: auto;
        left: 0;
        right:0;
        top: 177px !important;
        margin: auto;
        display: block;
        position: relative;
      }
      &.footer-zh-hans:before {
        background-position: 125px -293px;
        height: 41px;
        top: 224px;
        width: 125px;
      }
      .language_switcher {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          float: right;
          text-align: center;
          li.header-toolbar-language-switcher {
            ul {
              line-height: normal;
              display: inline-block;
              vertical-align: middle;
              float: none;
              li {
                display: inline-block;
                font-size: 16px;
                margin: 0 0 0 10px;
                &.label {
                  width: 100%;
                }
                a {
                  padding: 5px 10px;
                  text-decoration: none;
                  font-weight: bold;
                }
              }
            }
          }
          .languages_list {
            display: none;
            top: 92px;
            width: 100%;
            position: absolute;
            left: 0;
            height: 80px;
            line-height: 80px;
          }
          .choose {
            .languages_list {
              display: block;
            }
          }
        }
        span.current_language {
          background: none;
          padding: 0;
          margin: 0;
          font-weight: normal;
          height: auto;
          cursor: pointer;
        }
      }
    }
  }
  .footer-mob {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 30px;
      #block-ppr-header-footer-header-footer-mobile-stock {
        float: left;
        width: 30%;
        line-height: 16px;
        font-size: 17px;
        margin-left: 3%;
        a {
          color: #fff;
          text-decoration: none;
          line-height: 17px;
        }
        p {
          color: #fff;
          font-family: 'gotham-book', Helvetica, Arial, sans-serif;
          font-size: 17px;
          line-height: 17px;
          margin: 0;
          padding: 0;
          &.stock-block-value {
            font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
          }
        }
      }
      #block-ppr-header-footer-header-footer-mobile-language {
        float: right;
        width: 27%;
        margin-right: 7%;
        line-height: 21px;
        font-size: 17px;
      }
      #block-ppr-header-footer-header-footer-mobile-sitemap {
        font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
        float: right;
        font-size: 17px;
        a {
          color: #fff;
          font-size: 18px;
          text-transform: uppercase;
          text-decoration: none;
          line-height: 21px;
        }
      }
    }
  }
  //menu footer
  .footer-wrapper.footer-ja, .footer-wrapper.footer-zh-hans {
    #block-ppr-header-footer-header-footer-footer-menu {
      width: 592px;
      ul {
        li {
          margin-top: 0;
          margin-bottom: 15px;
          width: 145px;
        }
      }
    }
  }
  #block-ppr-header-footer-header-footer-footer-menu {
    a {
      color: #fff;
      &.hover {
        color: #ebe9ea;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        vertical-align: top;
        padding: 0 15px 0 0;
        margin: 0;
        max-width: 180px;
        width: 15%;
        box-sizing: border-box;
        @media screen and (max-width: 1023px) and (min-width: 768px) {
          width: 33%;
          max-width: none;
        }
        &.kmag {
          display: none;
        }
        a {
          font-family: 'gotham-ultra', Helvetica, Arial, sans-serif;
          font-size: 10px;
          text-decoration: none;
          text-transform: uppercase;
        }
        ul {
          padding: 0;
          background-image: none !important;
          margin-top: 17px;
          li {
            margin-bottom: 6px;
            width: 100%;
            padding: 0;
            @media screen and (max-width: 1023px) and (min-width: 768px) {
              width: 100%;
            }
            a {
              font-family: 'gotham-book', Helvetica, Arial, sans-serif;
              text-transform: none;
            }
            ul {
              background-image: none !important;
              margin-top: 6px;
            }
          }
        }
        &.leaf {
          list-style: none none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
    .social {
      clear: left;
      padding-top: 170px;
      display: none;
      ul {
        padding: 0;
        text-align: center;
        li {
          display: inline-block;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 17%;
          a {
            background: url("../images/kering-refonte-sprite.png") 295px 110px;
            color: transparent;
            display: block;
            height: 30px;
            margin: auto;
            padding: 0;
            text-indent: -9999px;
            width: 26px;
          }
          &.twitter {
            a {
              background-position: 190px 110px;
            }
          }
          &.sinaweibo {
            a {
              background-position: 331px 243px;
              height: 24px;
              padding-top: 0;
              width: 41px;
            }
          }
          &.youku {
            a {
              background-position: 388px 350px;
              height: 26px;
              width: 22px;
            }
          }
          &.instagram {
            a {
              background-position: 242px 110px;
            }
          }
          &.linkedin {
            a {
              background-position: 137px 110px;
            }
          }
          &.youtube {
            a {
              background-position: 84px 110px;
            }
          }
        }
      }
    }
    #block-ppr-header-footer-header-footer-mobile-search-top.block.block-ppr-header-footer {
      display: none;
    }
    nav {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .i18n-zh-hans {
    .footer-wrapper.footer-zh-hans {
      #block-ppr-header-footer-header-footer-footer-socials {
        top: 85px + 65px;
        left: auto;
      }
      #block-simplenews-410 {
        position: absolute;
        right: 0;
        top: 218px + 65px;
        left: auto;
        &:before {
          background: url("../images/kering-refonte-sprite.png") 191px 343px;
          bottom: 12px;
          content: "";
          height: 92px;
          position: absolute;
          width: 92px;
        }
      }
    }
    #block-ppr-header-footer-header-footer-footer-menu {
      ul {
        li {
          a {
            font-family: gotham-ultra, Verdana, Arial, sans-serif;
            font-size: 10px;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
    .footer-legals {
      font-family: gotham-book;
      font-size: 10px;
      margin: 160px 0 53px;
    }

  }
  .i18n-ja {
    .footer-wrapper:before, .i18n-zh-hans .footer-wrapper:before {
      bottom: 360px;
      left: auto;
    }
    #block-simplenews-410 {
      left: auto;
      bottom: 20px;
      position: absolute;
      right: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .i18n-zh-hans, .i18n-ja {
    footer {
      .language_switcher {
        ul {
          .languages_list {
            display: none;
            top: 66px;
            width: 100%;
            position: absolute;
            left: 0;
            height: 80px;
            line-height: 80px;
          }
        }
      }
    }
  }
  .i18n-zh-hans {
    footer {
      .social {
        ul {
          li {
            &.twitter, &.youtube, &.facebook, &.instagram {
              display: none;
            }
          }
        }
      }
    }
    .footer-legals {
      font-family: 'AdobeHeitiStd-Regular', 'gotham-book', Arial, Verdana, 'SimSun';
      font-size: 10px;
      margin: 160px 0 53px;
    }
  }
}
.i18n-zh-hans #main-wrapper footer {
  @media screen and (min-width: 768px) {
    min-height: 305px;
  }
}