/**
 * @file
 * Search Result Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.search-results {
}

.search-result__title {
}

.search-result__snippet-info {
}

.search-result__snippet {
}

.search-result__info {
}

/* ---------------- CUSTOM -----------------*/



