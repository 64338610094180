/**
 * @file
 * Social Links Styling
 */

/* ---------------- Share Links  -----------------*/

/*
#block-ppr-header-footer-header-footer-header-socials {
  background-color: #000;
  right: -171px;
  width: 500px;
  z-index: 800;
}*/
#block-ppr-header-footer-header-footer-header-socials {
  background-color: #000;
  right: -171px;
  width: 500px;
  z-index: 800;
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style: none none;
    }
  }
  .content {
    padding-top: 77px;
    ul {
      display: none;
      margin-left: 35px;
      vertical-align: middle;
      margin-top: 5px;
    }
  }
}

.i18n-ja {
  #block-ppr-header-footer-header-footer-header-socials {
    right: -150px;
  }
}

/*
#block-ppr-header-footer-header-footer-header-socials ul {
  margin: 0;
  padding: 0;
}*/
/*
#block-ppr-header-footer-header-footer-header-socials ul li {
  display: inline-block;
  list-style: none none;
}

#block-ppr-header-footer-header-footer-header-socials .content {
  padding-top: 77px;
}

#block-ppr-header-footer-header-footer-header-socials .content ul {
  display: none;
  margin-left: 35px;
  vertical-align: middle;
  margin-top: 5px;
}*/
/*
button{
  &.social{
    background: url("../images/button_social.png") no-repeat;
    height: 36px;
    margin-left: 25px;
    width: 70px;
    border:1px solid red;
    &.open{
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      color: #fff;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 14px;
      margin-left: 27px;
      vertical-align: middle;
      width: 85px;
    }
  }
}

button.social.open {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #fff;
  font-family: 'gotham-book', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-left: 27px;
  vertical-align: middle;
  width: 85px;
}*/