/**
 * @file
 * Panel Styling
 */

/* ---------------- IMPORT -----------------*/



%box-33-percent {
  float: left;
  width: 366px;
  max-width: calc(33% - 15px);
  margin: 0 15px 15px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @media screen and (max-width: 1023px) {
    clear: none !important;
    float: left !important;
    margin: 0 15px 15px 0 !important;
    max-width: calc(46% - 15px) !important;
  }
  @media screen and (max-width: 767px) {
    float: none !important;
    margin: 15px auto 15px auto !important;
    height: auto !important;
    max-width: 100% !important;
    clear: both;
    .tier {
      height: auto !important;
    }
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
%box-66-percent {
  float: left;
  width: 745px;
  max-width: calc(66% - 15px);
  margin: 0 15px 15px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @media screen and (max-width: 1023px) {
    float: none !important;
    clear: both !important;
    margin: 15px 0 15px 0 !important;
    max-width: calc(92% - 15px) !important;
  }
  @media screen and (max-width: 767px) {
    float: none !important;
    margin: 15px auto 15px auto !important;
    height: auto !important;
    max-width: 100% !important;
    clear: both;
    .tier {
      height: auto !important;
    }
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
%box-66-video {
 @extend %box-66-percent;
  padding: 0;
}
%panel-column-33-percent {

  .panels-flexible-column {
    margin-bottom: 0 !important;
    & > .panels-flexible-column-inside {
      padding: 0;
      margin: 0;
      .panels-flexible-region {
        float: none;
        width: 100%;
      }
      .panel-separator {
        display: none;
      }
      .panel-pane {
        margin-bottom: 15px;
      }
    }
    @extend %box-33-percent;
    .tier {
      margin-right: 0;
      margin-top: 0;
      clear: both;
      float: none;
      width: 100%;
      max-width: 100%;
      @media screen and (max-width: 767px) {
        height: auto !important;
      }
    }
  }
  & > .panels-flexible-row-inside {
    & > .panels-flexible-region {
      &:after {
        content: "";
        display: table;
        clear: both;
      }
      .pane-ppr-rubrique-ppr-rubrique-news {
        @extend %box-33-percent;
        .tier {
          margin-right: 0;
          margin-top: 0;
          width: 100%;
          max-width: 100%;
        }
      }
      .panels-flexible-region-panel_front_talents-region_video-inside {
      }
    }
  }
}
#job-desktop,
#encourage-desktop,
#news-desktop,
#social-desktop,
.twitter-desktop {
  display: block;
}
#job-mobile,
#social-mobile,
#news-mobile,
#encourage-mobile,
.twitter-mobile {
  display: none;
}
,
#news-tablette,
#encourage-tablette,
#news-tablette,
#social-tablette,
.twitter-tablette {
  display: none;
}
.panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-3,
.panels-flexible-panel_front_talents  .panels-flexible-row-panel_front_talents-10,
.panel-flexible.front-finance .panels-flexible-row-front-finance-3 {
  max-width: 100%;
  margin-left: 90px !important;
  margin-right: 0;
  padding: 0;
  box-sizing: border-box;
  float: none;
  clear: both;
  @media screen and (max-width: 1439px) {
    margin-left:50px !important;
  }
  @media screen and (max-width: 767px) {
    margin-left:25px !important;
    margin-right: 25px;
  }
}
.pane-content .quarter {
  width: 100%;
  margin: 0 15px 15px 0;
  padding: 0 15px 30px 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 189px;
  p {
    font-size : 16px;
  }
}
.node-landing-page {
  .tier {
    position: relative;
    padding: 0 15px 30px 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: black;
    font-family: 'Radley', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 22px;
    background-color: #f8f7f7;
    &:hover {
      background-color: #ebe9ea !important;
    }
    p,
    p.block-content {
      margin: 20px 0 0 0;
      padding-bottom: 30px;
      color: black;
      font-size: 16px;
      line-height: 1.5em;
    }
    &.white-txt  {
      background-color: #0d0d0d !important;
      &:hover {
        background-color: #222222 !important;
      }
      p,
      h2,
      & h2.title-align-center,
      a {
        color: #ffffff !important;
      }

    }
    img {
      max-width: 100%;
      height: auto;
    }
    & > a {
      display: block;
      height: 100%;
    }
    a {
      cursor: pointer;
      &:hover {
        color: #a7a8ac;
      }
    }
  }
  // IMAGE
  .tier2-image-block {
    padding: 0;
    p {
      margin: 0;
      padding: 0;
    }
  }
  .tier > div,
  .tier > a > div,
  .quarter > div,
  .quarter > a > div,
  .quarter > h2,
  .quarter > a > h2,
  .quarter > .block-content,
  .quarter > a > .block-content,
  .quarter >  p,
  .quarter > a > p,
  .tier > h2,
  .tier > a > h2,
  .tier > .block-content,
  .tier > a > .block-content,
  .tier > a > p,
  .tier > p,
  .tier > ul.latest-news,
  .tier .left-top-new {
    width: 100%;
    margin: 20px auto 0;
    padding: 0 25px 0 35px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

  }
  .quarter.bourse {
    padding-left: 50px;
    div.stock-block,
    h2 {
      padding-left: 0;
    }
  }
  .panel-pane .quarter h2,
  .tier > h2,
  .tier > a > h2,
  .tier  h2.title-align-center {
    font-size: 14px;
    font-family: 'gotham-black', Helvetica, Arial, sans-serif;
    font-weight: normal;
    background: none;
    text-align: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 15px;
    margin: 0;
    color: black;
    text-transform: uppercase;
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      font-size: 18px;
      padding-top: 40px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 50px;
      font-size: 20px;
      line-height: 1em;
    }
    @media screen and (max-width: 450px) {
      padding-top: 30px;
      font-size: 16px;
      line-height: 1em;
    }
  }
  .tier.tier-half-height {
    p {
      max-width: 100%;
      font-family: 'Radley', Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 15px;
      text-align: left;
      color: black;
    }
    a {
      color: transparent;
    }
  }
  .pane-bean-video-ppr-bands-emotion {
    @extend %box-66-video;
  }
  // VIDEO
  .tier.rubrique_video_size {
    height: auto !important;
    padding: 0;
    #title_video_description {
      font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 22px;
      color: white;
      padding: 15px;
      text-transform: none;
      margin: 0;
      background: none;
      text-align: left;
      font-weight: normal;
    }
    .description {
      float: none;
      padding: 0;
      width: initial;
      margin-top: 0 !important;
    }
    div.video_with_description {
      margin: 0 auto;
      width: 100%;
      padding : 0 0 56.25% 0; /* 16:9 */
      position: relative;
      background: black;
      text-align: center;
      iframe,
      .ppr-player {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0; bottom: 0; left: 0; right: 0;
        color: white;
        font-size: 24px;
        text-align: center;
      }
    }
  }
}





/* BRAND PAGE */
.node-type-brands {
  .section-content {
    .pane-block {
      @extend %box-33-percent;
      &.pane-ppr-rubrique-ppr-rubrique-luxury-brands,
      &.pane-ppr-rubrique-ppr-rubrique-sports-brands {
        .tier {
          height: 420px !important;
        }
      }
    }
    .pane-bean-panels.last  {
      @extend %box-66-video;
    }

  }

  .tier.brands-block,
  .tier.news-block {
    height: 420px;
  }

  .tier.rubrique_video_size {
    margin-top: 0;
    .description {
      display: none;
    }
  }
}
.tier {
  a {
    color: #000;
    text-decoration: none;
  }

  ul.latest-news > li {
    list-style: none;
    font-size: 11px;
    line-height: 1em;
    ul {
      padding: 0;
      margin: 15px 0 0 0;
      li {
        list-style: none;
        margin: 0;
      }
    }
    p {
      margin: 5px 0 0 0;
      padding: 0;
      line-height: 1.2em;
    }
  }
  .latest-news-infos {
    li {
      margin-bottom: 15px;
      list-style: none;
      display: inline-block;
      font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
    }
    li.brand-concern {
      text-transform: uppercase;
    }
  }
  .date,
  .brand-concern {
    font-size: 11px !important;
    font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
  }
  .chapo {
    line-height: 1.2em !important;
  }
}


/* End of Page customization */

.tier-block1 ul.latest-news > li {
  list-style-type: none;
  font-size: 1.1em;
  padding: 0 0 15px 0;
  margin-top: 14px;
}
/* SUSTAINABILITY  PAGE */
.tier.news-one-top-block {

  & > h2 {
    padding-left: 35px;
  }
  .left-top-new,
  .right-list-news {
    display: inline-block;
    width: calc(50% - 35px);
    vertical-align: top;
    padding-right: 0;
    ul {
      padding: 0;
      margin: 0;
    }

  }
  .left-top-new {
    img {
      max-width: 100%;
      width: 270px;
      height: auto;
      padding-right: 0;
    }

  }
  .right-list-news {

  }
  .right-list-news {
  .latest-news > li  {
    margin-top: 0;
    & + li {
      margin-top: 35px;
    }

  }
  }
  p.date {
    margin: 15px 0 0 0;
    padding: 0;
  }
  p.chapo {
    margin: 7px 0 0 0;
  }

}

.node-type-sustainability {
  .section-content {
    .panel-pane {
      @extend %box-66-percent;
    }
    .panel-pane.image_top,
    .panel-pane.objectifs_block,
    .panel-pane.kering_fondation_block {
      @extend %box-33-percent;
    }
    .tier {
      height: 460px;
      li.slider-wrapper {
        list-style: none;
        img {
          display: block;
          margin: auto;
        }
      }
    }
  }
  .kering_fondation_block.last {
    @media screen and (max-width: 1023px) {
      text-align: center;
      float: none !important;
      margin: 15px 0 15px 0 !important;
      max-width: calc(92% - 15px) !important;
    }
    @media screen and (max-width: 767px) {
      float: none !important;
      margin: 15px auto 15px auto !important;
      height: auto !important;
      max-width: 100% !important;
      clear: both;
      .tier {
        height: auto !important;
      }
    }
    .tier:hover {
      background: #ffffff !important;
    }
  }
  .pane-bean-panels.objectifs_block p.readmore {
    display: none;
  }
  .pane-bean-panels.image_top.last p.readmore a {
    color: transparent;

  }
  .pane-bean-panels.image_top.last .tier .image-top-margin {
    margin: 0;
  }
  .pane-bean-panels.image_top .tier {
    padding-left: 0;
    padding-right: 0;
    padding-top:0;
    h2,
    p.block-content {
      padding-left: 35px;
    }
    a > p:first-child {
      padding: 0;
      margin: 0;
    }
    img {
      width: 100% !important;
      max-width: 100%;
      height: auto !important;
    }
  }
  .tier.block-toolarge {
    overflow: hidden;
    background-color: #FFFFFF ! important;
    height: 401px;
    &.tier2-image-block {
      height: 435px;
    }
    img {
      width: 100% !important;
      max-width: 100%;
      height: auto !important;
    }
    p {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .tier.kering_fondation_block {
    @media screen and (max-width: 767px) {
      text-align: center;
    }

  }
  .tier.image-block-slider {
    height: 435px;
    @media screen and (max-width: 1023px) {
      height: 460px;
    }
    & .block_content {
      display: none;
    }
    p {
      font-family: 'Radley', Helvetica, Arial, sans-serif;
      font-size: 16px;
      color: black;
      padding : 0 35px 20px 35px;
    }
  }
}
/* TALENT PAGE */
.node-type-talents {
  @extend %panel-column-33-percent;
  .panels-flexible-row-panel_front_talents-3 {
    margin-top: 15px;
  }
  .tier {
    height: 260px;
    &.last {
      margin-bottom: 0;
    }
  }
  .tier.news-block.tier-block1 {
    height: 535px;
  }
  .panels-flexible-column-panel_front_talents-6 {
    .pane-block {
      &.talents_news {
        .tier {
          height: 425px;
        }
      }
      &.encourage_talents {
        .tier {
          height: 370px;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 100% !important;
      max-width: calc(92% - 15px) !important;
      .pane-block {
        float: left;
        width: 366px;
        max-width: calc(46% - 15px);
        margin-right: 15px;
        &.encourage_talents,
        &.talents_news {
          .tier {
            height: 400px;
          }
        }
      }
    }
  }
  .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 {
    .panels-flexible-region-panel_front_talents-region_video {
      @extend %box-66-percent;
      .tier.rubrique_video_size {
        margin: 0 auto;
        width: 100%;
        padding : 0 0 56.25% 0; /* 16:9 */
        position: relative;
        background: black;
        text-align: center;
        h2 {
          display: none;
        }
        iframe,
        .ppr-player {
          width: 100% !important;
          height: 100% !important;
          position: absolute;
          top: 0; bottom: 0; left: 0; right: 0;
          color: white;
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
  .pane-block.pane-bean-ppr-rubrique-jobs-offers {
    .tier.tier-half-height {
      height: 535px;
        h2 {
        font-size: 27px;
        padding-bottom: 20px;
        padding-top: 35%;
          @media screen and (max-width: 767px) {
            padding-top: 30px;
          }
      }
      p {
        padding-left: 35px;
        margin: 0;
        font-family: 'gotham-light', Helvetica, Arial, sans-serif;
        font-size: 15px;
      }
    }
  }
  .style1 {
    color: #000 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-family: 'Radley', 'alto_pro_lt', Helvetica, Arial, sans-serif !important;
    width: auto !important;
    padding-left: 35px;
    margin: 15px 0 0 0 !important;
  }
  .style2 {
    font-size: 12px !important;
    line-height: 1.5em !important;
    font-family: "gotham-book", Helvetica, Arial, sans-serif !important;
    width: auto !important;
    padding-left: 35px;
    margin: 15px 0 30px 0 !important;
  }
}

/*GROUP PAGE */
.i18n-fr,
.i18n-en {
  &.node-type-group {
    .panels-flexible-row-panel_front_talents-3 {
      margin-top: 15px;
    }
    .panels-flexible-row-panel_front_talents-3,
    .panels-flexible-row-panel_front_talents-10 {
      @extend %panel-column-33-percent;
    }
    .panels-flexible-region-panel_front_talents-region_video-inside {
      .panel-separator {
        display: none;
      }
    }
    .panels-flexible-panel_front_talents {
      .panels-flexible-column-inside {
        padding: 0;
      }
    }
    .pane-bean-ppr-rubrique-lifestyle {
      .tier.tier-half-height {
        p > strong {
          font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
          font-size: 16px;
        }
      }
    }
    .pane-content {
      vertical-align: top;
    }
    .tier{
      height: 465px;
    }
    .tier.news-block {
      height: 472px;
    }

    .pane-bean-ppr-rubrique-inspiration  {
      .tier.tier-half-height {
        height: 210px;
      }
    }
    .pane-bean-ppr-rubrique-creativity {
      .tier.tier-half-height {
        height: 240px;
      }
    }
  }
}

/*PRESS PAGE */
.node-type-press {
  .section-content {
    .panel-pane {
      @extend %box-33-percent;
    }
    .pane-ppr-rubrique-ppr-rubrique-press-releases,
    .pane-ppr-rubrique-ppr-rubrique-press-one-top{
      @extend %box-66-percent;
    }
  }
  .panel-separator {
    display: none;
  }
  .tier.pressreleases {
    height: 235px;
    background-color: black !important;
    h2 {
      color: #FFFFFF;
    }
  }
  .tier.news-one-top-block {
    height: 472px;
  }
  .tier-half-height {
    height: 235px;
  }
  #twitter-block {
    float: right;
    height:auto;
    position: relative;
    padding: 0;
    @media screen and (max-width: 1023px) {
      float: left;
    }
    @media screen and (max-width: 767px) {
      float: none;
      margin-right: auto;
    }
    .pane-content {
      width: 366px;
      max-width: 100%;
      position: relative;
      right: 0;

      @media screen and (max-width: 767px) {
        right: auto;
      }
      .tier {
        padding-top: 15px;
        width: 100%;
      }
    }
  }
}
/** finance ***/
.node-type-finance .panels-flexible-row-front-finance-3 {

  @extend %panel-column-33-percent;
  .panels-flexible-row-inside {
    padding-top: 15px;
  }
  #block-shareholder .tier {
    height: 472px;
  }

  .tier.news-block {
    height: 435px;
  }
  .quarter {
    width: 100%;
    height: 210px;
    background-color: #f8f7f7;
    a {
      color: initial !important;
      text-decoration: none;
    }
  }
  .pane-bean-ppr-rubrique-contact {
    .tier {
      height: 270px;
    }
    p {
      margin-top: 0;
      padding-bottom: 0;
      line-height: 1em;
      &.contact {
        margin-top: 20px;
      }
    }
  }
  .pane-bean-ppr-rubrique-2011-reference-doc .quarter {
    padding-left: 0;
    background-color: #f8f7f7 !important;
    h2 {
      padding-left: calc(43% + 20px);
    }
    img {
      float: left;
      max-width: 43%;
      width: auto;
      height: 210px !important;
    }
    p.link-in-block {
      padding-left: calc(43% + 20px);
    }
  }
  .pane-bean-ppr-rubrique-agenda .quarter p {
    font-family: 'radley', Helvetica, Arial, sans-serif;
    & + p  {
      margin-top: 15px;
    }
  }
  .DesktopRupriquePack .tier {
    p + p {
      margin-top: 10px;
      line-height: 1em;
    }
  }
  .DesktopRubriqueKey {
    p.link-in-block + p.link-in-block {
      margin-top: 10px;
    }
  }
  p.link-in-block,
  p.link-block a {
    border: none;
    color: #000;
    font-family: 'gotham-light', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 19px;
    margin-bottom: 0;
    & + p {
      margin-top: 0;
    }
  }
  .lien_talents {
    display: inline-block;
    font-family: 'Radley', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.2em !important;
    text-decoration: none;
    &:hover {
      background: #ebe9ea;
    }
  }
}

// Japoanais / Chinois
.i18n-ja,
.i18n-zh-hans {
  &.node-landing-page {
    .section-content .pane-bean-panels.tier-large {
      @extend %box-33-percent;
      .tier {
        height: 420px;
      }
    }
    .tier.rubrique_video_size {
      .description {
        display: none;
      }
    }
  }
  &.node-type-group {

    .section-content  .pane-bean-panels.last {
      clear: both;
      @extend %box-66-video;
    }
  }
  .pane-ppr-rubrique-ppr-rubrique-news {
    @extend %box-66-percent;
    .tier {
      height: 420px;
    }
  }
}
