/**
 * @file
 * Sidebars Layout Styling
 */

/* ---------------- IMPORT -----------------*/

/* Achievements */
.aside-banner {
  background: #CBCBCB;
  padding: 0;
  margin-bottom: 15px;
  width: 190px;
  p {
    padding: 0 5px 5px;
    color: #333;
    margin: 0;
    a {
      color: #333;
      text-decoration: none;
      font-size: 1em;
    }
  }
  &.blackbg {
    p {
      padding: 10px 5px 10px 5px;
      color: #fff;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.4em;
      font-family: alto_pro_lt, sans-serif;
      a {
        color: #fff;
        text-decoration: none;
        font-size: 1em;
      }
    }
  }
}

nav.left {
  @media screen and (max-width: 767px) {
    display: none;
  }
  .aside-banner {
    img {
      width: 190px;
    }
  }
  a {
    color: #000;
    font-family: gotham-black;
    font-size: 14px;
    text-decoration: none;
  }
}

#leftmenu {
  float: none;
  margin: 0;
  padding: 0;
  p {
    font-family: 'gotham-black', Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-left: 29px;
  }

}
section {
  .content-wrapper nav {
    display: block;
    width: 200px;
    float: left;
    margin-top: 65px;
    margin-bottom: 60px;
    padding-left: 65px;
    @media screen and (max-width: 1439px) {
      margin-top: 100px;
      padding-left: 30px;
    }
    @media screen and (max-width: 1023px) {
      margin-top: 100px;
      padding-left: 0px;
    }
  }
  nav {
    .title {
      margin-top: 4px;
    }
    ul.menu-left {
      padding: 0;
      > li {
        cursor: pointer;
        display: block;
        font-family: 'gotham-light', 'alto_pro_lt', Helvetica, Arial, sans-serif;
        font-size: 1.3em;
        margin-bottom: 25px;
        margin-left: 30px;
        text-transform: uppercase;
        > a {
          color: #333;
          display: inline;
          font-family: 'gotham-black', Helvetica, Arial, sans-serif;
          font-size: 11px;
          line-height: 17px;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        a.active, a:hover, ul > li:hover > a {
          border-bottom: 1px solid;
        }
        ul {
          li {
            a.active {
              border-bottom: 1px solid;

            }
          }
        }
      }
      li ul {
        padding: 0;
        margin-left: 0;
        li {
          margin-bottom: 10px;
          a {
            font-family: 'gotham-book', Helvetica, Arial, sans-serif;
            color: #6b6b6b;
            display: inline;
            font-size: 11px;
            text-decoration: none;
            text-transform: none;
          }
        }
      }
    }
  }
}

.left {
  .region-sidebar-first {
    .block-bean {
      margin-left: 30px;
      margin-bottom: 15px;
    }
    .quarter, .tier {
      float: none;
      padding: 15px;
      height: auto;
      margin-right: 30px;
      width: 184px;
    }
  }
  #block-bean-bloc-groupe-contact-presse {
    .quarter {
      width: 160px;
    }
  }
  #block-bean-bloc-groupe-contact-presse {
    .quarter {
      background-color: #0d0d0d !important;
    }
  }
}
.region-sidebar-first {

}
/*second sidebar*/
.region-sidebar-second,
.region-sidebar-second {
  .quarter, .tier {
    width: 170px;
    float: none;
    padding: 15px;
    height: auto;
  }
}

/*
nav.left a {
  color: #000;
  font-family: gotham-black;
  font-size: 14px;
  text-decoration: none;
}
section nav ul.menu-left {
  padding: 0;
}
section nav ul.menu-left > li {
  cursor: pointer;
  display: block;
  font-family: 'gotham-light', 'alto_pro_lt', Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  margin-bottom: 25px;
  margin-left: 30px;
  text-transform: uppercase;
}

section nav ul.menu-left > li > a {
  color: #333;
  display: inline;
  font-family: 'gotham-black', Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 17px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

section nav ul.menu-left > li > a.active,
section nav ul.menu-left > li > a:hover,
section nav ul.menu-left > li > ul > li:hover > a {
  border-bottom: 1px solid;
}

section nav ul.menu-left li ul li a.active {
  border-bottom: 1px solid;

}

section nav ul.menu-left li ul {
  padding: 0;
  margin-left: 0
}

section nav ul.menu-left li ul li {
  margin-bottom: 10px;
}

section nav ul.menu-left li ul li a {
  font-family: 'gotham-book', Helvetica, Arial, sans-serif;
  color: #6b6b6b;
  display: inline;
  font-size: 11px;
  text-decoration: none;
  text-transform: none;
}

.left .region-sidebar-first .block-bean {
  margin-left: 30px;
}
nav.left .aside-banner img {
  width:190px;
}
.aside-banner.blackbg p {
  padding: 10px 5px 10px 5px;
  color: #fff;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4em;
  font-family: alto_pro_lt, sans-serif;
}

.aside-banner.blackbg p a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}*/

/* ---------------- DEFAULT -----------------*/

/* ---------------- MOBILE -----------------*/

/* ---------------- TABLET -----------------*/

/* ---------------- DESKTOP -----------------*/