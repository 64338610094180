/**
 * @file
 * Fields Components Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.field {
  .field__label {
    &.sr-only {
    }
  }
  .field__items {
    .field--item {
    }
  }
}

// Field by type
.field--type-entity-reference {
}

.field--type-string {
}

// Label by state
.field--label-inline {
}

.field--label-hidden {
}

/* ---------------- CUSTOM -----------------*/