/**
 * @file
 * Visual styles for tabs.
 */
/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
div.tabs {
}

ul.tabs {
}

.tabs {
  & > li {
  }
  a {
    &.is-active {
    }
    &:focus {
    }
    &:hover {
    }
  }
}

/* ---------------- CUSTOM  -----------------*/