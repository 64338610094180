/**
 * @file
 * Default Html Tags Styling
 */

/* ---------------- DRUPAL DEFAULT -----------------*/
/*html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: $body_font;
  font-size: 1em;
  line-height: 165%;
}*/

/*.page-title {}*/

/* -------------- HEADINGS -----------------*/
/*
h1 {
  @extend %heading1;
}

h2{
  @extend %heading1;
}

h3 {
  @extend %heading3;
}

h4 {
  @extend %heading4;
}

h5 {
  @extend %heading5;
}*/
/*
h6 {
  @extend %heading6;
}*/

/* -------------- PARAGRAPHS -----------------*/
/*
p {
  &:last-child {

  }
}
*/
/* -------------- BLOCKQUOTE -----------------*/
/*
blockquote {
  p {}
}
*/
/* -------------- LIST -----------------*/
/*
ol, ul {
  li {

  }
}*/
/* -------------- LISTS -----------------*/

article h1,
.page-dev-durable-actualite.i18n-fr #block-system-main > div > div > h2,
.page-sustainability-news.i18n-en #block-system-main > div > div > h2 {
  display: block;
  font-size: 30px;
  color: #222222;
  font-family: 'gotham-black', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1em;
  text-align: left;
  margin: 0 0 15px 0;
  font-weight: normal;
  background: none;
  padding: 0;
  @media screen and (max-width: 1023px) {
    font-size: 25px;
  }
  @media screen and (max-width: 767px) {
    font-family: 'gotham-bold', 'alto_pro_thin', Helvetica, Arial, sans-serif;
  }
  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
}
.i18n-ja {
  article h1 {
    font-family: 'SourceHanSansCN-Bold', 'alto_pro_thin', Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
}