/**
 * @file
 * Views Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- CUSTOM -----------------*/
/* Biography */
.block-biographies {
 .advanced-search-form {
    display: block;
  }
  .views-row {
    background-color: #f7f8f8;
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 15px;
    padding: 20px 10px 0 20px;
    position: relative;
    vertical-align: top;
    width: 381px;
    max-width: calc(50% - 15px);
    height: 185px !important;
    box-sizing: border-box;
    @media screen and (max-width: 1023px) {
      max-width: 100%;
      height: auto !important;
      margin-right: 0;
    }
   }
  .item-list ul li {
    margin: 0;
    padding: 0;
    font-family: 'gotham-light', Helvetica, Arial, sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    color: black;
  }
  .readmore a {
    display: none;
  }
  .views-row-even {
    margin-right: 0;
  }
  .view-content .views-comex {
    .views-field {
      float: left;
      max-width: calc(100% - 115px);
      @media screen and (max-width: 450px) {
        float: none;
        max-width: 100%;
      }
      &.views-field.views-field-title-field {
        margin-top: 15px;
      }
    }
  }
  .view-content .views-field {
    margin: 0;
    float: none;
    box-sizing: border-box;
    &.views-field-field-poste {
      margin-top: 10px;
      margin-bottom: 15px;
      div {
        font-family: 'gotham-book', Helvetica, Arial, sans-serif;
        font-size: 10px;
        text-transform: uppercase;
        color: black;
      }
    }
    &.views-field.views-field-title-field {
      margin-top: 30px;
      h3 {
        font-family: 'gotham-black', Helvetica, Arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
  .view-content .views-comex .views-field-field-image {
    display: block;
    width: 100px;
    margin-right: 15px;
    margin-top: 0;
    padding: 0;
    margin-bottom: 15px;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
    & > p {
      line-height: 0;
      margin: 0;
      padding: 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}