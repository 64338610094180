/**
 * @file
 * navbar Styling
 */

/* ---------------- DEFAULT -----------------*/
/**
 * @file
 * Social Links Styling
 */

header {
  nav ul.main-nav {
    font: 1.6em altopro_con_regular, Arial, Helvetica;
    margin: 90px 120px 0 0;
    display: block;
    text-align: center;
    > li {
      display: inline-block;
      zoom: 1;
      *display: inline;
      border-right: #b2b2b2 1px solid;
      padding: 5px 10px;
      cursor: pointer;
      text-transform: uppercase;
      height: 12px;
      line-height: 12px;
      margin-bottom: 10px;
      &:hover {
        background: #f1f1f1;
        height: 25px;
        border: none;
        padding-top: 10px;
        padding-left: 11px;
        margin: -15px 1px 0 -1px;
      }
      &.first:hover {
        padding-left: 10px;
        border-left: 1px solid #fff;
      }
      &.last:hover {
        padding-left: 11px;
        padding-right: 9px;
      }
    }
    li {
      &:hover {
        a.menulink {
          border-bottom: #000 2px solid;
          padding-bottom: 5px
        }
        ul.second-level-menu {
          height: auto;
          background: #f1f1f1;
          width: 10000px;
          position: absolute;
          left: 50%;
          top: 109px;
          margin-left: -5000px;
          padding: 15px 15px 35px;
          display: block;
          li {
            display: block;
            width: 960px;
            margin: 0 auto;
            .second-level-menu-wrapper-item1 {
              float: left;
              width: 540px;
              margin-right: 40px;
            }
            .second-level-menu-wrapper-item2 {
              float: left;
              width: 365px
            }
            .second-level-menu-wrapper-item {
              ul.second-level-menu-item {
                padding: 0;
                li {
                  float: left;
                  width: 119px;
                  font-family: Verdana, Arial, sans-serif;
                  font-size: 0.7em;
                  text-transform: capitalize;
                  padding: 5px 0 5px 20px;
                  margin: 0 10px;
                  border-bottom: 1px dotted #202020;
                  a {
                    color: #7f7f7f;
                  }
                  &:hover {
                    background: #7F7F7F;
                    border-bottom: #7F7F7F 1px solid;
                    margin-top: -1px;
                    margin-bottom: 1px;
                    a {
                      color: #fff;
                    }
                  }
                }
                .second-level-menu-title {
                  background: url('../images/sprite-header-kering.png') no-repeat -310px -155px;
                  padding: 2px 0 10px 35px;
                  border-bottom: #000 1px solid;
                }
              }
            }
          }
        }
      }
      &.main-nav-right-group, &.main-nav-left-group {
        a.active {
          border-bottom: #000 2px solid;
          padding-bottom: 5px
        }
        &.last {
          border-right: 1px solid #fff;
        }
      }
      &.main-nav-left-group {
        &.last {
          margin-right: 70px;
        }
      }
      &.main-nav-right-group {
        &.first {
          margin-left: 170px;
        }
      }
      &.cannes {
        width: 103px;
        height: 53px;
        position: absolute;
        border: 0 none;
        bottom: -5px;
        background: url('../images/festical-de-cannes.svg') no-repeat 10px 10px;
        a {
          width: 103px;
          height: 61px;
          display: block;
        }
        &:hover, &.hover {
          margin: 0 0 0 0;
          padding: 5px 10px 15px 10px;
          width: 103px;
          height: 53px;
          background: url('../images/festical-de-cannes_hover.svg') #000 no-repeat 10px 10px;
        }
      }
      ul.second-level-menu {
        display: none;
        text-align: left;
      }
    }
  }
  a {
    text-decoration: none;
    color: #000;
  }
}

.admin-menu {
  .main-nav {
    li:hover {
      ul.second-level-menu {
        margin-top: -45px;
        top: 80px;
      }
    }
  }
}

.i18n-ja {
  header {
    nav {
      margin-top: 20px;
      ul.main-nav {
        padding: 39px 0 0;
        margin: 0;
        float: left;
        > li {
          padding-left: 0;
          padding-right: 0;
        }
        li.main-nav-left-group, li.main-nav-right-group {
          width: 65px;
          text-align: center;
          font-size: 12px;
        }
        li.main-nav-left-group.last {
          margin-right: 26px;
          width: 115px;
        }
        li.main-nav-left-group.first {
          margin-left: 110px;
          width: 115px;
        }
      }
    }
  }
  .main-nav {
    > li:hover {
      padding-left: 1px;
      padding-right: 0;
    }
    > li.first:hover {
      padding-left: 0;
      border-left: none;
    }
    > li.last:hover {
      padding-left: 1px;
      padding-right: 0;
    }
    li:hover {
      ul.second-level-menu {
        top: 59px;
      }
    }
  }
  &.admin-menu {
    .main-nav {
      li:hover {
        ul.second-level-menu {
          top: 120px;
        }
      }
    }
  }
}

.i18n-en {
  header {
    nav {
      ul.main-nav {
        li.cannes {
          background: url('../images/festical-de-cannes_en.svg') no-repeat 10px 10px;
          &:hover, &.hover {
            background: url('../images/festical-de-cannes_en_hover.svg') #000 no-repeat 10px 10px;
          }
        }
      }
    }
  }
}

.lt-ie9 {
  .main-nav {
    li:hover {
      ul.second-level-menu {
        li .second-level-menu-wrapper-item ul.second-level-menu-item li {
          width: 140px;
        }
      }
    }
  }
}

/*
header nav ul.main-nav {
  font: 1.6em altopro_con_regular, Arial, Helvetica;
  margin: 90px 120px 0 0;
  display: block;
  text-align: center;
}
header nav ul.main-nav > li {
  display: inline-block;
  zoom: 1;
  *display: inline;
  border-right: #b2b2b2 1px solid;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: uppercase;
  height: 12px;
  line-height: 12px;
  margin-bottom: 10px;
}


header a {
  text-decoration: none;
  color: #000;
}

header nav ul.main-nav li:hover a.menulink,
header nav ul.main-nav li.main-nav-right-group > a.active,
header nav ul.main-nav li.main-nav-left-group > a.active {
  border-bottom: #000 2px solid;
  padding-bottom: 5px
}
.news-one-top-block {
  margin-right: 0;
}

header nav ul.main-nav li.main-nav-left-group.last,
header nav ul.main-nav li.main-nav-right-group.last {
  border-right: 1px solid #fff;
}

header nav ul.main-nav li.main-nav-left-group.last {
  margin-right: 70px;
}


header nav ul.main-nav li.main-nav-right-group.first {
  margin-left: 170px;
}

header nav ul.main-nav li.cannes {
  width: 103px;
  height: 53px;
  position: absolute;
  border: 0 none;
  bottom: -5px;
  background: url('../images/festical-de-cannes.svg') no-repeat 10px 10px;
}*/
/*
.i18n-en header nav ul.main-nav li.cannes {
  background: url('../images/festical-de-cannes_en.svg') no-repeat 10px 10px;
}

header nav ul.main-nav li.cannes a {
  width: 103px;
  height: 61px;
  display: block;
}*/

/* Sub menu ---------*/
/*
.main-nav {
    > li:hover {
    background: #f1f1f1;
    height: 25px;
    border: none;
    padding-top: 10px;
    padding-left: 11px;
    margin: -15px 1px 0 -1px;
  }
  li.cannes:hover, li.cannes.hover{
    margin: 0 0 0 0;
    padding: 5px 10px 15px 10px;
    width: 103px;
    height: 53px;
    background: url('../images/festical-de-cannes_hover.svg') #000 no-repeat 10px 10px;
  }
}
.main-nav li.cannes:hover,
.main-nav li.cannes.hover {
  margin: 0 0 0 0;
  padding: 5px 10px 15px 10px;
  width: 103px;
  height: 53px;
  background: url('../images/festical-de-cannes_hover.svg') #000 no-repeat 10px 10px;
}
.i18n-en .main-nav li.cannes:hover,
.i18n-en .main-nav li.cannes.hover {
  background: url('../images/festical-de-cannes_en_hover.svg') #000 no-repeat 10px 10px;
}


.main-nav > li.first:hover {
  padding-left: 10px;
  border-left: 1px solid #fff;
}

.main-nav > li.last:hover {
  padding-left: 11px;
  padding-right: 9px;
}

.main-nav li ul.second-level-menu {
  display: none;
  text-align: left;
}

.main-nav li:hover ul.second-level-menu {
  height: auto;
  background: #f1f1f1;
  width: 10000px;
  position: absolute;
  left: 50%;
  top: 109px;
  margin-left: -5000px;
  padding: 15px 15px 35px;
  display: block;
}*/

/*
.main-nav li:hover ul.second-level-menu li {
  display: block;
  width: 960px;
  margin: 0 auto;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item1 {
  float: left;
  width: 540px;
  margin-right: 40px;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item2 {
  float: left;
  width: 365px;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item {
  padding: 0
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li {
  float: left;
  width: 119px;
  font-family: Verdana, Arial, sans-serif;
  font-size: 0.7em;
  text-transform: capitalize;
  padding: 5px 0 5px 20px;
  margin: 0 10px;
  border-bottom: 1px dotted #202020;
}

.lt-ie9 .main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li {
  width: 140px;
}
.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li a {
  color: #7f7f7f;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li:hover a {
  color: #fff;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li:hover {
  background: #7F7F7F;
  border-bottom: #7F7F7F 1px solid;
  margin-top: -1px;
  margin-bottom: 1px;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item .second-level-menu-title {
  background: url('../images/sprite-header-kering.png') no-repeat -310px -155px;
  padding: 2px 0 10px 35px;
  border-bottom: #000 1px solid;
}
.i18n-ja header nav ul.main-nav {
  padding: 39px 0 0;
  margin: 0;
  float: left;
}
.i18n-ja header nav ul.main-nav > li {
  padding-left: 0;
  padding-right: 0;
}
.i18n-ja header nav ul.main-nav li.main-nav-left-group,
.i18n-ja header nav ul.main-nav li.main-nav-right-group {
  width: 65px;
  text-align: center;
  font-size: 12px;
}

.i18n-ja header nav ul.main-nav li.main-nav-left-group.last,
.i18n-ja header nav ul.main-nav li.main-nav-left-group.first {
  width: 115px;
}

.i18n-ja header nav ul.main-nav li.main-nav-left-group.last {
  margin-right: 26px;
}
.i18n-ja header nav ul.main-nav li.main-nav-left-group.first {
  margin-left: 110px;
}
.i18n-ja .main-nav > li:hover {
  padding-left: 1px;
  padding-right: 0;
}

.i18n-ja .main-nav > li.first:hover {
  padding-left: 0;
  border-left: none;
}

.i18n-ja .main-nav > li.last:hover {
  padding-left: 1px;
  padding-right: 0;
}
.i18n-ja .main-nav li:hover ul.second-level-menu {
  top: 59px;
}

.i18n-ja.admin-menu .main-nav li:hover ul.second-level-menu {
  top: 120px;
}*/

/* End of Sub menu ---------*/

/* End of Nav */