button {
  &.search, &.social, &.close-button {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: transparent;
    border-image-outset: 0 0 0 0;
    border-image-repeat: stretch stretch;
    border-image-slice: 100% 100% 100% 100%;
    border-image-source: none;
    border-image-width: 1 1 1 1;
    border: none;
    color: transparent;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    position: relative;
  }
  &.search, &.close-button {
    background-image: url("../images/kering-refonte-sprite.png");
  }
  &.search {
    background-position: -114px -73px;
    height: 25px;
    width: 28px;
    margin-left: 20px;
    margin-right: 30px;
  }
  &.social {
    background: url("../images/button_social.png") no-repeat;
    height: 36px;
    margin-left: 25px;
    width: 70px;
    &.open {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      color: #fff;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 14px;
      margin-left: 27px;
      vertical-align: middle;
      width: 85px;
    }
  }
  &.close-button {
    background-position: -225px -3px;
    display: none;
    height: 19px;
    margin-left: 20px;
    width: 18px;
  }
  &.close-language {
    margin-top: 9px;
  }
}