/**
 * @file
 * Forms Components Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
form {
}

/*.form-item {}
.form-textarea-wrapper {}
.description {}
.form-text {}
.form-radios{}
.form-checkboxes{}
.form-actions {}
.form-item-separator {}
.form--inline{}*/

label {
}

label.option {
}

input[type="text"],
input[type="password"],
input[type="date"],
textarea {
  &:focus {
  }
}

input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
}

fieldset {
}

fieldset legend {
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"],
.form-submit {
  /*@extend %button-form;*/
}

input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"] {
  &:focus {
  }
}

//
// Drupal selectors.
//
.collapsible {
  .fieldset-legend {
    .summary {
    }
  }
  &.collapsed {
    .fieldset-legend {
    }
    .fieldset-wrapper {
    }
  }
}

/* ---------------- CUSTOM  -----------------*/

