/**
 * @file
 * More Links Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/

.more-link {
}

/* ---------------- CUSTOM  -----------------*/