/**
 * @file
 * Visual styles for tables.
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";
// Bootstrap Libraries
// @import path-to-scss-file/

/* ---------------- DEFAULT -----------------*/
table {

  .empty {
  }
  .message {
  }

  tr {
    &.drag {
    }
    &.drag-previous {
    }
  }

  th, td {
    &.is-active {
    }
    &.odd {
    }
    &.even {
    }
  }

  thead {
    tr {
      td, th {
      }
    }
  }
  tbody {
    tr {
      td {
      }
    }
  }
  caption {
  }
  colgroup {
  }
}

/* ---------------- CUSTOM  -----------------*/

