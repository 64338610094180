/*************************************************************************************
  Import tous les fichiers _name.scss liés à la navigation.
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
@import "breadcrumb";
@import "menu";
@import "navbar";
@import "mega_menu";
@import "mobile_menu";
@import "more_links";
@import "social-media";
@import "pager";

