/*************************************************************************************
  Import tous les fichiers _name.scss liés au "component"
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
@import "button";
@import "file";
@import "fields";
@import "icons";
@import "tables";
@import "tabs";
@import "toolbar";
@import "forms";
//@import "webforms";
//@import "comments";
@import "paragraphs";
// @import "modals";
@import "carousels";