/**
 * @file
 * Main Layout Styling
 */
/* ---------------- DEFAULT -----------------*/
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

article, aside, footer, header, nav, section {
  display: block
}

section nav .title {
  font-size: 3.2em;
  line-height: 1em;
  font-family: 'alto_pro_thin', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  margin: 20px 0 30px;
  font-weight: normal;
}

.main-wrapper {
  position: absolute;
  width: 100%;
  overflow: hidden;
}
.main-wrapper > header,
.main-wrapper > .region-content,
.main-wrapper > section.rubrique,
.main-wrapper > section.firstsection  {
  position: relative;
  background: #ffffff url("../images/background.png") repeat-y;
  margin: auto;
  padding-left: 110px;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  @media screen and (max-width: 1439px)   {
    background: #ffffff url("../images/background_1025.png") repeat-y;
    margin: auto;
    padding-left: 64px;
  }
  @media screen and (max-width: 767px) {
    background-position: -45px 0 !important;
    padding-left: 20px;
  }
}
.front .news-slider-content  {
  position: relative;
  left: -110px;
  width: calc(100% + 110px);
  @media screen and (max-width: 1439px)   {
    left: -64px;
    width: calc(100% + 64px);
  }
  @media screen and (max-width: 767px) {
    left: -20px;
    width: calc(100% + 20px);
  }
}

div.blackbg {
  background: #000;
  padding: 0;
}

.content-wrapper.clearfix{
  position: relative;
  padding-left:90px;
  padding-right:15px;
  box-sizing: border-box;
  max-width: 1240px;
  @media screen and (max-width: 1439px) {
    padding-left:50px;
  }
  @media screen and (max-width: 767px) {
    padding-left:15px;
  }
}
.region-main-block {
  background-color: #fbfbfb;
  height: 80px;
  margin-left: 0;
  padding-left: 0;
  width: 100%;
  @media screen and (max-width: 1439px) {
    height: 60px;
  }
}
.node-type-page {
  article {

  }
}
.node-news .block-content-description,
.content-type-contenu,
.content-type-contenu .field-name-field-content {
  margin: 0;
  font-family: 'gotham-book', Helvetica, Arial, sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 27px;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 26px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 26px;
  }
  strong {
    font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
    font-weight: normal;
  }
  div > em {
    font-family: 'radley', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 23px;
    line-height: 32px;
  }
  p {
    margin: 0 0 2em 0;
    text-align: justify;
  }
  div + p {
    margin-top: 2em;
  }
  .block-content-description {
    font-size: 18px;
    line-height: 28px;
    color: #666;
    font-weight: normal;
    @media screen and (max-width: 1023px) {
      font-size: 17px;
      line-height: 27px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .block-content-intro {
    color: #333;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 0;
    font-family: 'gotham-book', Helvetica, Arial, sans-serif;
    @media screen and (max-width: 1023px) {
      font-size: 17px;
      line-height: 27px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  h3 + p.block-content-intro {
    margin-top: 1em;
  }
  ul {
    li {
      p {
        margin: 0;
        & + p {
          margin: 2em 0 0 0;
        }
      }
    }
  }
  table th, table td {
    vertical-align: middle;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    @media screen and (max-width: 767px) {
      font-size: 13px;
      line-height: 21px;
    }
  }
}

.front-page .panels-flexible-region-front-page-main_block,
.front-finance .panels-flexible-region-front-finance-main_block,
.panels-flexible-panel_front_talents .panels-flexible-region-panel_front_talents-main_block,
.node-type-brand .region-main-block,
section .section-slider .content-wrapper {
  background-color: #f8f7f7;
  margin-left: -110px;
  padding-left: 110px;
  height: 394px;
  width: 100%;
  @media screen and (max-width: 1439px) {
    margin-left: -65px;
    padding-left:65px;
    height: auto;
    max-width: none;
  }
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    margin-left: -20px;
  }
}

// BANNER GRAY

.featured-slide {
  display: inline-block;
  max-width: 60%;
  width: 785px;
  padding-left: 90px;
  box-sizing: border-box;
  @media screen and (max-width: 1439px) {
    padding-left: 50px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    padding-right: 80px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 60px;
    padding-right: 80px;
    height: auto;
    line-height: normal;
  }
  @media screen and (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    line-height: normal;
  }
  .node-panel & {
    height: 394px;
    line-height: 394px;
    @media screen and (max-width: 767px) {
      height: auto;
      line-height: normal;
      @media screen and (max-width: 767px) {
        padding-bottom: 50px;
      }
    }
  }

  .featured-content {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    h1 {
      color: #333;
      font-family: 'gotham-medium', Helvetica, Arial, sans-serif;
      font-size: 30px;
      font-weight: normal;
      line-height: 26px;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media screen and (max-width: 1023px) {

      }
      @media screen and (max-width: 767px) {
        padding-top: 50px;
      }
      & + p {
        margin-bottom: 20px;
      }
    }
    p {
      font-family: 'Radley', Helvetica, Arial, sans-serif;
      font-size: 17px;
      line-height: 21px;
      margin: 0;
      padding-bottom: 0;
      @media screen and (max-width: 1023px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media screen and (max-width: 767px) {
        font-size: 15px;
        line-height: 19px;
      }
      a {
        display: inline-block;
        vertical-align: top;
        margin: 20px .5em 0 0;
      }
    }
    a {
      color: #333232;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 13px;
      text-decoration: none;
      &:hover {
        color: #a7a8ac;
        font-family: 'gotham-light', Helvetica, Arial, sans-serif;
      }
      span  {
        font-family: 'gotham-light';
        font-size: 12px;
        line-height: 1em;
        font-style: normal;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.slider.slider_hidden {
  float: right;
  vertical-align: top;
  text-align: right;
  width: 375px;
  @media screen and (max-width: 1439px) {
    width: 300px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
}

// Content
.page-communiques-de-presse article .featured-block,
.page-press-releases article .featured-block,
#block-system-main > .content > .presentation-block,
.block-content-article,
#messages,
#ppr-alert-press-release-register-form {
  padding-left: 130px;
  margin-top: 0;
  width: 821px;
  max-width: 98%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @media screen and (max-width: 1439px) {
    padding-left: 100px;
    max-width: 98%;
  }
  @media screen and (max-width: 1023px) {
    padding-left: 60px;
    max-width: 98%;
  }
  @media screen and (max-width: 767px) {
    max-width: 90%;
    margin: 20px auto 0;
    padding: 0;
  }
}
.not-logged-in.page-node-17 .block-content-article,
.not-logged-in #ppr-alert-press-release-register-form,
.not-logged-in.page-node-165 .block-content-article {
  margin-top : 65px;
  @media screen and (max-width: 1439px){
    margin-top: 100px;
  }
  @media screen and (max-width: 767px){
    margin-top: 15px;
  }
}

.view-videotheque,
.masonry-publications {
  margin-left: 130px;
  @media screen and (max-width: 1439px) {
    margin-left: 80px;
  }
  @media screen and (max-width: 1110px) {
    margin-left: 90px;
  }
  @media screen and (max-width: 767px) {
    margin: 20px auto 0;
  }
  .masonry-videos {
    @media screen and (max-width: 767px) {
      max-width: 100%;
      width: 350px;
      margin: auto;
    }
  }
  .masonry-item,
  .masonry-item img {
    max-width: 100%;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto !important;
    }
  }
}
@media screen and (max-width: 1110px) and (min-width: 768px) {

  .view-videotheque, .masonry-videos {
    width: 350px;
    max-width: 100%;
  }

}

#block-system-main > .content > .presentation-block,
.block-content-article {
  & > ul {
    padding: 0;
    margin-left :30px;
    li {
      p:first-child {
        display: inline-block;
        vertical-align: top;
        margin-top: 0;
        margin-bottom: 0;
        & + p {
          margin-top: 1em;
        }
      }
      & + li {
        margin-top: 15px;
      }
    }
  }
}
article p.readmore.return-link {
  padding-left: 130px;
  margin: 3em 0 5em 0;
  text-align: center;
  width: 821px;
  max-width: 98%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @media screen and (max-width: 1439px) {
    padding-left: 100px;
    max-width: 98%;
  }
  @media screen and (max-width: 1023px) {
    padding-left: 60px;
    max-width: 98%;
  }
  @media screen and (max-width: 767px) {
    max-width: 90%;
    margin: 20px auto 30px auto;
    padding: 0;
  }
}

.admin-menu ul.tabs.primary {
  margin: 0 0 30px 130px;
  width: 691px;
  max-width: 80%;
  @media screen and (max-width: 1439px) {
    margin: 0 0 30px 100px;
  }
  @media screen and (max-width: 1023px) {
    margin: 0 0 30px 60px;
  }
  @media screen and (max-width: 640px) {
    margin: 0 0 30px 50px;
  }
  @media screen and (max-width: 450px) {
    margin: 0 0 30px 30px;
  }
}
.section .content-wrapper article {
  margin: 65px 0 40px 0 !important;
  overflow: hidden;
  padding: 0;
  width: 870px;
  @media screen and (max-width: 1439px) {
    max-width: calc(100% - 250px);
    margin: 100px 0 0 0 !important;
  }
  @media screen and (max-width: 1023px) {
    max-width: calc(100% - 200px);
  }
  @media screen and (max-width: 767px) {
      width: 90%;
      max-width: 90%;
      margin: 20px auto 0 auto !important;
  }
}
.page-node-8266.i18n-fr .section .content-wrapper article,
.page-node-8266.i18n-en .section .content-wrapper article {
  @media screen and (min-width: 1440px) {
    width: auto;
  }
  @media screen and (min-width: 768px) {
    .block-content-intro {
      width: 690px;
      max-width: 98%;
    }
  }
  .block-content-article {
    width: 98%;
  }
}
.i18n-ja,
.i18n-zh-hans {
  .presentation-block,
  .block-content-article {
    width: 1200px;
  }
  aside {
    width: 200px;
    float: right;
    margin: 30px 0 0 0;
    @media screen and (max-width: 1439px) {
      margin: 30px 15px 0 0;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  &.page-brands ,
  &.page-node-8266 {
    .section > .content-wrapper > article {
      width: auto;
      max-width: 100%;
      @media screen and (max-width: 767px) {
        max-width: 80%;
      }
    }
    ul.achievements-list {
      padding :0;
      margin: 0;
    }
    .presentation-block,
    .block-content-article {
      width: 1330px;
      .article-item-wrapper {
        .article-item {
          padding-left: 0;
        }
      }
    }
  }
  .node-news {
    .presentation-block,
    .block-content-article {
      max-width: 87%;
      width: 760px;
    }
  }
  .block-content-article > .block-content-intro,
  .block-content-article .article-item-wrapper.content-type-contenu {
    max-width: 80%;

    @media screen and (max-width: 767px) {
      max-width: 98%;
    }
  }



  p.readmore.return-link a {
    font-family: "gotham-black";
  }
}

.media-vimeo-video,
div.media-youtube-video {
  margin-bottom: 15px;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  background: black;
  iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    color: white;
    font-size: 24px;
    text-align: center;
  }
}
