/**
 * @file
 * Search Form Styling
 */

/* ---------------- DEFAULT -----------------*/
/**
 * @file
 * Social Links Styling
 */

/* ---------------- Search  -----------------*/

/* Toolbar */

/*
.admin-menu header div.header-toolbar {
  top: 25px;
}*/
#block-ppr-header-footer-refonte-header-footer-search-top {
  position: absolute;
  height: 100%;
  width: 835px;
  z-index: 700;
  right: -460px;
  .content {
    padding-top: 65px;
    @media screen and (max-width: 1439px) {
      padding-top:45px;
    }
  }
  .contextual-links-wrapper {
    top: 39px;
  }
  button.search {
    background-position: -114px -73px;
    height: 25px;
    width: 28px;
    margin-left: 20px;
    margin-right: 30px;
  }
  @media screen and (max-width: 767px) {
    height: 79px;
    background-image: none;
    width: 0;
    right: 0;
    .content {
      padding-top: 30px;
      button {
        position: absolute;
        right: 0;
        z-index: 999;
        background-position: -114px -73px;
        height: 25px;
        width: 28px;
        margin-left: 20px;
        margin-right: 26px;
        top: 27px;
      }
    }
  }
  .form-item-website-search label, .form-item-reset-search {
    display: none;
  }
  .form-item-website-search, #edit-submit-search {
    color: transparent;
    display: none;
  }
  .form-item-website-search {
    margin: 0;
    padding: 0;
    #edit-website-search {
      background-color: transparent;
      border-image: none;
      border: 0 none #fff;
      border-bottom: 1px solid;
      color: #fff;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 14px;
      padding-bottom: 25px;
      width: calc(100% - 50px);
      @media screen and (max-width: 1279px) {
        width: 460px;
      }
      @media screen and (max-width: 1023px) {
        width: 340px;
      }
      @media screen and (max-width: 900px) {
        width: 250px;
      }
      @media screen and (max-width: 767px) {
        width: 80%;
        border: 0 none;
        margin-left: 20px;
        line-height: 20px;
        font-size: 20px;
      }
    }
  }
  #ppr-header-footer-search-block-form {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: transparent;
    border-image-outset: 0 0 0 0;
    border-image-repeat: stretch stretch;
    border-image-slice: 100% 100% 100% 100%;
    border-image-source: none;
    border-image-width: 1 1 1 1;
    border: none;
    color: transparent;
    display: inline-block;
    padding: 0;
    position: relative;
  }
  #edit-submit-search {
    background: transparent url("../images/kering-refonte-sprite.png") -225px -86px;
    border: medium none;
    color: transparent;
    height: 19px;
    margin-left: -20px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;

  }
}
header {
  #block-ppr-header-footer-refonte-header-footer-lg-switch {
    position: absolute;
    height: 100%;
    .contextual-links-wrapper {
      top: 39px;
    }
  }
}

.i18n-ja {
  #block-ppr-header-footer-refonte-header-footer-lg-switch {
    right: -235px;
  }
}