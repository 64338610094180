/**
 * @file
 * Paragraphs Component Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/

/* ---------------- CUSTOM  -----------------*/