@charset "UTF-8";
/*********************************************************************************
 _    _             _       _                      _   _
| |  | |           (_)     | |                    | | (_)
| | _| | ___  ___   _ _ __ | |_ ___ _ __ __ _  ___| |_ ___   _____
| |/ / |/ _ \/ _ \ | | '_ \| __/ _ \ '__/ _` |/ __| __| \ \ / / _ |   <| |  __/  __/ | | | | | ||  __/ | | (_| | (__| |_| |\ V /  __/
|_|\_\_|\___|\___| |_|_| |_|\__\___|_|  \__,_|\___|\__|_| \_/ \___|

*********************************************************************************/
/**
 * @file
 * Font Variables
 */
@font-face {
  font-family: 'altopro_con_regular';
  src: url("../fonts/altoprocon-normal-webfont.eot");
  src: url("../fonts/altoprocon-normal-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/altoprocon-normal-webfont.woff") format("woff"), url("../fonts/altoprocon-normal-webfont.ttf") format("truetype"), url("../fonts/altoprocon-normal-webfont.svg#alto_pro_con_norregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alto_pro_thin';
  src: url("../fonts/altopro-thin-webfont.eot");
  src: url("../fonts/altopro-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/altopro-thin-webfont.woff") format("woff"), url("../fonts/altopro-thin-webfont.ttf") format("truetype"), url("../fonts/altopro-thin-webfont.svg#alto_pro_thregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alto_pro_lt';
  src: url("../fonts/altopro-light-webfont.eot");
  src: url("../fonts/altopro-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/altopro-light-webfont.woff") format("woff"), url("../fonts/altopro-light-webfont.ttf") format("truetype"), url("../fonts/altopro-light-webfont.svg#alto_pro_ltregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SimSun';
  src: url("../fonts/SimSun.eot");
  src: url("../fonts/SimSun.eot?#iefix") format("embedded-opentype"), url("../fonts/SimSun.woff") format("woff"), url("../fonts/SimSun.ttf") format("truetype"), url("../fonts/SimSun.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD; }

@font-face {
  font-family: 'keringregular';
  src: url("../fonts/kering-regular-webfont.eot");
  src: url("../fonts/kering-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/kering-regular-webfont.woff2") format("woff2"), url("../fonts/kering-regular-webfont.woff") format("woff"), url("../fonts/kering-regular-webfont.ttf") format("truetype"), url("../fonts/kering-regular-webfont.svg#keringregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'altopro';
  src: url("../fonts/AltoPro-Normal.eot");
  src: url("../fonts/AltoPro-Normal.eot?#iefix") format("embedded-opentype"), url("../fonts/AltoPro-Normal.woff2") format("woff2"), url("../fonts/AltoPro-Normal.woff") format("woff"), url("../fonts/AltoPro-Normal.ttf") format("truetype"), url("../fonts/AltoPro-Normal.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-black';
  src: url("../fonts/gotham-black.eot");
  src: url("../fonts/gotham-black.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-black.woff") format("woff"), url("../fonts/gotham-black.ttf") format("truetype"), url("../fonts/gotham-black.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-ultra';
  src: url("../fonts/gotham-ultra.eot");
  src: url("../fonts/gotham-ultra.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-ultra.woff") format("woff"), url("../fonts/gotham-ultra.ttf") format("truetype"), url("../fonts/gotham-ultra.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url("../fonts/SourceHanSansCN-Bold.otf");
  src: url("../fonts/SourceHanSansCN-Bold.otf?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url("../fonts/SourceHanSansCN-Normal.otf");
  src: url("../fonts/SourceHanSansCN-Normal.otf?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-book';
  src: url("../fonts/gotham-book.eot");
  src: url("../fonts/gotham-book.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-book.woff") format("woff"), url("../fonts/gotham-book.ttf") format("truetype"), url("../fonts/gotham-book.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-bold';
  src: url("../fonts/gotham-bold.eot");
  src: url("../fonts/gotham-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-bold.woff") format("woff"), url("../fonts/gotham-bold.ttf") format("truetype"), url("../fonts/gotham-bold.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-medium';
  src: url("../fonts/gotham-medium.eot");
  src: url("../fonts/gotham-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-medium.woff") format("woff"), url("../fonts/gotham-medium.ttf") format("truetype"), url("../fonts/gotham-medium.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-light';
  src: url("../fonts/gotham-light.eot");
  src: url("../fonts/gotham-light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-light.woff") format("woff"), url("../fonts/gotham-light.ttf") format("truetype"), url("../fonts/gotham-light.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-book-italic';
  src: url("../fonts/gotham-book-italic.eot");
  src: url("../fonts/gotham-book-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-book-italic.woff") format("woff"), url("../fonts/gotham-book-italic.ttf") format("truetype"), url("../fonts/gotham-book-italic.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Radley';
  src: url("../fonts/Radley-Regular.eot");
  src: url("../fonts/Radley-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Radley-Regular.woff") format("woff"), url("../fonts/Radley-Regular.ttf") format("truetype"), url("../fonts/Radley-Regular.svg#radleyregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'AdobeFanHeitiStd-Bold';
  src: url("../fonts/AdobeFanHeitiStd-Bold.eot");
  src: url("../fonts/AdobeFanHeitiStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/AdobeFanHeitiStd-Bold.woff") format("woff"), url("../fonts/AdobeFanHeitiStd-Bold.ttf") format("truetype"), url("../fonts/AdobeFanHeitiStd-Bold.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'AdobeHeitiStd-Regular';
  src: url("../fonts/AdobeHeitiStd-Regular.eot");
  src: url("../fonts/AdobeHeitiStd-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AdobeHeitiStd-Regular.woff") format("woff"), url("../fonts/AdobeHeitiStd-Regular.ttf") format("truetype"), url("../fonts/AdobeHeitiStd-Regular.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * @file
 * Breakpoint Variables
 */
/*************************************************************************************
  Import tous les fichiers _name.scss de base.
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
/**
 * @file
 * Nomalize Styling
 */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/**
 * @file
 * Default Html Tags Styling
 */
/* ---------------- DRUPAL DEFAULT -----------------*/
/*html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: $body_font;
  font-size: 1em;
  line-height: 165%;
}*/
/*.page-title {}*/
/* -------------- HEADINGS -----------------*/
/*
h1 {
  @extend %heading1;
}

h2{
  @extend %heading1;
}

h3 {
  @extend %heading3;
}

h4 {
  @extend %heading4;
}

h5 {
  @extend %heading5;
}*/
/*
h6 {
  @extend %heading6;
}*/
/* -------------- PARAGRAPHS -----------------*/
/*
p {
  &:last-child {

  }
}
*/
/* -------------- BLOCKQUOTE -----------------*/
/*
blockquote {
  p {}
}
*/
/* -------------- LIST -----------------*/
/*
ol, ul {
  li {

  }
}*/
/* -------------- LISTS -----------------*/
article h1,
.page-dev-durable-actualite.i18n-fr #block-system-main > div > div > h2,
.page-sustainability-news.i18n-en #block-system-main > div > div > h2 {
  display: block;
  font-size: 30px;
  color: #222222;
  font-family: 'gotham-black', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1em;
  text-align: left;
  margin: 0 0 15px 0;
  font-weight: normal;
  background: none;
  padding: 0; }
  @media screen and (max-width: 1023px) {
    article h1,
    .page-dev-durable-actualite.i18n-fr #block-system-main > div > div > h2,
    .page-sustainability-news.i18n-en #block-system-main > div > div > h2 {
      font-size: 25px; } }
  @media screen and (max-width: 767px) {
    article h1,
    .page-dev-durable-actualite.i18n-fr #block-system-main > div > div > h2,
    .page-sustainability-news.i18n-en #block-system-main > div > div > h2 {
      font-family: 'gotham-bold', 'alto_pro_thin', Helvetica, Arial, sans-serif; } }
  @media screen and (max-width: 450px) {
    article h1,
    .page-dev-durable-actualite.i18n-fr #block-system-main > div > div > h2,
    .page-sustainability-news.i18n-en #block-system-main > div > div > h2 {
      font-size: 22px; } }

.i18n-ja article h1 {
  font-family: 'SourceHanSansCN-Bold', 'alto_pro_thin', Helvetica, Arial, sans-serif;
  font-weight: bold; }

/**
 * @file
 * Styles for system messages and progress bar.
 */
/* ---------------- Top of page link  -----------------*/
/* ---------------- Message Styling -----------------*/
/* ---------------- Progress Bar Styling -----------------*/
/* ---------------- Alert Styling -----------------*/
/**
 * @file
 * Branding Styling
 */
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/*************************************************************************************
  Import tous les fichiers _name.scss liés au layout.
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
/*********************************************************************************
 _    _             _       _                      _   _
| |  | |           (_)     | |                    | | (_)
| | _| | ___  ___   _ _ __ | |_ ___ _ __ __ _  ___| |_ ___   _____
| |/ / |/ _ \/ _ \ | | '_ \| __/ _ \ '__/ _` |/ __| __| \ \ / / _ |   <| |  __/  __/ | | | | | ||  __/ | | (_| | (__| |_| |\ V /  __/
|_|\_\_|\___|\___| |_|_| |_|\__\___|_|  \__,_|\___|\__|_| \_/ \___|

*********************************************************************************/
/**
 * @file
 * Font Variables
 */
@font-face {
  font-family: 'altopro_con_regular';
  src: url("../fonts/altoprocon-normal-webfont.eot");
  src: url("../fonts/altoprocon-normal-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/altoprocon-normal-webfont.woff") format("woff"), url("../fonts/altoprocon-normal-webfont.ttf") format("truetype"), url("../fonts/altoprocon-normal-webfont.svg#alto_pro_con_norregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alto_pro_thin';
  src: url("../fonts/altopro-thin-webfont.eot");
  src: url("../fonts/altopro-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/altopro-thin-webfont.woff") format("woff"), url("../fonts/altopro-thin-webfont.ttf") format("truetype"), url("../fonts/altopro-thin-webfont.svg#alto_pro_thregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'alto_pro_lt';
  src: url("../fonts/altopro-light-webfont.eot");
  src: url("../fonts/altopro-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/altopro-light-webfont.woff") format("woff"), url("../fonts/altopro-light-webfont.ttf") format("truetype"), url("../fonts/altopro-light-webfont.svg#alto_pro_ltregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SimSun';
  src: url("../fonts/SimSun.eot");
  src: url("../fonts/SimSun.eot?#iefix") format("embedded-opentype"), url("../fonts/SimSun.woff") format("woff"), url("../fonts/SimSun.ttf") format("truetype"), url("../fonts/SimSun.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD; }

@font-face {
  font-family: 'keringregular';
  src: url("../fonts/kering-regular-webfont.eot");
  src: url("../fonts/kering-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/kering-regular-webfont.woff2") format("woff2"), url("../fonts/kering-regular-webfont.woff") format("woff"), url("../fonts/kering-regular-webfont.ttf") format("truetype"), url("../fonts/kering-regular-webfont.svg#keringregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'altopro';
  src: url("../fonts/AltoPro-Normal.eot");
  src: url("../fonts/AltoPro-Normal.eot?#iefix") format("embedded-opentype"), url("../fonts/AltoPro-Normal.woff2") format("woff2"), url("../fonts/AltoPro-Normal.woff") format("woff"), url("../fonts/AltoPro-Normal.ttf") format("truetype"), url("../fonts/AltoPro-Normal.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-black';
  src: url("../fonts/gotham-black.eot");
  src: url("../fonts/gotham-black.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-black.woff") format("woff"), url("../fonts/gotham-black.ttf") format("truetype"), url("../fonts/gotham-black.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-ultra';
  src: url("../fonts/gotham-ultra.eot");
  src: url("../fonts/gotham-ultra.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-ultra.woff") format("woff"), url("../fonts/gotham-ultra.ttf") format("truetype"), url("../fonts/gotham-ultra.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url("../fonts/SourceHanSansCN-Bold.otf");
  src: url("../fonts/SourceHanSansCN-Bold.otf?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url("../fonts/SourceHanSansCN-Normal.otf");
  src: url("../fonts/SourceHanSansCN-Normal.otf?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-book';
  src: url("../fonts/gotham-book.eot");
  src: url("../fonts/gotham-book.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-book.woff") format("woff"), url("../fonts/gotham-book.ttf") format("truetype"), url("../fonts/gotham-book.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-bold';
  src: url("../fonts/gotham-bold.eot");
  src: url("../fonts/gotham-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-bold.woff") format("woff"), url("../fonts/gotham-bold.ttf") format("truetype"), url("../fonts/gotham-bold.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-medium';
  src: url("../fonts/gotham-medium.eot");
  src: url("../fonts/gotham-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-medium.woff") format("woff"), url("../fonts/gotham-medium.ttf") format("truetype"), url("../fonts/gotham-medium.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-light';
  src: url("../fonts/gotham-light.eot");
  src: url("../fonts/gotham-light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-light.woff") format("woff"), url("../fonts/gotham-light.ttf") format("truetype"), url("../fonts/gotham-light.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gotham-book-italic';
  src: url("../fonts/gotham-book-italic.eot");
  src: url("../fonts/gotham-book-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-book-italic.woff") format("woff"), url("../fonts/gotham-book-italic.ttf") format("truetype"), url("../fonts/gotham-book-italic.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Radley';
  src: url("../fonts/Radley-Regular.eot");
  src: url("../fonts/Radley-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Radley-Regular.woff") format("woff"), url("../fonts/Radley-Regular.ttf") format("truetype"), url("../fonts/Radley-Regular.svg#radleyregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'AdobeFanHeitiStd-Bold';
  src: url("../fonts/AdobeFanHeitiStd-Bold.eot");
  src: url("../fonts/AdobeFanHeitiStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/AdobeFanHeitiStd-Bold.woff") format("woff"), url("../fonts/AdobeFanHeitiStd-Bold.ttf") format("truetype"), url("../fonts/AdobeFanHeitiStd-Bold.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'AdobeHeitiStd-Regular';
  src: url("../fonts/AdobeHeitiStd-Regular.eot");
  src: url("../fonts/AdobeHeitiStd-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AdobeHeitiStd-Regular.woff") format("woff"), url("../fonts/AdobeHeitiStd-Regular.ttf") format("truetype"), url("../fonts/AdobeHeitiStd-Regular.svg#altopro") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * @file
 * Breakpoint Variables
 */
/**
 * @file
 * header Styling
 */
/* ---------------- DEFAULT -----------------*/
header {
  margin: auto;
  position: relative;
  width: 1440px;
  max-width: 100%;
  background-color: #fff; }
  @media screen and (max-width: 767px) {
    header ul.menu li {
      padding: 0 16px 25px; } }
  header div.header-toolbar {
    top: 25px;
    text-align: left; }
    header div.header-toolbar ul li.header-toolbar-search {
      background-position: -56px 10px;
      margin: 0 5px 0 -30px;
      height: 36px;
      width: 36px;
      border: 1px solid #B2B2B2; }
      header div.header-toolbar ul li.header-toolbar-search.activesearch {
        margin-bottom: -12px;
        height: 50px;
        width: 38px;
        border: none;
        background: #f1f1f1 -55px 11px; }
      header div.header-toolbar ul li.header-toolbar-search ul.header-toolbar-search-wrapper {
        position: absolute;
        display: block;
        top: 50px;
        padding: 0; }
        header div.header-toolbar ul li.header-toolbar-search ul.header-toolbar-search-wrapper li {
          background: #f1f1f1;
          display: block;
          height: auto;
          margin: 0 auto;
          padding: 5px 0;
          position: relative;
          right: 400px;
          text-align: right;
          width: 424px; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    header .language_switcher ul li.header-toolbar-language-switcher ul li {
      margin: 0 0 0 10px; }
      header .language_switcher ul li.header-toolbar-language-switcher ul li a {
        font-size: 15px;
        padding: 12px 4px; } }

.header-wrapper {
  position: relative;
  max-width: 100%;
  height: 168px; }
  @media screen and (max-width: 1439px) {
    .header-wrapper {
      height: 130px; } }
  @media screen and (max-width: 767px) {
    .header-wrapper {
      height: 80px; } }
  @media screen and (max-width: 767px) {
    i18n-ja .header-wrapper,
    .i18n-zh-hans .header-wrapper {
      height: 120px; } }

.region-header {
  display: block;
  box-sizing: border-box;
  margin-left: 165px;
  margin-right: 375px;
  padding-top: 75px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
  background-color: #fff; }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .region-header {
      margin-right: 290px;
      margin-left: 145px;
      padding-top: 50px; } }
  @media screen and (max-width: 1023px) {
    .region-header {
      padding: 30px 0 0 0;
      width: 100%;
      margin: 0; }
      .region-header ul.menu {
        margin-bottom: 0;
        margin-top: 0;
        display: table;
        width: 100%;
        text-align: center;
        padding: 0; }
        .region-header ul.menu li {
          padding: 0 0 25px; }
          .region-header ul.menu li a {
            font-size: 12px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .region-header {
      margin: 0;
      box-sizing: border-box; } }
  @media screen and (max-width: 767px) {
    .region-header {
      padding-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding-left: 0; } }

.logo {
  float: left;
  display: block;
  height: inherit;
  margin-left: 15px;
  width: 150px;
  padding-top: 60px;
  box-sizing: border-box; }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .logo {
      padding-top: 45px;
      width: 130px; } }
  @media screen and (max-width: 1023px) {
    .logo {
      width: 150px;
      padding-top: 50px;
      margin-left: 50px; } }
  @media screen and (max-width: 767px) {
    .logo {
      position: absolute;
      z-index: 800;
      padding-top: 20px;
      width: 130px;
      margin-left: calc((100% - 160px) / 2); } }
  .logo img {
    vertical-align: middle;
    width: 100% !important;
    max-width: 100%;
    -webkit-backface-visibility: hidden;
    height: auto; }
    @media screen and (max-width: 767px) {
      .logo img {
        margin: 0 auto; } }

.region-header-right {
  float: right;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 375px;
  background: #000000;
  z-index: 1000;
  height: 168px; }
  @media screen and (max-width: 1439px) {
    .region-header-right {
      height: 130px;
      right: -75px; } }
  @media screen and (max-width: 1023px) {
    .region-header-right {
      margin-top: 0; } }
  @media screen and (max-width: 767px) {
    .region-header-right {
      right: 0;
      height: 80px;
      width: 80px; } }
  @media screen and (max-width: 767px) {
    .i18n-ja .region-header-right,
    .i18n-zh-hans .region-header-right {
      display: none;
      height: 100px; } }

.i18n-ja header .logo img {
  margin-top: 0; }

.i18n-ja #search {
  display: none; }

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .i18n-zh-hans header .header-wrapper .logo img {
    margin-top: -12px; } }

.i18n-fr header div#block-ppr-header-footer-header-footer-stock .stock-block {
  margin-right: 20px; }

.i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul li, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul li {
  margin-left: 0; }

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul > li, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul > li {
    margin-left: 0; }
    .i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul > li:first-of-type, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul > li:first-of-type {
      margin-left: 0; }
    .i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul > li > a, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu ul > li > a {
      font-size: 10px; } }

.i18n-zh-hans header .header-wrapper #block-ppr-header-footer-header-footer-header-socials, .i18n-ja header .header-wrapper #block-ppr-header-footer-header-footer-header-socials {
  right: -150px; }

.i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-lg-switch, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-lg-switch {
  right: -270px; }

.i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-search-top, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-search-top {
  display: none; }

@media screen and (max-width: 767px) {
  .i18n-ja header .logo img {
    margin-top: 0; }
  .i18n-ja header .region-header-right {
    height: 79px;
    width: 80px;
    background: #000 640px 0; }
  .i18n-ja header .region-header {
    padding-top: 0; }
  .i18n-ja .region-header {
    width: 100%; }
  .i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li {
    display: block;
    width: 100%; }
    .i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.facebook, .i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.twitter, .i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.instagram, .i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.youtube {
      display: none; }
  .i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu .social ul.menu li {
    display: inline-block;
    width: 16%; }
  .i18n-zh-hans header .logo {
    top: 10px;
    position: absolute; }
  .i18n-zh-hans header #block-ppr-header-footer-refonte-header-footer-search-top {
    display: block; }
  .i18n-zh-hans .region-header {
    width: 100%; }
  .i18n-zh-hans .block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li {
    display: block;
    width: 100%; }
    .i18n-zh-hans .block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.facebook, .i18n-zh-hans .block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.twitter, .i18n-zh-hans .block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.instagram, .i18n-zh-hans .block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.youtube {
      display: none; }
  .i18n-zh-hans .block-ppr-header-footer-refonte-header-footer-top-menu .social ul.menu li {
    display: inline-block;
    width: 16%; } }

/*
#block-ppr-header-footer-refonte-header-footer-top-menu,
#block-ppr-header-footer-refonte-header-footer-search-top,
#block-ppr-header-footer-refonte-header-footer-lg-switch,
#block-ppr-header-footer-header-footer-stock {
  display: block;
}

#block-ppr-header-footer-refonte-header-footer-top-menu ul {
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}
#block-ppr-header-footer-header-footer-header-socials ul li{
  margin: 0 0 0 24px;
  padding: 0;
}

#block-ppr-header-footer-header-footer-header-socials ul li a {
  background-image: url("../images/kering-refonte-sprite.png");
  color: transparent;
  display: block;
  height: 30px;
  text-indent: -9999px;
  width: 26px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.facebook a {
  background-position: 295px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.twitter a {
  background-position: 190px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.sinaweibo a {
  background-position: 408px 348px;
  height: 24px;
  padding-top: 0;
  width: 41px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.youku a {
  background-position: 332px 242px;
  height: 24px;
  width: 41px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.instagram a {
  background-position: 242px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.linkedin a {
  background-position: 137px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.youtube a {
  background-position: 84px 107px;
}*/
/* End of Sub menu ---------*/
/* ---------------- MOBILE -----------------*/
/* ---------------- TABLET -----------------*/
/* ---------------- DESKTOP -----------------*/
/**
 * @file
 * Footer Styling
 */
/* ---------------- DEFAULT -----------------*/
footer {
  color: #fff;
  background-color: #000;
  min-height: 245px;
  width: 100%;
  /*pied de page*/ }
  footer .footer-wrapper {
    position: relative;
    margin: 0 auto;
    padding-left: 65px;
    padding-top: 65px;
    box-sizing: border-box;
    position: relative;
    width: 1122px;
    max-width: 100%;
    /*footer-legals*/
    /*footer-newsletter*/ }
    @media screen and (max-width: 1023px) and (min-width: 768px) {
      footer .footer-wrapper {
        width: 82%;
        padding-left: 0; } }
    @media screen and (max-width: 767px) {
      footer .footer-wrapper {
        height: 245px;
        padding-top: 0;
        padding-left: 0; } }
    footer .footer-wrapper:before {
      bottom: 117px;
      content: "";
      height: 39px;
      position: absolute;
      right: 26px;
      width: 119px;
      background-image: url("../images/logo-kering-footer.png");
      background-size: 100% auto;
      /*bottom: 360px;
      right: 0;*/ }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        footer .footer-wrapper:before {
          right: auto;
          left: 56.5%; } }
      @media screen and (max-width: 767px) {
        footer .footer-wrapper:before {
          top: 144px !important;
          left: 0;
          position: absolute; } }
    footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials {
      top: 135px;
      right: 0; }
      footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials ul li {
        margin: 0 0 0 24px;
        padding: 0; }
        footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials ul li.facebook, footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials ul li.instagram, footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials ul li.twitter, footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials ul li.youtube {
          display: none; }
    footer .footer-wrapper.footer-zh-hans:before {
      height: 42px;
      width: 128px;
      top: 65px;
      right: 0;
      left: auto;
      bottom: auto; }
    footer .footer-wrapper.footer-zh-hans #block-simplenews-410 {
      position: absolute;
      right: 0;
      top: 278px; }
      footer .footer-wrapper.footer-zh-hans #block-simplenews-410:before {
        background: url("../images/kering-refonte-sprite.png") 191px 343px;
        bottom: 12px;
        content: "";
        height: 92px;
        position: absolute;
        right: 0;
        width: 92px; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        footer .footer-wrapper.footer-zh-hans #block-simplenews-410 {
          left: 52%;
          bottom: 242px;
          margin-left: 12px; }
          footer .footer-wrapper.footer-zh-hans #block-simplenews-410.block-simplenews h3 {
            margin-right: 10px;
            font-size: 8px; }
          footer .footer-wrapper.footer-zh-hans #block-simplenews-410.block-simplenews #newsletter-register {
            margin-right: 10px;
            font-size: 8px; } }
    footer .footer-wrapper.footer-ja #block-ppr-header-footer-header-footer-footer-socials {
      display: none; }
    footer .footer-wrapper.footer-ja #block-simplenews-410 {
      top: 80px; }
    footer .footer-wrapper.footer-ja:before {
      top: 65px;
      right: 0;
      left: auto;
      bottom: auto; }
    footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials {
      bottom: 74px;
      position: absolute;
      right: 15px; }
      footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul, footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li {
        list-style: none none;
        margin: 0;
        padding: 0; }
      footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li {
        display: inline-block;
        margin: 0 10px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li a {
          background-image: url(../images/kering-refonte-sprite.png);
          color: transparent;
          display: block;
          height: 18px;
          text-indent: -9999px;
          width: 18px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.facebook a {
          background-position: 298px 345px; }
          footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.facebook a:hover {
            background-position: 179px -98px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.twitter a {
          background-position: 298px 258px; }
          footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.twitter a:hover {
            background-position: 193px -117px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.sinaweibo a {
          background-position: 388px 346px;
          width: 20px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.youku a {
          background-position: 331px 241px;
          width: 39px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.instagram a {
          background-position: 298px 326px; }
          footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.instagram a:hover {
            background-position: 191px -161px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.linkedin a {
          background-position: 298px 306px; }
          footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.linkedin a:hover {
            background-position: 171px -140px; }
        footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.youtube a {
          background-position: 298px 284px; }
          footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials ul li.youtube a:hover {
            background-position: 191px -182px; }
    footer .footer-wrapper .footer-legals {
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 10px;
      margin: 90px 0 53px; }
      @media screen and (max-width: 767px) {
        footer .footer-wrapper .footer-legals {
          display: none; } }
    footer .footer-wrapper .footer-links {
      position: absolute;
      right: 10px;
      bottom: 35px; }
      footer .footer-wrapper .footer-links ul {
        margin: 0;
        padding: 0; }
        footer .footer-wrapper .footer-links ul li {
          display: inline-block;
          height: 12px;
          padding-left: 15px;
          padding-right: 15px;
          vertical-align: middle; }
          footer .footer-wrapper .footer-links ul li a {
            font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
            font-size: 8px;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff; }
            footer .footer-wrapper .footer-links ul li a:hover {
              color: #ebe9ea; }
          footer .footer-wrapper .footer-links ul li:nth-child(2) {
            border-left: 1px solid #fff;
            border-right: 1px solid #fff; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        footer .footer-wrapper .footer-links {
          width: 100%;
          left: 51.5%;
          bottom: 175px; }
          footer .footer-wrapper .footer-links ul {
            padding-left: 0; } }
    footer .footer-wrapper .footer-nav-block4 {
      display: none; }
    footer .footer-wrapper fieldset.footer-newsletter {
      border: 1px solid; }
      footer .footer-wrapper fieldset.footer-newsletter fieldset.footer-newsletter input[type="submit"] {
        background: #000 url("../images/kering-refonte-sprite.png") 363px 343px;
        border: medium none;
        color: transparent;
        height: 15px;
        margin-right: 10px;
        padding: 0;
        width: 13px; }
        footer .footer-wrapper fieldset.footer-newsletter fieldset.footer-newsletter input[type="submit"]:hover {
          background-position: 191px -205px; }
      footer .footer-wrapper fieldset.footer-newsletter fieldset.footer-newsletter #newsletter-register {
        background-color: #000;
        border: medium none;
        color: #fff;
        position: relative;
        font-family: 'gotham-light', Helvetica, Arial, sans-serif;
        font-size: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-top: 10px; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      footer .footer-wrapper:before {
        left: 54%;
        bottom: 260px;
        right: 0; }
      footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-menu > .content > ul > .expanded {
        margin-top: 70px; }
      footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-menu > .content > ul > #menu-group, footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-menu > .content > ul > #menu-brand, footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-menu > .content > ul > #menu-sustainability {
        margin-top: 0; }
      footer .footer-wrapper #block-ppr-header-footer-header-footer-footer-socials {
        left: 52%;
        bottom: 210px; }
      footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-menu ul {
        margin-right: 128px; }
        footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-menu ul li {
          margin-top: 0;
          margin-bottom: 15px;
          width: 126px; } }
    @media screen and (max-width: 767px) {
      footer .footer-wrapper {
        width: 100%;
        margin: 10px auto 0; }
        footer .footer-wrapper:before {
          bottom: auto;
          left: 0;
          right: 0;
          top: 177px !important;
          margin: auto;
          display: block;
          position: relative; }
        footer .footer-wrapper.footer-zh-hans:before {
          background-position: 125px -293px;
          height: 41px;
          top: 224px;
          width: 125px; }
        footer .footer-wrapper .language_switcher ul {
          list-style: none;
          padding: 0;
          margin: 0;
          float: right;
          text-align: center; }
          footer .footer-wrapper .language_switcher ul li.header-toolbar-language-switcher ul {
            line-height: normal;
            display: inline-block;
            vertical-align: middle;
            float: none; }
            footer .footer-wrapper .language_switcher ul li.header-toolbar-language-switcher ul li {
              display: inline-block;
              font-size: 16px;
              margin: 0 0 0 10px; }
              footer .footer-wrapper .language_switcher ul li.header-toolbar-language-switcher ul li.label {
                width: 100%; }
              footer .footer-wrapper .language_switcher ul li.header-toolbar-language-switcher ul li a {
                padding: 5px 10px;
                text-decoration: none;
                font-weight: bold; }
          footer .footer-wrapper .language_switcher ul .languages_list {
            display: none;
            top: 92px;
            width: 100%;
            position: absolute;
            left: 0;
            height: 80px;
            line-height: 80px; }
          footer .footer-wrapper .language_switcher ul .choose .languages_list {
            display: block; }
        footer .footer-wrapper .language_switcher span.current_language {
          background: none;
          padding: 0;
          margin: 0;
          font-weight: normal;
          height: auto;
          cursor: pointer; } }
  footer .footer-mob {
    display: none; }
    @media screen and (max-width: 767px) {
      footer .footer-mob {
        display: block;
        margin-bottom: 30px; }
        footer .footer-mob #block-ppr-header-footer-header-footer-mobile-stock {
          float: left;
          width: 30%;
          line-height: 16px;
          font-size: 17px;
          margin-left: 3%; }
          footer .footer-mob #block-ppr-header-footer-header-footer-mobile-stock a {
            color: #fff;
            text-decoration: none;
            line-height: 17px; }
          footer .footer-mob #block-ppr-header-footer-header-footer-mobile-stock p {
            color: #fff;
            font-family: 'gotham-book', Helvetica, Arial, sans-serif;
            font-size: 17px;
            line-height: 17px;
            margin: 0;
            padding: 0; }
            footer .footer-mob #block-ppr-header-footer-header-footer-mobile-stock p.stock-block-value {
              font-family: 'gotham-bold', Helvetica, Arial, sans-serif; }
        footer .footer-mob #block-ppr-header-footer-header-footer-mobile-language {
          float: right;
          width: 27%;
          margin-right: 7%;
          line-height: 21px;
          font-size: 17px; }
        footer .footer-mob #block-ppr-header-footer-header-footer-mobile-sitemap {
          font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
          float: right;
          font-size: 17px; }
          footer .footer-mob #block-ppr-header-footer-header-footer-mobile-sitemap a {
            color: #fff;
            font-size: 18px;
            text-transform: uppercase;
            text-decoration: none;
            line-height: 21px; } }
  footer .footer-wrapper.footer-ja #block-ppr-header-footer-header-footer-footer-menu, footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-menu {
    width: 592px; }
    footer .footer-wrapper.footer-ja #block-ppr-header-footer-header-footer-footer-menu ul li, footer .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-menu ul li {
      margin-top: 0;
      margin-bottom: 15px;
      width: 145px; }
  footer #block-ppr-header-footer-header-footer-footer-menu a {
    color: #fff; }
    footer #block-ppr-header-footer-header-footer-footer-menu a.hover {
      color: #ebe9ea; }
  footer #block-ppr-header-footer-header-footer-footer-menu ul {
    margin: 0;
    padding: 0; }
    footer #block-ppr-header-footer-header-footer-footer-menu ul li {
      display: inline-block;
      vertical-align: top;
      padding: 0 15px 0 0;
      margin: 0;
      max-width: 180px;
      width: 15%;
      box-sizing: border-box; }
      @media screen and (max-width: 1023px) and (min-width: 768px) {
        footer #block-ppr-header-footer-header-footer-footer-menu ul li {
          width: 33%;
          max-width: none; } }
      footer #block-ppr-header-footer-header-footer-footer-menu ul li.kmag {
        display: none; }
      footer #block-ppr-header-footer-header-footer-footer-menu ul li a {
        font-family: 'gotham-ultra', Helvetica, Arial, sans-serif;
        font-size: 10px;
        text-decoration: none;
        text-transform: uppercase; }
      footer #block-ppr-header-footer-header-footer-footer-menu ul li ul {
        padding: 0;
        background-image: none !important;
        margin-top: 17px; }
        footer #block-ppr-header-footer-header-footer-footer-menu ul li ul li {
          margin-bottom: 6px;
          width: 100%;
          padding: 0; }
          @media screen and (max-width: 1023px) and (min-width: 768px) {
            footer #block-ppr-header-footer-header-footer-footer-menu ul li ul li {
              width: 100%; } }
          footer #block-ppr-header-footer-header-footer-footer-menu ul li ul li a {
            font-family: 'gotham-book', Helvetica, Arial, sans-serif;
            text-transform: none; }
          footer #block-ppr-header-footer-header-footer-footer-menu ul li ul li ul {
            background-image: none !important;
            margin-top: 6px; }
      footer #block-ppr-header-footer-header-footer-footer-menu ul li.leaf {
        list-style: none none; }
  @media screen and (max-width: 767px) {
    footer {
      padding-left: 5%;
      padding-right: 5%;
      width: 90%; }
      footer .social {
        clear: left;
        padding-top: 170px;
        display: none; }
        footer .social ul {
          padding: 0;
          text-align: center; }
          footer .social ul li {
            display: inline-block;
            margin: 0;
            padding: 0;
            text-align: center;
            width: 17%; }
            footer .social ul li a {
              background: url("../images/kering-refonte-sprite.png") 295px 110px;
              color: transparent;
              display: block;
              height: 30px;
              margin: auto;
              padding: 0;
              text-indent: -9999px;
              width: 26px; }
            footer .social ul li.twitter a {
              background-position: 190px 110px; }
            footer .social ul li.sinaweibo a {
              background-position: 331px 243px;
              height: 24px;
              padding-top: 0;
              width: 41px; }
            footer .social ul li.youku a {
              background-position: 388px 350px;
              height: 26px;
              width: 22px; }
            footer .social ul li.instagram a {
              background-position: 242px 110px; }
            footer .social ul li.linkedin a {
              background-position: 137px 110px; }
            footer .social ul li.youtube a {
              background-position: 84px 110px; }
      footer #block-ppr-header-footer-header-footer-mobile-search-top.block.block-ppr-header-footer {
        display: none; }
      footer nav {
        display: none; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .i18n-zh-hans .footer-wrapper.footer-zh-hans #block-ppr-header-footer-header-footer-footer-socials {
    top: 150px;
    left: auto; }
  .i18n-zh-hans .footer-wrapper.footer-zh-hans #block-simplenews-410 {
    position: absolute;
    right: 0;
    top: 283px;
    left: auto; }
    .i18n-zh-hans .footer-wrapper.footer-zh-hans #block-simplenews-410:before {
      background: url("../images/kering-refonte-sprite.png") 191px 343px;
      bottom: 12px;
      content: "";
      height: 92px;
      position: absolute;
      width: 92px; }
  .i18n-zh-hans #block-ppr-header-footer-header-footer-footer-menu ul li a {
    font-family: gotham-ultra, Verdana, Arial, sans-serif;
    font-size: 10px;
    text-decoration: none;
    text-transform: uppercase; }
  .i18n-zh-hans .footer-legals {
    font-family: gotham-book;
    font-size: 10px;
    margin: 160px 0 53px; }
  .i18n-ja .footer-wrapper:before, .i18n-ja .i18n-zh-hans .footer-wrapper:before {
    bottom: 360px;
    left: auto; }
  .i18n-ja #block-simplenews-410 {
    left: auto;
    bottom: 20px;
    position: absolute;
    right: 0; } }

@media screen and (max-width: 767px) {
  .i18n-zh-hans footer .language_switcher ul .languages_list, .i18n-ja footer .language_switcher ul .languages_list {
    display: none;
    top: 66px;
    width: 100%;
    position: absolute;
    left: 0;
    height: 80px;
    line-height: 80px; }
  .i18n-zh-hans footer .social ul li.twitter, .i18n-zh-hans footer .social ul li.youtube, .i18n-zh-hans footer .social ul li.facebook, .i18n-zh-hans footer .social ul li.instagram {
    display: none; }
  .i18n-zh-hans .footer-legals {
    font-family: 'AdobeHeitiStd-Regular', 'gotham-book', Arial, Verdana, 'SimSun';
    font-size: 10px;
    margin: 160px 0 53px; } }

@media screen and (min-width: 768px) {
  .i18n-zh-hans #main-wrapper footer {
    min-height: 305px; } }

/**
 * @file
 * Main Layout Styling
 */
/* ---------------- DEFAULT -----------------*/
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, footer, header, nav, section {
  display: block; }

section nav .title {
  font-size: 3.2em;
  line-height: 1em;
  font-family: 'alto_pro_thin', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  margin: 20px 0 30px;
  font-weight: normal; }

.main-wrapper {
  position: absolute;
  width: 100%;
  overflow: hidden; }

.main-wrapper > header,
.main-wrapper > .region-content,
.main-wrapper > section.rubrique,
.main-wrapper > section.firstsection {
  position: relative;
  background: #ffffff url("../images/background.png") repeat-y;
  margin: auto;
  padding-left: 110px;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box; }
  @media screen and (max-width: 1439px) {
    .main-wrapper > header,
    .main-wrapper > .region-content,
    .main-wrapper > section.rubrique,
    .main-wrapper > section.firstsection {
      background: #ffffff url("../images/background_1025.png") repeat-y;
      margin: auto;
      padding-left: 64px; } }
  @media screen and (max-width: 767px) {
    .main-wrapper > header,
    .main-wrapper > .region-content,
    .main-wrapper > section.rubrique,
    .main-wrapper > section.firstsection {
      background-position: -45px 0 !important;
      padding-left: 20px; } }

.front .news-slider-content {
  position: relative;
  left: -110px;
  width: calc(100% + 110px); }
  @media screen and (max-width: 1439px) {
    .front .news-slider-content {
      left: -64px;
      width: calc(100% + 64px); } }
  @media screen and (max-width: 767px) {
    .front .news-slider-content {
      left: -20px;
      width: calc(100% + 20px); } }

div.blackbg {
  background: #000;
  padding: 0; }

.content-wrapper.clearfix {
  position: relative;
  padding-left: 90px;
  padding-right: 15px;
  box-sizing: border-box;
  max-width: 1240px; }
  @media screen and (max-width: 1439px) {
    .content-wrapper.clearfix {
      padding-left: 50px; } }
  @media screen and (max-width: 767px) {
    .content-wrapper.clearfix {
      padding-left: 15px; } }

.region-main-block {
  background-color: #fbfbfb;
  height: 80px;
  margin-left: 0;
  padding-left: 0;
  width: 100%; }
  @media screen and (max-width: 1439px) {
    .region-main-block {
      height: 60px; } }

.node-news .block-content-description,
.content-type-contenu,
.content-type-contenu .field-name-field-content {
  margin: 0;
  font-family: 'gotham-book', Helvetica, Arial, sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 27px; }
  @media screen and (max-width: 1023px) {
    .node-news .block-content-description,
    .content-type-contenu,
    .content-type-contenu .field-name-field-content {
      font-size: 14px;
      line-height: 26px; } }
  @media screen and (max-width: 767px) {
    .node-news .block-content-description,
    .content-type-contenu,
    .content-type-contenu .field-name-field-content {
      font-size: 14px;
      line-height: 26px; } }
  .node-news .block-content-description strong,
  .content-type-contenu strong,
  .content-type-contenu .field-name-field-content strong {
    font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
    font-weight: normal; }
  .node-news .block-content-description div > em,
  .content-type-contenu div > em,
  .content-type-contenu .field-name-field-content div > em {
    font-family: 'radley', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 23px;
    line-height: 32px; }
  .node-news .block-content-description p,
  .content-type-contenu p,
  .content-type-contenu .field-name-field-content p {
    margin: 0 0 2em 0;
    text-align: justify; }
  .node-news .block-content-description div + p,
  .content-type-contenu div + p,
  .content-type-contenu .field-name-field-content div + p {
    margin-top: 2em; }
  .node-news .block-content-description .block-content-description,
  .content-type-contenu .block-content-description,
  .content-type-contenu .field-name-field-content .block-content-description {
    font-size: 18px;
    line-height: 28px;
    color: #666;
    font-weight: normal; }
    @media screen and (max-width: 1023px) {
      .node-news .block-content-description .block-content-description,
      .content-type-contenu .block-content-description,
      .content-type-contenu .field-name-field-content .block-content-description {
        font-size: 17px;
        line-height: 27px; } }
    @media screen and (max-width: 767px) {
      .node-news .block-content-description .block-content-description,
      .content-type-contenu .block-content-description,
      .content-type-contenu .field-name-field-content .block-content-description {
        font-size: 16px;
        line-height: 26px; } }
  .node-news .block-content-description .block-content-intro,
  .content-type-contenu .block-content-intro,
  .content-type-contenu .field-name-field-content .block-content-intro {
    color: #333;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 0;
    font-family: 'gotham-book', Helvetica, Arial, sans-serif; }
    @media screen and (max-width: 1023px) {
      .node-news .block-content-description .block-content-intro,
      .content-type-contenu .block-content-intro,
      .content-type-contenu .field-name-field-content .block-content-intro {
        font-size: 17px;
        line-height: 27px; } }
    @media screen and (max-width: 767px) {
      .node-news .block-content-description .block-content-intro,
      .content-type-contenu .block-content-intro,
      .content-type-contenu .field-name-field-content .block-content-intro {
        font-size: 16px;
        line-height: 26px; } }
  .node-news .block-content-description h3 + p.block-content-intro,
  .content-type-contenu h3 + p.block-content-intro,
  .content-type-contenu .field-name-field-content h3 + p.block-content-intro {
    margin-top: 1em; }
  .node-news .block-content-description ul li p,
  .content-type-contenu ul li p,
  .content-type-contenu .field-name-field-content ul li p {
    margin: 0; }
    .node-news .block-content-description ul li p + p,
    .content-type-contenu ul li p + p,
    .content-type-contenu .field-name-field-content ul li p + p {
      margin: 2em 0 0 0; }
  .node-news .block-content-description table th, .node-news .block-content-description table td,
  .content-type-contenu table th,
  .content-type-contenu table td,
  .content-type-contenu .field-name-field-content table th,
  .content-type-contenu .field-name-field-content table td {
    vertical-align: middle;
    font-size: 14px;
    line-height: 22px;
    color: #000; }
    @media screen and (max-width: 767px) {
      .node-news .block-content-description table th, .node-news .block-content-description table td,
      .content-type-contenu table th,
      .content-type-contenu table td,
      .content-type-contenu .field-name-field-content table th,
      .content-type-contenu .field-name-field-content table td {
        font-size: 13px;
        line-height: 21px; } }

.front-page .panels-flexible-region-front-page-main_block,
.front-finance .panels-flexible-region-front-finance-main_block,
.panels-flexible-panel_front_talents .panels-flexible-region-panel_front_talents-main_block,
.node-type-brand .region-main-block,
section .section-slider .content-wrapper {
  background-color: #f8f7f7;
  margin-left: -110px;
  padding-left: 110px;
  height: 394px;
  width: 100%; }
  @media screen and (max-width: 1439px) {
    .front-page .panels-flexible-region-front-page-main_block,
    .front-finance .panels-flexible-region-front-finance-main_block,
    .panels-flexible-panel_front_talents .panels-flexible-region-panel_front_talents-main_block,
    .node-type-brand .region-main-block,
    section .section-slider .content-wrapper {
      margin-left: -65px;
      padding-left: 65px;
      height: auto;
      max-width: none; } }
  @media screen and (max-width: 767px) {
    .front-page .panels-flexible-region-front-page-main_block,
    .front-finance .panels-flexible-region-front-finance-main_block,
    .panels-flexible-panel_front_talents .panels-flexible-region-panel_front_talents-main_block,
    .node-type-brand .region-main-block,
    section .section-slider .content-wrapper {
      padding-left: 20px;
      margin-left: -20px; } }

.featured-slide {
  display: inline-block;
  max-width: 60%;
  width: 785px;
  padding-left: 90px;
  box-sizing: border-box; }
  @media screen and (max-width: 1439px) {
    .featured-slide {
      padding-left: 50px; } }
  @media screen and (max-width: 1023px) {
    .featured-slide {
      max-width: 100%;
      padding-right: 80px; } }
  @media screen and (max-width: 767px) {
    .featured-slide {
      padding-left: 60px;
      padding-right: 80px;
      height: auto;
      line-height: normal; } }
  @media screen and (max-width: 450px) {
    .featured-slide {
      padding-left: 20px;
      padding-right: 20px;
      height: auto;
      line-height: normal; } }
  .node-panel .featured-slide {
    height: 394px;
    line-height: 394px; }
    @media screen and (max-width: 767px) {
      .node-panel .featured-slide {
        height: auto;
        line-height: normal; } }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    .node-panel .featured-slide {
      padding-bottom: 50px; } }
  .featured-slide .featured-content {
    line-height: normal;
    display: inline-block;
    vertical-align: middle; }
    .featured-slide .featured-content h1 {
      color: #333;
      font-family: 'gotham-medium', Helvetica, Arial, sans-serif;
      font-size: 30px;
      font-weight: normal;
      line-height: 26px;
      text-transform: uppercase;
      margin-bottom: 20px; }
      @media screen and (max-width: 767px) {
        .featured-slide .featured-content h1 {
          padding-top: 50px; } }
      .featured-slide .featured-content h1 + p {
        margin-bottom: 20px; }
    .featured-slide .featured-content p {
      font-family: 'Radley', Helvetica, Arial, sans-serif;
      font-size: 17px;
      line-height: 21px;
      margin: 0;
      padding-bottom: 0; }
      @media screen and (max-width: 1023px) {
        .featured-slide .featured-content p {
          font-size: 16px;
          line-height: 20px; } }
      @media screen and (max-width: 767px) {
        .featured-slide .featured-content p {
          font-size: 15px;
          line-height: 19px; } }
      .featured-slide .featured-content p a {
        display: inline-block;
        vertical-align: top;
        margin: 20px .5em 0 0; }
    .featured-slide .featured-content a {
      color: #333232;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 13px;
      text-decoration: none; }
      .featured-slide .featured-content a:hover {
        color: #a7a8ac;
        font-family: 'gotham-light', Helvetica, Arial, sans-serif; }
      .featured-slide .featured-content a span {
        font-family: 'gotham-light';
        font-size: 12px;
        line-height: 1em;
        font-style: normal; }
        @media screen and (max-width: 767px) {
          .featured-slide .featured-content a span {
            font-size: 16px; } }
    .featured-slide .featured-content img {
      max-width: 100%;
      height: auto; }

.slider.slider_hidden {
  float: right;
  vertical-align: top;
  text-align: right;
  width: 375px; }
  @media screen and (max-width: 1439px) {
    .slider.slider_hidden {
      width: 300px; } }
  @media screen and (max-width: 1023px) {
    .slider.slider_hidden {
      display: none; } }

.page-communiques-de-presse article .featured-block,
.page-press-releases article .featured-block,
#block-system-main > .content > .presentation-block,
.block-content-article,
#messages,
#ppr-alert-press-release-register-form {
  padding-left: 130px;
  margin-top: 0;
  width: 821px;
  max-width: 98%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (max-width: 1439px) {
    .page-communiques-de-presse article .featured-block,
    .page-press-releases article .featured-block,
    #block-system-main > .content > .presentation-block,
    .block-content-article,
    #messages,
    #ppr-alert-press-release-register-form {
      padding-left: 100px;
      max-width: 98%; } }
  @media screen and (max-width: 1023px) {
    .page-communiques-de-presse article .featured-block,
    .page-press-releases article .featured-block,
    #block-system-main > .content > .presentation-block,
    .block-content-article,
    #messages,
    #ppr-alert-press-release-register-form {
      padding-left: 60px;
      max-width: 98%; } }
  @media screen and (max-width: 767px) {
    .page-communiques-de-presse article .featured-block,
    .page-press-releases article .featured-block,
    #block-system-main > .content > .presentation-block,
    .block-content-article,
    #messages,
    #ppr-alert-press-release-register-form {
      max-width: 90%;
      margin: 20px auto 0;
      padding: 0; } }

.not-logged-in.page-node-17 .block-content-article,
.not-logged-in #ppr-alert-press-release-register-form,
.not-logged-in.page-node-165 .block-content-article {
  margin-top: 65px; }
  @media screen and (max-width: 1439px) {
    .not-logged-in.page-node-17 .block-content-article,
    .not-logged-in #ppr-alert-press-release-register-form,
    .not-logged-in.page-node-165 .block-content-article {
      margin-top: 100px; } }
  @media screen and (max-width: 767px) {
    .not-logged-in.page-node-17 .block-content-article,
    .not-logged-in #ppr-alert-press-release-register-form,
    .not-logged-in.page-node-165 .block-content-article {
      margin-top: 15px; } }

.view-videotheque,
.masonry-publications {
  margin-left: 130px; }
  @media screen and (max-width: 1439px) {
    .view-videotheque,
    .masonry-publications {
      margin-left: 80px; } }
  @media screen and (max-width: 1110px) {
    .view-videotheque,
    .masonry-publications {
      margin-left: 90px; } }
  @media screen and (max-width: 767px) {
    .view-videotheque,
    .masonry-publications {
      margin: 20px auto 0; } }
  @media screen and (max-width: 767px) {
    .view-videotheque .masonry-videos,
    .masonry-publications .masonry-videos {
      max-width: 100%;
      width: 350px;
      margin: auto; } }
  .view-videotheque .masonry-item,
  .view-videotheque .masonry-item img,
  .masonry-publications .masonry-item,
  .masonry-publications .masonry-item img {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .view-videotheque .masonry-item,
      .view-videotheque .masonry-item img,
      .masonry-publications .masonry-item,
      .masonry-publications .masonry-item img {
        width: 100%;
        height: auto !important; } }

@media screen and (max-width: 1110px) and (min-width: 768px) {
  .view-videotheque, .masonry-videos {
    width: 350px;
    max-width: 100%; } }

#block-system-main > .content > .presentation-block > ul,
.block-content-article > ul {
  padding: 0;
  margin-left: 30px; }
  #block-system-main > .content > .presentation-block > ul li p:first-child,
  .block-content-article > ul li p:first-child {
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
    margin-bottom: 0; }
    #block-system-main > .content > .presentation-block > ul li p:first-child + p,
    .block-content-article > ul li p:first-child + p {
      margin-top: 1em; }
  #block-system-main > .content > .presentation-block > ul li + li,
  .block-content-article > ul li + li {
    margin-top: 15px; }

article p.readmore.return-link {
  padding-left: 130px;
  margin: 3em 0 5em 0;
  text-align: center;
  width: 821px;
  max-width: 98%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (max-width: 1439px) {
    article p.readmore.return-link {
      padding-left: 100px;
      max-width: 98%; } }
  @media screen and (max-width: 1023px) {
    article p.readmore.return-link {
      padding-left: 60px;
      max-width: 98%; } }
  @media screen and (max-width: 767px) {
    article p.readmore.return-link {
      max-width: 90%;
      margin: 20px auto 30px auto;
      padding: 0; } }

.admin-menu ul.tabs.primary {
  margin: 0 0 30px 130px;
  width: 691px;
  max-width: 80%; }
  @media screen and (max-width: 1439px) {
    .admin-menu ul.tabs.primary {
      margin: 0 0 30px 100px; } }
  @media screen and (max-width: 1023px) {
    .admin-menu ul.tabs.primary {
      margin: 0 0 30px 60px; } }
  @media screen and (max-width: 640px) {
    .admin-menu ul.tabs.primary {
      margin: 0 0 30px 50px; } }
  @media screen and (max-width: 450px) {
    .admin-menu ul.tabs.primary {
      margin: 0 0 30px 30px; } }

.section .content-wrapper article {
  margin: 65px 0 40px 0 !important;
  overflow: hidden;
  padding: 0;
  width: 870px; }
  @media screen and (max-width: 1439px) {
    .section .content-wrapper article {
      max-width: calc(100% - 250px);
      margin: 100px 0 0 0 !important; } }
  @media screen and (max-width: 1023px) {
    .section .content-wrapper article {
      max-width: calc(100% - 200px); } }
  @media screen and (max-width: 767px) {
    .section .content-wrapper article {
      width: 90%;
      max-width: 90%;
      margin: 20px auto 0 auto !important; } }

@media screen and (min-width: 1440px) {
  .page-node-8266.i18n-fr .section .content-wrapper article,
  .page-node-8266.i18n-en .section .content-wrapper article {
    width: auto; } }

@media screen and (min-width: 768px) {
  .page-node-8266.i18n-fr .section .content-wrapper article .block-content-intro,
  .page-node-8266.i18n-en .section .content-wrapper article .block-content-intro {
    width: 690px;
    max-width: 98%; } }

.page-node-8266.i18n-fr .section .content-wrapper article .block-content-article,
.page-node-8266.i18n-en .section .content-wrapper article .block-content-article {
  width: 98%; }

.i18n-ja .presentation-block,
.i18n-ja .block-content-article,
.i18n-zh-hans .presentation-block,
.i18n-zh-hans .block-content-article {
  width: 1200px; }

.i18n-ja aside,
.i18n-zh-hans aside {
  width: 200px;
  float: right;
  margin: 30px 0 0 0; }
  @media screen and (max-width: 1439px) {
    .i18n-ja aside,
    .i18n-zh-hans aside {
      margin: 30px 15px 0 0; } }
  @media screen and (max-width: 767px) {
    .i18n-ja aside,
    .i18n-zh-hans aside {
      display: none; } }

.i18n-ja.page-brands .section > .content-wrapper > article, .i18n-ja.page-node-8266 .section > .content-wrapper > article,
.i18n-zh-hans.page-brands .section > .content-wrapper > article,
.i18n-zh-hans.page-node-8266 .section > .content-wrapper > article {
  width: auto;
  max-width: 100%; }
  @media screen and (max-width: 767px) {
    .i18n-ja.page-brands .section > .content-wrapper > article, .i18n-ja.page-node-8266 .section > .content-wrapper > article,
    .i18n-zh-hans.page-brands .section > .content-wrapper > article,
    .i18n-zh-hans.page-node-8266 .section > .content-wrapper > article {
      max-width: 80%; } }

.i18n-ja.page-brands ul.achievements-list, .i18n-ja.page-node-8266 ul.achievements-list,
.i18n-zh-hans.page-brands ul.achievements-list,
.i18n-zh-hans.page-node-8266 ul.achievements-list {
  padding: 0;
  margin: 0; }

.i18n-ja.page-brands .presentation-block,
.i18n-ja.page-brands .block-content-article, .i18n-ja.page-node-8266 .presentation-block,
.i18n-ja.page-node-8266 .block-content-article,
.i18n-zh-hans.page-brands .presentation-block,
.i18n-zh-hans.page-brands .block-content-article,
.i18n-zh-hans.page-node-8266 .presentation-block,
.i18n-zh-hans.page-node-8266 .block-content-article {
  width: 1330px; }
  .i18n-ja.page-brands .presentation-block .article-item-wrapper .article-item,
  .i18n-ja.page-brands .block-content-article .article-item-wrapper .article-item, .i18n-ja.page-node-8266 .presentation-block .article-item-wrapper .article-item,
  .i18n-ja.page-node-8266 .block-content-article .article-item-wrapper .article-item,
  .i18n-zh-hans.page-brands .presentation-block .article-item-wrapper .article-item,
  .i18n-zh-hans.page-brands .block-content-article .article-item-wrapper .article-item,
  .i18n-zh-hans.page-node-8266 .presentation-block .article-item-wrapper .article-item,
  .i18n-zh-hans.page-node-8266 .block-content-article .article-item-wrapper .article-item {
    padding-left: 0; }

.i18n-ja .node-news .presentation-block,
.i18n-ja .node-news .block-content-article,
.i18n-zh-hans .node-news .presentation-block,
.i18n-zh-hans .node-news .block-content-article {
  max-width: 87%;
  width: 760px; }

.i18n-ja .block-content-article > .block-content-intro,
.i18n-ja .block-content-article .article-item-wrapper.content-type-contenu,
.i18n-zh-hans .block-content-article > .block-content-intro,
.i18n-zh-hans .block-content-article .article-item-wrapper.content-type-contenu {
  max-width: 80%; }
  @media screen and (max-width: 767px) {
    .i18n-ja .block-content-article > .block-content-intro,
    .i18n-ja .block-content-article .article-item-wrapper.content-type-contenu,
    .i18n-zh-hans .block-content-article > .block-content-intro,
    .i18n-zh-hans .block-content-article .article-item-wrapper.content-type-contenu {
      max-width: 98%; } }

.i18n-ja p.readmore.return-link a,
.i18n-zh-hans p.readmore.return-link a {
  font-family: "gotham-black"; }

.media-vimeo-video,
div.media-youtube-video {
  margin-bottom: 15px;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 */
  position: relative;
  background: black; }
  .media-vimeo-video iframe,
  div.media-youtube-video iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-size: 24px;
    text-align: center; }

/**
 * @file
 * Sidebars Layout Styling
 */
/* ---------------- IMPORT -----------------*/
/* Achievements */
.aside-banner {
  background: #CBCBCB;
  padding: 0;
  margin-bottom: 15px;
  width: 190px; }
  .aside-banner p {
    padding: 0 5px 5px;
    color: #333;
    margin: 0; }
    .aside-banner p a {
      color: #333;
      text-decoration: none;
      font-size: 1em; }
  .aside-banner.blackbg p {
    padding: 10px 5px 10px 5px;
    color: #fff;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.4em;
    font-family: alto_pro_lt, sans-serif; }
    .aside-banner.blackbg p a {
      color: #fff;
      text-decoration: none;
      font-size: 1em; }

@media screen and (max-width: 767px) {
  nav.left {
    display: none; } }

nav.left .aside-banner img {
  width: 190px; }

nav.left a {
  color: #000;
  font-family: gotham-black;
  font-size: 14px;
  text-decoration: none; }

#leftmenu {
  float: none;
  margin: 0;
  padding: 0; }
  #leftmenu p {
    font-family: 'gotham-black', Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-left: 29px; }

section .content-wrapper nav {
  display: block;
  width: 200px;
  float: left;
  margin-top: 65px;
  margin-bottom: 60px;
  padding-left: 65px; }
  @media screen and (max-width: 1439px) {
    section .content-wrapper nav {
      margin-top: 100px;
      padding-left: 30px; } }
  @media screen and (max-width: 1023px) {
    section .content-wrapper nav {
      margin-top: 100px;
      padding-left: 0px; } }

section nav .title {
  margin-top: 4px; }

section nav ul.menu-left {
  padding: 0; }
  section nav ul.menu-left > li {
    cursor: pointer;
    display: block;
    font-family: 'gotham-light', 'alto_pro_lt', Helvetica, Arial, sans-serif;
    font-size: 1.3em;
    margin-bottom: 25px;
    margin-left: 30px;
    text-transform: uppercase; }
    section nav ul.menu-left > li > a {
      color: #333;
      display: inline;
      font-family: 'gotham-black', Helvetica, Arial, sans-serif;
      font-size: 11px;
      line-height: 17px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 2px; }
    section nav ul.menu-left > li a.active, section nav ul.menu-left > li a:hover, section nav ul.menu-left > li ul > li:hover > a {
      border-bottom: 1px solid; }
    section nav ul.menu-left > li ul li a.active {
      border-bottom: 1px solid; }
  section nav ul.menu-left li ul {
    padding: 0;
    margin-left: 0; }
    section nav ul.menu-left li ul li {
      margin-bottom: 10px; }
      section nav ul.menu-left li ul li a {
        font-family: 'gotham-book', Helvetica, Arial, sans-serif;
        color: #6b6b6b;
        display: inline;
        font-size: 11px;
        text-decoration: none;
        text-transform: none; }

.left .region-sidebar-first .block-bean {
  margin-left: 30px;
  margin-bottom: 15px; }

.left .region-sidebar-first .quarter, .left .region-sidebar-first .tier {
  float: none;
  padding: 15px;
  height: auto;
  margin-right: 30px;
  width: 184px; }

.left #block-bean-bloc-groupe-contact-presse .quarter {
  width: 160px; }

.left #block-bean-bloc-groupe-contact-presse .quarter {
  background-color: #0d0d0d !important; }

/*second sidebar*/
.region-sidebar-second .quarter, .region-sidebar-second .tier,
.region-sidebar-second .quarter,
.region-sidebar-second .tier {
  width: 170px;
  float: none;
  padding: 15px;
  height: auto; }

/*
nav.left a {
  color: #000;
  font-family: gotham-black;
  font-size: 14px;
  text-decoration: none;
}
section nav ul.menu-left {
  padding: 0;
}
section nav ul.menu-left > li {
  cursor: pointer;
  display: block;
  font-family: 'gotham-light', 'alto_pro_lt', Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  margin-bottom: 25px;
  margin-left: 30px;
  text-transform: uppercase;
}

section nav ul.menu-left > li > a {
  color: #333;
  display: inline;
  font-family: 'gotham-black', Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 17px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

section nav ul.menu-left > li > a.active,
section nav ul.menu-left > li > a:hover,
section nav ul.menu-left > li > ul > li:hover > a {
  border-bottom: 1px solid;
}

section nav ul.menu-left li ul li a.active {
  border-bottom: 1px solid;

}

section nav ul.menu-left li ul {
  padding: 0;
  margin-left: 0
}

section nav ul.menu-left li ul li {
  margin-bottom: 10px;
}

section nav ul.menu-left li ul li a {
  font-family: 'gotham-book', Helvetica, Arial, sans-serif;
  color: #6b6b6b;
  display: inline;
  font-size: 11px;
  text-decoration: none;
  text-transform: none;
}

.left .region-sidebar-first .block-bean {
  margin-left: 30px;
}
nav.left .aside-banner img {
  width:190px;
}
.aside-banner.blackbg p {
  padding: 10px 5px 10px 5px;
  color: #fff;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4em;
  font-family: alto_pro_lt, sans-serif;
}

.aside-banner.blackbg p a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- MOBILE -----------------*/
/* ---------------- TABLET -----------------*/
/* ---------------- DESKTOP -----------------*/
/**
 * @file
 * Homepage Layout Styling
 */
/* ---------------- IMPORT -----------------*/
/*************************************************************************************
  Import tous les fichiers _name.scss liés au "component"
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
button.search, button.social, button.close-button {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: transparent;
  border-image-outset: 0 0 0 0;
  border-image-repeat: stretch stretch;
  border-image-slice: 100% 100% 100% 100%;
  border-image-source: none;
  border-image-width: 1 1 1 1;
  border: none;
  color: transparent;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  position: relative; }

button.search, button.close-button {
  background-image: url("../images/kering-refonte-sprite.png"); }

button.search {
  background-position: -114px -73px;
  height: 25px;
  width: 28px;
  margin-left: 20px;
  margin-right: 30px; }

button.social {
  background: url("../images/button_social.png") no-repeat;
  height: 36px;
  margin-left: 25px;
  width: 70px; }
  button.social.open {
    background: transparent none repeat scroll 0 0;
    color: #fff;
    font-family: 'gotham-book', Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-left: 27px;
    vertical-align: middle;
    width: 85px; }

button.close-button {
  background-position: -225px -3px;
  display: none;
  height: 19px;
  margin-left: 20px;
  width: 18px; }

button.close-language {
  margin-top: 9px; }

/**
 * @file
 * File Component Styling - Default style for file module.
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Fields Components Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM -----------------*/
/**
 * @file
 * Icons Component Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/**
 * @file
 * Visual styles for tables.
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Visual styles for tabs.
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Main Layout Styling
 */
/* ---------------- DEFAULT -----------------*/
#content-toolbar {
  position: absolute;
  right: 0;
  top: auto;
  padding: 30px 1em 0.5em 1em;
  z-index: 99; }
  @media screen and (max-width: 1439px) {
    #content-toolbar {
      position: absolute;
      clear: both;
      max-width: 691px;
      width: 68%;
      text-align: left;
      right: auto;
      box-sizing: border-box;
      margin: 0;
      margin-left: 335px;
      padding: 30px 0; }
      #content-toolbar #block-ppr-header-footer-header-footer-share {
        float: right;
        display: block;
        padding-right: 30px; } }
  @media screen and (max-width: 1023px) {
    #content-toolbar {
      margin-left: 265px; } }
  @media screen and (max-width: 767px) {
    #content-toolbar {
      text-align: center;
      padding: 0.5em 0 0 0;
      margin: 1em auto 0 auto;
      position: relative;
      width: 80%; } }
  #content-toolbar .block {
    float: none;
    line-height: 33px; }
    @media screen and (max-width: 1023px) {
      #content-toolbar .block {
        display: inline-block; } }
  #content-toolbar:after {
    content: "";
    clear: both;
    display: block;
    height: 0;
    width: 0; }
  #content-toolbar #block-ppr-header-footer-header-footer-zoom,
  #content-toolbar #block-ppr-header-footer-header-footer-speaker {
    display: inline; }
    #content-toolbar #block-ppr-header-footer-header-footer-zoom .content,
    #content-toolbar #block-ppr-header-footer-header-footer-speaker .content {
      display: inline; }
  #content-toolbar .rsbtn {
    display: inline; }

/**
 * @file
 * Forms Components Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/*.form-item {}
.form-textarea-wrapper {}
.description {}
.form-text {}
.form-radios{}
.form-checkboxes{}
.form-actions {}
.form-item-separator {}
.form--inline{}*/
button,
html input[type="button"],
input[type="reset"],
input[type="submit"],
.form-submit {
  /*@extend %button-form;*/ }

/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Paragraphs Component Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Carousel Component Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/*************************************************************************************
  Import tous les fichiers _name.scss liés à la navigation.
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
/**
 * @file
 * Breadcrumb Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* Breadcrumb */
.breadcrumb {
  padding: 30px 0 0 0;
  height: 80px;
  box-sizing: border-box;
  white-space: nowrap; }
  @media screen and (max-width: 1023px) {
    .breadcrumb {
      padding: 20px 0 0 0;
      height: 60px; } }
  .breadcrumb ul {
    padding: 0; }
    .breadcrumb ul li {
      display: inline;
      font-size: 1em;
      line-height: 1em;
      border-right: #676767 1px solid;
      padding: 0 5px;
      font-weight: bold; }
      .breadcrumb ul li.first {
        padding: 0 5px 0 0; }
      .breadcrumb ul li:last-child {
        color: #676767; }
      .breadcrumb ul li a {
        color: #676767;
        font-weight: normal; }
        .breadcrumb ul li a.active {
          color: #333333;
          text-decoration: none;
          font-weight: bold; }
        .breadcrumb ul li a:hover {
          text-decoration: none; }
      .breadcrumb ul li:last-child {
        border: none; }
  .breadcrumb div {
    color: #b4b4b8;
    border-right: 1px solid #a7a8ac;
    display: inline;
    font-size: 12px;
    padding: 0 7px; }
    .breadcrumb div.first {
      padding: 0 5px 0 0; }
    .breadcrumb div a {
      color: #676767;
      font-weight: normal; }

/* Page customization */
.node-type-finance .breadcrumb ul li a .breadcrumb ul li a {
  color: #676767; }
  .node-type-finance .breadcrumb ul li a .breadcrumb ul li a.active {
    color: #333333; }

.finance-blue .breadcrumb ul li {
  border-color: #fff; }
  .finance-blue .breadcrumb ul li a {
    color: #fff; }
    .finance-blue .breadcrumb ul li a.active {
      color: #fff; }
  .finance-blue .breadcrumb ul li:last-child {
    color: #FFFFFF; }

.node-type-press .breadcrumb ul li, .node-type-group .breadcrumb ul li, .node-type-brands .breadcrumb ul li, .node-type-talents .breadcrumb ul li {
  border-color: #fff; }
  .node-type-press .breadcrumb ul li a, .node-type-press .breadcrumb ul li a.active, .node-type-group .breadcrumb ul li a, .node-type-group .breadcrumb ul li a.active, .node-type-brands .breadcrumb ul li a, .node-type-brands .breadcrumb ul li a.active, .node-type-talents .breadcrumb ul li a, .node-type-talents .breadcrumb ul li a.active {
    color: #fff; }
  .node-type-press .breadcrumb ul li:last-child, .node-type-group .breadcrumb ul li:last-child, .node-type-brands .breadcrumb ul li:last-child, .node-type-talents .breadcrumb ul li:last-child {
    color: #FFFFFF; }

.node-type-group .featured-content .breadcrumb,
.node-type-brands .pane-bean-rubrique-brands .breadcrumb,
.node-type-finance .pane-bean-ppr-rubrique-finance .breadcrumb,
.node-type-sustainability .pane-bean-rubrique-sustainability .breadcrumb,
.node-type-press .pane-bean-rubrique-press .breadcrumb,
.node-type-talents .pane-bean-rubrique-talents .breadcrumb {
  display: none; }

.node-type-press-release .breadcrumb {
  display: block; }

.node-type-search .article-item .breadcrumb {
  position: relative;
  top: inherit;
  left: inherit; }
  .node-type-search .article-item .breadcrumb .last {
    margin-left: 5px; }

.region-main-block .breadcrumb span.last {
  color: #000;
  font-family: gotham-book;
  font-size: 12px;
  padding: 0 0 0 5px; }
  @media screen and (max-width: 767px) {
    .region-main-block .breadcrumb span.last {
      font-size: 16px; } }

/*
.node-type-finance .breadcrumb ul li a.active {
  color: #333333;
}
.breadcrumb ul {
  padding: 0
}
.breadcrumb ul li {
  display: inline;
  font-size: 1em;
  border-right: #676767 1px solid;
  padding-right: 5px;
}

.breadcrumb ul li a {
  color: #676767;
}

.breadcrumb ul li a.active {
  color: #333333;
}

.breadcrumb ul li:last-child {
  border: none;
}

.breadcrumb ul li a.active {
  text-decoration: none;
  font-weight: bold;
}*/
/*
.finance-blue .breadcrumb ul li a.active {
  color: #fff;
}
*/
/*
.node-type-press .breadcrumb ul li a,
.node-type-group .breadcrumb ul li a,
.node-type-brands .breadcrumb ul li a,
.node-type-talents .breadcrumb ul li a,
.node-type-press .breadcrumb ul li a.active,
.node-type-brands .breadcrumb ul li a.active,
.node-type-group .breadcrumb ul li a.active,
.node-type-talents .breadcrumb ul li a.active {
  color: #fff;
}
/*
.node-type-press .breadcrumb ul li,
.node-type-brands .breadcrumb ul li,
.node-type-group .breadcrumb ul li,
.node-type-talents .breadcrumb ul li,
.finance-blue .breadcrumb ul li {
  border-color: #fff;
}
*/
/*
.node-type-group .featured-content .breadcrumb,
.node-type-brands .pane-bean-rubrique-brands .breadcrumb,
.node-type-finance .pane-bean-ppr-rubrique-finance .breadcrumb,
.node-type-sustainability .pane-bean-rubrique-sustainability .breadcrumb,
.node-type-press .pane-bean-rubrique-press .breadcrumb,
.node-type-talents .pane-bean-rubrique-talents .breadcrumb {
  display: none;
}*/
/*
.node-type-search .article-item .breadcrumb .last {
  margin-left: 5px;
}

.breadcrumb {
  padding: 0;
  position: absolute;
  top: 39px;
  left:31px;
}*/
/*
.breadcrumb ul li {
  padding: 0 5px;
}

.breadcrumb ul li.first {
  padding: 0 5px 0 0;
}

.breadcrumb ul li:last-child {
  color: #676767;
}
*/
/*
.breadcrumb ul li a:hover {
  text-decoration:none;
}*/
/*
.breadcrumb li {
  font-weight:bold;
}
.breadcrumb li a {
  font-weight:normal;
}
.breadcrumb div {
  color: #b4b4b8;
  border-right: 1px solid #a7a8ac;
  display: inline;
  font-size: 12px;
  padding: 0 7px;
}

.breadcrumb div.first {
  padding: 0 5px 0 0;
}.region-main-block .breadcrumb span.last {
   color: #000;
   font-family: gotham-book;
   font-size: 12px;
   line-height: 12.5px;
   padding: 0 5px;
 }

.breadcrumb div a {
  color: #676767;
  font-weight: normal;
}*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Default Menu Styling
 */
/* ---------------- DEFAULT -----------------*/
/*
 * Menu
 */
/* General */
#MobileRubriqueBourse,
.MobileRubriqueKey,
.MobileRubriquePack,
#MobileRubriqueAgenda,
#MobileRubriqueRefDoc,
#MobileRubriqueContact,
#MobileRubriqueAlerte,
#MobileRubriqueNews {
  display: none; }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
  /*a.selectedMenu {
        background: url("../images/arrow-menu.png") no-repeat center bottom;
        padding-bottom: 25px;
      }*/ }
  #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li {
    margin: 0;
    display: table-cell;
    padding: 0 16px 25px;
    text-align: center; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a {
      font-family: 'gotham-medium', Helvetica, Arial, sans-serif;
      font-size: 14px;
      text-transform: uppercase; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a {
          font-size: 12px; } }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.kmag a.selectedMenu {
      background: none; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.leaf a {
      letter-spacing: 1px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.group ul {
      background-image: url("../images/menu_illustration.png"); }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.brand ul {
      background-image: url("../images/brand_illustration.png"); }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.sustainability ul {
      background-image: url("../images/sustainability_illustration.png"); }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.finance ul {
      background-image: url("../images/finance_illustration.png"); }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.talent ul {
      background-image: url("../images/menu_illustration.png"); }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li.press ul {
      background-image: url("../images/press_illustration.png"); }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li ul {
      background: no-repeat right top; }

#block-ppr-header-footer-refonte-header-footer-top-menu ul.contextual-links {
  display: none; }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: auto;
  background-color: #fff;
  border-top: 1px solid #000;
  z-index: 1000;
  display: block;
  margin: 0;
  min-height: 330px;
  padding: 48px 10% 35px 90px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (max-width: 1023px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover {
      left: 65px; } }
  #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li {
    position: relative;
    text-align: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    @media screen and (min-width: 768px) {
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li {
        display: inline-block;
        vertical-align: top;
        width: 32%;
        margin: 0 0 35px 0;
        padding: 0 15px 0 0;
        font-size: 20px; } }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.about-kering {
      width: 133px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.management {
      margin-left: 138px;
      min-height: 42px;
      width: 157px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.history {
      margin-left: 122px;
      min-height: 42px;
      width: 132px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.activity-report {
      width: 119px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.kering-partnership {
      margin-left: 151px;
      width: 251px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.luxury {
      width: 556px; }
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.luxury ul li {
        display: inline-block;
        width: 266px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.sport-lifestyle {
      margin-left: 0;
      width: 244px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.news {
      width: 135px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.video {
      margin-left: 136px;
      width: 165px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.recognition {
      margin-left: 110px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.epl-menu {
      margin-bottom: 15px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.action {
      margin-left: 39px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.stakeholders {
      margin-bottom: 72px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.governance {
      margin-bottom: 85px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.target {
      margin-bottom: 95px;
      margin-left: 37px;
      width: 255px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.foundation {
      margin-left: 36px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li ul {
      background-image: none !important;
      border-top: inherit;
      display: block;
      height: inherit;
      left: inherit;
      margin: .6em 0 0;
      padding: inherit;
      position: relative;
      top: 0;
      width: 100%; }
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li ul li {
        display: inherit;
        float: inherit;
        font-size: inherit;
        margin: 0;
        min-height: inherit;
        padding: inherit;
        text-align: left;
        width: inherit; }
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li ul li a {
          font-family: 'gotham-book', Helvetica, Arial, sans-serif;
          font-size: 11px;
          line-height: 23px; }
          #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li ul li a:hover {
            font-family: 'gotham-ultra', Helvetica, Arial, sans-serif;
            color: #000; }

@media screen and (max-width: 767px) {
  #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu {
    display: none;
    position: static; } }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a {
  font-size: 18px;
  line-height: 21px; }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a {
      color: #cacaca;
      font-size: 14px !important;
      line-height: 18px !important; } }
  #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a:hover {
    color: #cacaca; }
    @media screen and (max-width: 767px) {
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a:hover {
        font-size: 14px !important;
        line-height: 18px !important; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a {
      font-size: 13px; } }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a + ul {
      display: none;
      position: static; } }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a + ul li a {
      font-family: gotham-medium;
      color: #000000;
      font-size: 11px !important;
      line-height: 18px !important;
      text-transform: none !important;
      padding: 0 0 15px 0 !important; } }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li > ul.menu a + ul li a:hover {
      font-family: gotham-medium;
      font-size: 11px !important;
      line-height: 18px !important;
      text-transform: none !important;
      padding: 0 0 15px 0 !important; } }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul {
  display: none; }
  #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul li ul li {
    display: block; }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu #menu-talent ul.maintainHover li {
  padding: 0 20px 0 0; }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu #menu-sustainability > a {
  white-space: nowrap; }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu #menu-sustainability ul.maintainHover li {
  float: left; }

#block-ppr-header-footer-refonte-header-footer-top-menu #menu-luxury {
  float: left;
  margin: 0 0 15px;
  width: 69%; }
  #block-ppr-header-footer-refonte-header-footer-top-menu #menu-luxury ul li {
    float: left;
    width: 50%; }

#block-ppr-header-footer-refonte-header-footer-top-menu #menu-sport-lifestyle {
  margin: 0 0 4.6em 0;
  width: 31%; }

#block-ppr-header-footer-refonte-header-footer-top-menu #menu-kering-eyewear {
  width: 31%; }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
  margin: 0;
  padding: 0;
  display: table;
  width: 100%; }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
      text-align: left; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
      margin-top: 0; } }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
      display: block; } }
  #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li {
    margin: 0;
    display: table-cell;
    padding: 0 16px 25px;
    /* FR */
    text-align: center; }
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li {
        padding-left: 0;
        padding-right: 0;
        width: 17%; } }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a {
      font-size: 14px; }
      @media screen and (min-width: 1024px) and (max-width: 1175px) {
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a {
          font-size: 12px; } }
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a.selectedMenu {
        background: url("../images/arrow-menu.png") no-repeat center bottom;
        padding-bottom: 25px; }
        @media screen and (max-width: 767px) {
          #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a.selectedMenu {
            background: none;
            padding-bottom: 30px; } }

.i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul a.selectedMenu {
  background: none;
  padding-bottom: auto; }

.i18n-ja #block-ppr-header-footer-refonte-header-footer-top-menu ul li.leaf a {
  white-space: nowrap; }

.i18n-ja #block-ppr-header-footer-refonte-header-footer-search-top {
  display: none; }

.i18n-en #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.governance {
  margin-bottom: 120px; }

.i18n-en #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.governance {
  margin-bottom: 120px; }

.i18n-zh-hans header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a.selectedMenu, .i18n-ja header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a.selectedMenu {
  background: none;
  padding-bottom: 0; }

/**
 * @file
 * navbar Styling
 */
/* ---------------- DEFAULT -----------------*/
/**
 * @file
 * Social Links Styling
 */
header nav ul.main-nav {
  font: 1.6em altopro_con_regular, Arial, Helvetica;
  margin: 90px 120px 0 0;
  display: block;
  text-align: center; }
  header nav ul.main-nav > li {
    display: inline-block;
    zoom: 1;
    *display: inline;
    border-right: #b2b2b2 1px solid;
    padding: 5px 10px;
    cursor: pointer;
    text-transform: uppercase;
    height: 12px;
    line-height: 12px;
    margin-bottom: 10px; }
    header nav ul.main-nav > li:hover {
      background: #f1f1f1;
      height: 25px;
      border: none;
      padding-top: 10px;
      padding-left: 11px;
      margin: -15px 1px 0 -1px; }
    header nav ul.main-nav > li.first:hover {
      padding-left: 10px;
      border-left: 1px solid #fff; }
    header nav ul.main-nav > li.last:hover {
      padding-left: 11px;
      padding-right: 9px; }
  header nav ul.main-nav li:hover a.menulink {
    border-bottom: #000 2px solid;
    padding-bottom: 5px; }
  header nav ul.main-nav li:hover ul.second-level-menu {
    height: auto;
    background: #f1f1f1;
    width: 10000px;
    position: absolute;
    left: 50%;
    top: 109px;
    margin-left: -5000px;
    padding: 15px 15px 35px;
    display: block; }
    header nav ul.main-nav li:hover ul.second-level-menu li {
      display: block;
      width: 960px;
      margin: 0 auto; }
      header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item1 {
        float: left;
        width: 540px;
        margin-right: 40px; }
      header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item2 {
        float: left;
        width: 365px; }
      header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item {
        padding: 0; }
        header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li {
          float: left;
          width: 119px;
          font-family: Verdana, Arial, sans-serif;
          font-size: 0.7em;
          text-transform: capitalize;
          padding: 5px 0 5px 20px;
          margin: 0 10px;
          border-bottom: 1px dotted #202020; }
          header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li a {
            color: #7f7f7f; }
          header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li:hover {
            background: #7F7F7F;
            border-bottom: #7F7F7F 1px solid;
            margin-top: -1px;
            margin-bottom: 1px; }
            header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li:hover a {
              color: #fff; }
        header nav ul.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item .second-level-menu-title {
          background: url("../images/sprite-header-kering.png") no-repeat -310px -155px;
          padding: 2px 0 10px 35px;
          border-bottom: #000 1px solid; }
  header nav ul.main-nav li.main-nav-right-group a.active, header nav ul.main-nav li.main-nav-left-group a.active {
    border-bottom: #000 2px solid;
    padding-bottom: 5px; }
  header nav ul.main-nav li.main-nav-right-group.last, header nav ul.main-nav li.main-nav-left-group.last {
    border-right: 1px solid #fff; }
  header nav ul.main-nav li.main-nav-left-group.last {
    margin-right: 70px; }
  header nav ul.main-nav li.main-nav-right-group.first {
    margin-left: 170px; }
  header nav ul.main-nav li.cannes {
    width: 103px;
    height: 53px;
    position: absolute;
    border: 0 none;
    bottom: -5px;
    background: url("../images/festical-de-cannes.svg") no-repeat 10px 10px; }
    header nav ul.main-nav li.cannes a {
      width: 103px;
      height: 61px;
      display: block; }
    header nav ul.main-nav li.cannes:hover, header nav ul.main-nav li.cannes.hover {
      margin: 0 0 0 0;
      padding: 5px 10px 15px 10px;
      width: 103px;
      height: 53px;
      background: url("../images/festical-de-cannes_hover.svg") #000 no-repeat 10px 10px; }
  header nav ul.main-nav li ul.second-level-menu {
    display: none;
    text-align: left; }

header a {
  text-decoration: none;
  color: #000; }

.admin-menu .main-nav li:hover ul.second-level-menu {
  margin-top: -45px;
  top: 80px; }

.i18n-ja header nav {
  margin-top: 20px; }
  .i18n-ja header nav ul.main-nav {
    padding: 39px 0 0;
    margin: 0;
    float: left; }
    .i18n-ja header nav ul.main-nav > li {
      padding-left: 0;
      padding-right: 0; }
    .i18n-ja header nav ul.main-nav li.main-nav-left-group, .i18n-ja header nav ul.main-nav li.main-nav-right-group {
      width: 65px;
      text-align: center;
      font-size: 12px; }
    .i18n-ja header nav ul.main-nav li.main-nav-left-group.last {
      margin-right: 26px;
      width: 115px; }
    .i18n-ja header nav ul.main-nav li.main-nav-left-group.first {
      margin-left: 110px;
      width: 115px; }

.i18n-ja .main-nav > li:hover {
  padding-left: 1px;
  padding-right: 0; }

.i18n-ja .main-nav > li.first:hover {
  padding-left: 0;
  border-left: none; }

.i18n-ja .main-nav > li.last:hover {
  padding-left: 1px;
  padding-right: 0; }

.i18n-ja .main-nav li:hover ul.second-level-menu {
  top: 59px; }

.i18n-ja.admin-menu .main-nav li:hover ul.second-level-menu {
  top: 120px; }

.i18n-en header nav ul.main-nav li.cannes {
  background: url("../images/festical-de-cannes_en.svg") no-repeat 10px 10px; }
  .i18n-en header nav ul.main-nav li.cannes:hover, .i18n-en header nav ul.main-nav li.cannes.hover {
    background: url("../images/festical-de-cannes_en_hover.svg") #000 no-repeat 10px 10px; }

.lt-ie9 .main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li {
  width: 140px; }

/*
header nav ul.main-nav {
  font: 1.6em altopro_con_regular, Arial, Helvetica;
  margin: 90px 120px 0 0;
  display: block;
  text-align: center;
}
header nav ul.main-nav > li {
  display: inline-block;
  zoom: 1;
  *display: inline;
  border-right: #b2b2b2 1px solid;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: uppercase;
  height: 12px;
  line-height: 12px;
  margin-bottom: 10px;
}


header a {
  text-decoration: none;
  color: #000;
}

header nav ul.main-nav li:hover a.menulink,
header nav ul.main-nav li.main-nav-right-group > a.active,
header nav ul.main-nav li.main-nav-left-group > a.active {
  border-bottom: #000 2px solid;
  padding-bottom: 5px
}
.news-one-top-block {
  margin-right: 0;
}

header nav ul.main-nav li.main-nav-left-group.last,
header nav ul.main-nav li.main-nav-right-group.last {
  border-right: 1px solid #fff;
}

header nav ul.main-nav li.main-nav-left-group.last {
  margin-right: 70px;
}


header nav ul.main-nav li.main-nav-right-group.first {
  margin-left: 170px;
}

header nav ul.main-nav li.cannes {
  width: 103px;
  height: 53px;
  position: absolute;
  border: 0 none;
  bottom: -5px;
  background: url('../images/festical-de-cannes.svg') no-repeat 10px 10px;
}*/
/*
.i18n-en header nav ul.main-nav li.cannes {
  background: url('../images/festical-de-cannes_en.svg') no-repeat 10px 10px;
}

header nav ul.main-nav li.cannes a {
  width: 103px;
  height: 61px;
  display: block;
}*/
/* Sub menu ---------*/
/*
.main-nav {
    > li:hover {
    background: #f1f1f1;
    height: 25px;
    border: none;
    padding-top: 10px;
    padding-left: 11px;
    margin: -15px 1px 0 -1px;
  }
  li.cannes:hover, li.cannes.hover{
    margin: 0 0 0 0;
    padding: 5px 10px 15px 10px;
    width: 103px;
    height: 53px;
    background: url('../images/festical-de-cannes_hover.svg') #000 no-repeat 10px 10px;
  }
}
.main-nav li.cannes:hover,
.main-nav li.cannes.hover {
  margin: 0 0 0 0;
  padding: 5px 10px 15px 10px;
  width: 103px;
  height: 53px;
  background: url('../images/festical-de-cannes_hover.svg') #000 no-repeat 10px 10px;
}
.i18n-en .main-nav li.cannes:hover,
.i18n-en .main-nav li.cannes.hover {
  background: url('../images/festical-de-cannes_en_hover.svg') #000 no-repeat 10px 10px;
}


.main-nav > li.first:hover {
  padding-left: 10px;
  border-left: 1px solid #fff;
}

.main-nav > li.last:hover {
  padding-left: 11px;
  padding-right: 9px;
}

.main-nav li ul.second-level-menu {
  display: none;
  text-align: left;
}

.main-nav li:hover ul.second-level-menu {
  height: auto;
  background: #f1f1f1;
  width: 10000px;
  position: absolute;
  left: 50%;
  top: 109px;
  margin-left: -5000px;
  padding: 15px 15px 35px;
  display: block;
}*/
/*
.main-nav li:hover ul.second-level-menu li {
  display: block;
  width: 960px;
  margin: 0 auto;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item1 {
  float: left;
  width: 540px;
  margin-right: 40px;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item2 {
  float: left;
  width: 365px;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item {
  padding: 0
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li {
  float: left;
  width: 119px;
  font-family: Verdana, Arial, sans-serif;
  font-size: 0.7em;
  text-transform: capitalize;
  padding: 5px 0 5px 20px;
  margin: 0 10px;
  border-bottom: 1px dotted #202020;
}

.lt-ie9 .main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li {
  width: 140px;
}
.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li a {
  color: #7f7f7f;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li:hover a {
  color: #fff;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item ul.second-level-menu-item li:hover {
  background: #7F7F7F;
  border-bottom: #7F7F7F 1px solid;
  margin-top: -1px;
  margin-bottom: 1px;
}

.main-nav li:hover ul.second-level-menu li .second-level-menu-wrapper-item .second-level-menu-title {
  background: url('../images/sprite-header-kering.png') no-repeat -310px -155px;
  padding: 2px 0 10px 35px;
  border-bottom: #000 1px solid;
}
.i18n-ja header nav ul.main-nav {
  padding: 39px 0 0;
  margin: 0;
  float: left;
}
.i18n-ja header nav ul.main-nav > li {
  padding-left: 0;
  padding-right: 0;
}
.i18n-ja header nav ul.main-nav li.main-nav-left-group,
.i18n-ja header nav ul.main-nav li.main-nav-right-group {
  width: 65px;
  text-align: center;
  font-size: 12px;
}

.i18n-ja header nav ul.main-nav li.main-nav-left-group.last,
.i18n-ja header nav ul.main-nav li.main-nav-left-group.first {
  width: 115px;
}

.i18n-ja header nav ul.main-nav li.main-nav-left-group.last {
  margin-right: 26px;
}
.i18n-ja header nav ul.main-nav li.main-nav-left-group.first {
  margin-left: 110px;
}
.i18n-ja .main-nav > li:hover {
  padding-left: 1px;
  padding-right: 0;
}

.i18n-ja .main-nav > li.first:hover {
  padding-left: 0;
  border-left: none;
}

.i18n-ja .main-nav > li.last:hover {
  padding-left: 1px;
  padding-right: 0;
}
.i18n-ja .main-nav li:hover ul.second-level-menu {
  top: 59px;
}

.i18n-ja.admin-menu .main-nav li:hover ul.second-level-menu {
  top: 120px;
}*/
/* End of Sub menu ---------*/
/* End of Nav */
/**
 * @file
 * Default Mega Menu Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM -----------------*/
/**
 * @file
 * Mobile Menu Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * More Links Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/**
 * @file
 * Social Links Styling
 */
/* ---------------- Share Links  -----------------*/
/*
#block-ppr-header-footer-header-footer-header-socials {
  background-color: #000;
  right: -171px;
  width: 500px;
  z-index: 800;
}*/
#block-ppr-header-footer-header-footer-header-socials {
  background-color: #000;
  right: -171px;
  width: 500px;
  z-index: 800; }
  #block-ppr-header-footer-header-footer-header-socials ul {
    margin: 0;
    padding: 0; }
    #block-ppr-header-footer-header-footer-header-socials ul li {
      display: inline-block;
      list-style: none none; }
  #block-ppr-header-footer-header-footer-header-socials .content {
    padding-top: 77px; }
    #block-ppr-header-footer-header-footer-header-socials .content ul {
      display: none;
      margin-left: 35px;
      vertical-align: middle;
      margin-top: 5px; }

.i18n-ja #block-ppr-header-footer-header-footer-header-socials {
  right: -150px; }

/*
#block-ppr-header-footer-header-footer-header-socials ul {
  margin: 0;
  padding: 0;
}*/
/*
#block-ppr-header-footer-header-footer-header-socials ul li {
  display: inline-block;
  list-style: none none;
}

#block-ppr-header-footer-header-footer-header-socials .content {
  padding-top: 77px;
}

#block-ppr-header-footer-header-footer-header-socials .content ul {
  display: none;
  margin-left: 35px;
  vertical-align: middle;
  margin-top: 5px;
}*/
/*
button{
  &.social{
    background: url("../images/button_social.png") no-repeat;
    height: 36px;
    margin-left: 25px;
    width: 70px;
    border:1px solid red;
    &.open{
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      color: #fff;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 14px;
      margin-left: 27px;
      vertical-align: middle;
      width: 85px;
    }
  }
}

button.social.open {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #fff;
  font-family: 'gotham-book', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-left: 27px;
  vertical-align: middle;
  width: 85px;
}*/
/**
 * @file
 * Pager Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM  -----------------*/
/*************************************************************************************
  Import tous les fichiers _name.scss liés à la recherche
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
/**
 * @file
 * Search Form Styling
 */
/* ---------------- DEFAULT -----------------*/
/**
 * @file
 * Social Links Styling
 */
/* ---------------- Search  -----------------*/
/* Toolbar */
/*
.admin-menu header div.header-toolbar {
  top: 25px;
}*/
#block-ppr-header-footer-refonte-header-footer-search-top {
  position: absolute;
  height: 100%;
  width: 835px;
  z-index: 700;
  right: -460px; }
  #block-ppr-header-footer-refonte-header-footer-search-top .content {
    padding-top: 65px; }
    @media screen and (max-width: 1439px) {
      #block-ppr-header-footer-refonte-header-footer-search-top .content {
        padding-top: 45px; } }
  #block-ppr-header-footer-refonte-header-footer-search-top .contextual-links-wrapper {
    top: 39px; }
  #block-ppr-header-footer-refonte-header-footer-search-top button.search {
    background-position: -114px -73px;
    height: 25px;
    width: 28px;
    margin-left: 20px;
    margin-right: 30px; }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-search-top {
      height: 79px;
      background-image: none;
      width: 0;
      right: 0; }
      #block-ppr-header-footer-refonte-header-footer-search-top .content {
        padding-top: 30px; }
        #block-ppr-header-footer-refonte-header-footer-search-top .content button {
          position: absolute;
          right: 0;
          z-index: 999;
          background-position: -114px -73px;
          height: 25px;
          width: 28px;
          margin-left: 20px;
          margin-right: 26px;
          top: 27px; } }
  #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search label, #block-ppr-header-footer-refonte-header-footer-search-top .form-item-reset-search {
    display: none; }
  #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search, #block-ppr-header-footer-refonte-header-footer-search-top #edit-submit-search {
    color: transparent;
    display: none; }
  #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search {
    margin: 0;
    padding: 0; }
    #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search #edit-website-search {
      background-color: transparent;
      border-image: none;
      border: 0 none #fff;
      border-bottom: 1px solid;
      color: #fff;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 14px;
      padding-bottom: 25px;
      width: calc(100% - 50px); }
      @media screen and (max-width: 1279px) {
        #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search #edit-website-search {
          width: 460px; } }
      @media screen and (max-width: 1023px) {
        #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search #edit-website-search {
          width: 340px; } }
      @media screen and (max-width: 900px) {
        #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search #edit-website-search {
          width: 250px; } }
      @media screen and (max-width: 767px) {
        #block-ppr-header-footer-refonte-header-footer-search-top .form-item-website-search #edit-website-search {
          width: 80%;
          border: 0 none;
          margin-left: 20px;
          line-height: 20px;
          font-size: 20px; } }
  #block-ppr-header-footer-refonte-header-footer-search-top #ppr-header-footer-search-block-form {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: transparent;
    border-image-outset: 0 0 0 0;
    border-image-repeat: stretch stretch;
    border-image-slice: 100% 100% 100% 100%;
    border-image-source: none;
    border-image-width: 1 1 1 1;
    border: none;
    color: transparent;
    display: inline-block;
    padding: 0;
    position: relative; }
  #block-ppr-header-footer-refonte-header-footer-search-top #edit-submit-search {
    background: transparent url("../images/kering-refonte-sprite.png") -225px -86px;
    border: medium none;
    color: transparent;
    height: 19px;
    margin-left: -20px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px; }

header #block-ppr-header-footer-refonte-header-footer-lg-switch {
  position: absolute;
  height: 100%; }
  header #block-ppr-header-footer-refonte-header-footer-lg-switch .contextual-links-wrapper {
    top: 39px; }

.i18n-ja #block-ppr-header-footer-refonte-header-footer-lg-switch {
  right: -235px; }

/**
 * @file
 * Exposed Filters Styling - Views and Facet
 */
/**
 * @file
 * Search Result Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM -----------------*/
/*************************************************************************************
  Import tous les fichiers _name.scss liés au "component"
  NE PAS APPLIQUER DES STYLES ICI.
****************************************************************************************/
/**
 * @file
 * Block Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM -----------------*/
/*
  .block-[configuration.provider] { }
  .block-[plugin_id] { }
*/
#block-ppr-header-footer-refonte-header-footer-top-menu,
#block-ppr-header-footer-refonte-header-footer-search-top,
#block-ppr-header-footer-refonte-header-footer-lg-switch,
#block-ppr-header-footer-header-footer-stock {
  display: block; }

#block-ppr-header-footer-header-footer-stock {
  background-color: #000;
  bottom: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  width: 177px;
  z-index: 999; }
  #block-ppr-header-footer-header-footer-stock .stock-block {
    margin-right: 40px;
    margin-top: 0; }
    #block-ppr-header-footer-header-footer-stock .stock-block p {
      color: #ffffff;
      margin: 0; }
    #block-ppr-header-footer-header-footer-stock .stock-block .stock-block-value {
      font-family: 'gotham-black', Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: normal; }
  #block-ppr-header-footer-header-footer-stock .content {
    padding-top: 66px; }
    @media screen and (max-width: 1439px) {
      #block-ppr-header-footer-header-footer-stock .content {
        padding-top: 46px; } }

#block-ppr-header-footer-refonte-header-footer-lg-switch .content,
#block-ppr-header-footer-header-footer-header-socials .content {
  padding-top: 60px; }
  @media screen and (max-width: 1439px) {
    #block-ppr-header-footer-refonte-header-footer-lg-switch .content,
    #block-ppr-header-footer-header-footer-header-socials .content {
      padding-top: 40px; } }

@media screen and (max-width: 767px) {
  #block-ppr-header-footer-header-footer-stock,
  #block-ppr-header-footer-refonte-header-footer-lg-switch {
    display: none; } }

#block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
  margin: 0;
  padding: 0;
  display: table;
  width: 100%; }
  @media screen and (min-width: 1025px) and (max-width: 1279px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
      text-align: left;
      /*li{
         margin-left: 2%;
         padding-left: 0;
         padding-right: 0;
         a{
           font-size: 11px;
         }
       }*/ } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li {
      margin-left: 5%; }
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a.selectedMenu {
        padding-bottom: 17px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li ul li a {
      font-size: 13px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul.menu li ul.maintainHover li.governance {
      margin-bottom: 80px; } }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-refonte-header-footer-top-menu .content ul {
      width: 100%;
      background-color: #fff;
      text-align: left; }
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li {
        padding: 0;
        overflow-y: hidden; }
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li:hover {
          padding: 0; }
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li a {
          padding: 10px 0 20px 0; }
          #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li a:hover {
            background: none;
            padding: 10px 0 20px 0; }
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li ul.maintainHover {
          display: none !important;
          padding: 0 !important; }
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li.open {
          overflow-y: visible; }
          #block-ppr-header-footer-refonte-header-footer-top-menu .content ul ul.menu li.open ul {
            width: auto;
            display: block !important;
            position: relative;
            top: auto;
            left: auto;
            height: auto;
            padding: 0;
            margin: 0;
            border-top: 0 none;
            background-image: none; }
      #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li {
        float: none !important;
        margin: 0;
        width: auto;
        display: block;
        padding: 0;
        padding-left: 0 !important;
        padding-right: 0 !important; }
        #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a {
          padding: 30px 0 30px 0;
          display: block;
          font-family: gotham-medium;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;
          margin: 0;
          white-space: normal; } }

#block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li a {
  font-size: 16px; }
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li a ul {
    margin-top: 12px; }

@media screen and (min-width: 1025px) and (max-width: 1279px) {
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.governance {
    margin-bottom: 75px; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li:first-of-type {
    margin-left: 0; } }

@media screen and (max-width: 767px) {
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.open ul.maintainHover {
    width: auto;
    display: block;
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    padding: 0;
    margin: 0;
    border-top: 0 none;
    background-image: none; }
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li.open .content {
    display: block;
    position: absolute;
    top: 79px;
    left: 0;
    z-index: 999;
    background: #ffffff url("../images/background.png");
    width: 100%; }
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover {
    display: none; }
    #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li {
      width: auto;
      display: block;
      padding: 0;
      float: none !important;
      margin: 0; }
      #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li a {
        padding: 10px 20px 20px 20px;
        display: block;
        font-family: gotham-medium;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0; }
        #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li a.selectedMenu {
          background: none;
          padding-bottom: 30px; }
      #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li ul {
        margin-top: 0; }
      #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.menu-reporting, #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.menu-governance {
        position: relative;
        top: initial;
        left: initial;
        width: auto;
        margin-left: initial; }
      #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.menu-reporting {
        right: 0; }
      #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.menu-videos {
        bottom: 17%;
        left: 0; }
      #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.menu-foundation {
        bottom: 15%;
        left: 0; }
    #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover #regulated-information {
      position: relative;
      width: auto;
      margin-left: 0;
      bottom: 0; }
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li:hover ul {
    display: none; } }

@media screen and (max-width: 767px) {
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu #menu-sustainability ul.maintainHover li {
    float: none;
    margin: 0; }
  #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu ul a {
    color: #7f7e7e; } }

@media screen and (max-width: 767px) {
  #block-ppr-header-footer-refonte-header-footer-top-menu {
    background: url("../images/mobile-menu-little-refonte.png") no-repeat 10px 20px;
    width: 65px;
    height: 80px;
    margin-left: 10px;
    /*width: 148px;
    height: 124px;
    margin-left: 20px;*/
    cursor: pointer;
    float: left;
    left: 0; }
    #block-ppr-header-footer-refonte-header-footer-top-menu .content {
      display: none; }
    #block-ppr-header-footer-refonte-header-footer-top-menu.open {
      background: url("../images/mobile-menu-close-little-refonte.png") no-repeat 10px 20px; }
      #block-ppr-header-footer-refonte-header-footer-top-menu.open .content {
        display: block;
        position: absolute;
        top: 79px;
        left: -20px;
        z-index: 999;
        background: #ffffff url(../images/background.png);
        width: calc(100% + 20px); }
        #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > ul.menu {
          margin-left: 19px;
          width: calc(100% - 19px); }
          #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > ul.menu li.open ul {
            display: block; }
        #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social {
          margin-left: 19px;
          width: calc(100% - 19px); }
          #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul {
            background: #0d0d0d -690px 0;
            text-align: center;
            padding: 0  0 0 40px;
            box-sizing: border-box; }
            #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li {
              padding: 20px 40px 20px 0 !important;
              display: inline-block;
              box-sizing: border-box; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li a {
                background: url("../images/kering-refonte-sprite.png") 295px 110px;
                color: transparent;
                display: block;
                height: 30px;
                text-indent: -9999px;
                width: 26px;
                padding: 0; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li.twitter a {
                background-position: 190px 110px; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li.sinaweibo a {
                background-position: 331px 242px;
                height: 21px;
                padding-top: 0;
                width: 41px; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li.youku a {
                background-position: 388px 350px;
                height: 26px;
                width: 22px; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li.instagram a {
                background-position: 242px 110px; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li.linkedin a {
                background-position: 137px 110px; }
              #block-ppr-header-footer-refonte-header-footer-top-menu.open .content > div.social ul li.youtube a {
                background-position: 84px 110px; }
    #block-ppr-header-footer-refonte-header-footer-top-menu #menu-luxury {
      width: auto;
      float: none; }
      #block-ppr-header-footer-refonte-header-footer-top-menu #menu-luxury ul li {
        float: none;
        width: 100%; }
    #block-ppr-header-footer-refonte-header-footer-top-menu #menu-sport-lifestyle, #block-ppr-header-footer-refonte-header-footer-top-menu #menu-kering-eyewear {
      width: auto;
      padding: 0;
      margin: 0; } }

#block-ppr-header-footer-header-footer-header-socials {
  position: absolute;
  right: -171px;
  width: 500px;
  z-index: 800;
  height: 100%; }
  @media screen and (max-width: 767px) {
    #block-ppr-header-footer-header-footer-header-socials {
      display: none; } }
  #block-ppr-header-footer-header-footer-header-socials ul li {
    margin: 0 0 0 24px;
    padding: 0; }
    #block-ppr-header-footer-header-footer-header-socials ul li a {
      background-image: url("../images/kering-refonte-sprite.png");
      color: transparent;
      display: block;
      height: 30px;
      text-indent: -9999px;
      width: 26px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.facebook a {
      background-position: 295px 107px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.twitter a {
      background-position: 190px 107px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.sinaweibo a {
      background-position: 408px 348px;
      height: 24px;
      padding-top: 0;
      width: 41px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.youku a {
      background-position: 332px 242px;
      height: 24px;
      width: 41px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.instagram a {
      background-position: 242px 107px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.linkedin a {
      background-position: 137px 107px; }
    #block-ppr-header-footer-header-footer-header-socials ul li.youtube a {
      background-position: 84px 107px; }

#block-ppr-header-footer-refonte-header-footer-lg-switch {
  width: 550px;
  right: -310px;
  z-index: 800; }

#block-bean-brand-block {
  padding: 120px 20px 60px 0;
  box-sizing: border-box;
  max-width: 70%;
  margin: 0; }
  @media screen and (max-width: 1023px) {
    #block-bean-brand-block {
      padding: 60px 20px 50px 0; } }

@media screen and (max-width: 767px) {
  .i18n-zh-hans #block-ppr-header-footer-refonte-header-footer-top-menu.open .content {
    top: 120px; } }

/**
 * @file
 * Nodes Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- DEFAULT -----------------*/
/* ---------------- CUSTOM -----------------*/
/* .node--type-[node.bundle] { }
 .node--view-mode-[view_mode] { }
 */
@media screen and (max-width: 767px) {
  .node-type-brand .main-wrapper > .section.firstsection {
    padding-left: 0; } }

@media screen and (max-width: 767px) {
  .node-type-brand .main-wrapper > .section.firstsection .content-wrapper article {
    width: 100% !important;
    max-width: 100%; } }

.node-type-brand .main-wrapper > .section.firstsection .content-wrapper article .block-content-article {
  max-width: 100% !important; }
  @media screen and (max-width: 767px) {
    .node-type-brand .main-wrapper > .section.firstsection .content-wrapper article .block-content-article .article-item-wrapper {
      background: #ffffff url("../images/background_1025.png") repeat-y;
      background-position: -45px 0 !important;
      padding-left: 20px; } }

.node-type-search .block-content-article {
  background: #fff;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  margin: 15px auto;
  width: 900px;
  max-width: 100%; }
  .node-type-search .block-content-article .form-wrapper {
    background: #f1f1f1;
    padding: 30px 60px 15px;
    font-size: 1.1em; }
    @media screen and (max-width: 767px) {
      .node-type-search .block-content-article .form-wrapper {
        padding: 15px 30px 10px; } }

.node-type-search .article-item .breadcrumb {
  position: relative;
  top: inherit;
  left: inherit;
  padding: 0;
  height: auto;
  white-space: normal; }

.node-type-search .article-item .block-content-description {
  font-size: 15px;
  line-height: 26px;
  font-family: Radley, Verdana, Arial, sans-serif;
  color: #666; }

.node-type-search .article-item-wrapper {
  padding-left: 30px;
  padding-top: 30px;
  max-width: 90%; }
  .node-type-search .article-item-wrapper .article-item {
    border-bottom: #fff 21px solid;
    color: #7f7f7F;
    padding-left: 52px; }
    .node-type-search .article-item-wrapper .article-item.clearfix.views-row {
      border-bottom: 1px solid #7f7f7f;
      color: #7f7f7f;
      padding: 15px 20px; }

.page-search-content article p.readmore.return-link {
  width: 862px;
  text-align: center;
  padding-left: 0;
  margin: 30px auto; }

.node-type-custom-page .article-item-wrapper img {
  max-width: 100%; }

.node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li {
  background: #f8f7f7;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  width: calc(33% - 15px);
  height: 250px;
  max-width: 200px;
  overflow: hidden; }
  .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li:hover {
    background-color: #333333; }
    .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li:hover .views-field-title-field {
      background-color: #333333; }
  .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li .views-field-field-content {
    padding: 0 15px 15px 15px;
    width: calc(100% - 30px);
    word-break: break-all; }
  @media screen and (max-width: 767px) {
    .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li {
      width: calc(50% - 15px); } }
  @media screen and (max-width: 450px) {
    .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li {
      width: calc(100% - 15px); } }
  .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li .views-field-field-image a {
    display: block;
    height: 100%; }
  .node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li .views-field-field-image img {
    width: 100%;
    max-width: 100%;
    height: auto; }

.page-sustainability-news .block-content-article .view-content,
.page-press-releases .block-content-article .view-content,
.page-dev-durable-actualite .block-content-article .view-content,
.page-communiques-de-presse .block-content-article .view-content {
  margin-top: 30px;
  margin-bottom: 60px; }
  .page-sustainability-news .block-content-article .view-content .views-row,
  .page-press-releases .block-content-article .view-content .views-row,
  .page-dev-durable-actualite .block-content-article .view-content .views-row,
  .page-communiques-de-presse .block-content-article .view-content .views-row {
    background-color: #f7f8f8;
    display: inline-block;
    box-sizing: content-box;
    margin-bottom: 15px;
    margin-right: 15px;
    overflow: hidden;
    padding: 0 30px 220px 30px;
    position: relative;
    vertical-align: top;
    width: 265px;
    max-width: calc(50% - 80px); }
    @media screen and (max-width: 850px) {
      .page-sustainability-news .block-content-article .view-content .views-row,
      .page-press-releases .block-content-article .view-content .views-row,
      .page-dev-durable-actualite .block-content-article .view-content .views-row,
      .page-communiques-de-presse .block-content-article .view-content .views-row {
        display: block;
        width: 265px;
        max-width: calc(92% - 15px);
        margin: 0 auto 15px auto; } }
    .page-sustainability-news .block-content-article .view-content .views-row .category,
    .page-sustainability-news .block-content-article .view-content .views-row .brand,
    .page-press-releases .block-content-article .view-content .views-row .category,
    .page-press-releases .block-content-article .view-content .views-row .brand,
    .page-dev-durable-actualite .block-content-article .view-content .views-row .category,
    .page-dev-durable-actualite .block-content-article .view-content .views-row .brand,
    .page-communiques-de-presse .block-content-article .view-content .views-row .category,
    .page-communiques-de-presse .block-content-article .view-content .views-row .brand {
      color: #000;
      display: inline;
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      margin-right: 5px; }
    .page-sustainability-news .block-content-article .view-content .views-row ul.article-info,
    .page-press-releases .block-content-article .view-content .views-row ul.article-info,
    .page-dev-durable-actualite .block-content-article .view-content .views-row ul.article-info,
    .page-communiques-de-presse .block-content-article .view-content .views-row ul.article-info {
      display: inline; }
    .page-sustainability-news .block-content-article .view-content .views-row .brand,
    .page-press-releases .block-content-article .view-content .views-row .brand,
    .page-dev-durable-actualite .block-content-article .view-content .views-row .brand,
    .page-communiques-de-presse .block-content-article .view-content .views-row .brand {
      font-family: 'gotham-bold', Helvetica, Arial, sans-serif; }

.page-sustainability-news .block-content-article .views-field-field-image img,
.page-press-releases .block-content-article .views-field-field-image img,
.page-dev-durable-actualite .block-content-article .views-field-field-image img,
.page-communiques-de-presse .block-content-article .views-field-field-image img {
  bottom: 36px;
  height: auto;
  max-width: 100%;
  overflow: visible;
  position: absolute;
  width: calc(100% - 30px); }

.page-sustainability-news .block-content-article #views_infinite_scroll-ajax-loader,
.page-press-releases .block-content-article #views_infinite_scroll-ajax-loader,
.page-dev-durable-actualite .block-content-article #views_infinite_scroll-ajax-loader,
.page-communiques-de-presse .block-content-article #views_infinite_scroll-ajax-loader {
  margin-top: -60px;
  position: absolute;
  text-align: center;
  width: 100%; }

.page-sustainability-news .block-content-article .search-result-footer,
.page-press-releases .block-content-article .search-result-footer,
.page-dev-durable-actualite .block-content-article .search-result-footer,
.page-communiques-de-presse .block-content-article .search-result-footer {
  position: relative;
  margin-top: -30px; }
  .page-sustainability-news .block-content-article .search-result-footer p.readmore,
  .page-press-releases .block-content-article .search-result-footer p.readmore,
  .page-dev-durable-actualite .block-content-article .search-result-footer p.readmore,
  .page-communiques-de-presse .block-content-article .search-result-footer p.readmore {
    text-align: center; }
  .page-sustainability-news .block-content-article .search-result-footer #readmore_link,
  .page-press-releases .block-content-article .search-result-footer #readmore_link,
  .page-dev-durable-actualite .block-content-article .search-result-footer #readmore_link,
  .page-communiques-de-presse .block-content-article .search-result-footer #readmore_link {
    display: inline-block;
    border: 1px #000000 solid;
    padding: 0.5em; }
    .page-sustainability-news .block-content-article .search-result-footer #readmore_link:hover,
    .page-press-releases .block-content-article .search-result-footer #readmore_link:hover,
    .page-dev-durable-actualite .block-content-article .search-result-footer #readmore_link:hover,
    .page-communiques-de-presse .block-content-article .search-result-footer #readmore_link:hover {
      color: #6b6b6b;
      border: 1px #6b6b6b solid; }

.page-sustainability-news article .view-display-id-press_release_mise_avant,
.page-press-releases article .view-display-id-press_release_mise_avant,
.page-dev-durable-actualite article .view-display-id-press_release_mise_avant,
.page-communiques-de-presse article .view-display-id-press_release_mise_avant {
  background-color: black;
  width: calc(100% - 15px);
  padding-right: 15px;
  margin-right: 15px;
  margin-top: 30px; }
  @media screen and (max-width: 1023px) {
    .page-sustainability-news article .view-display-id-press_release_mise_avant,
    .page-press-releases article .view-display-id-press_release_mise_avant,
    .page-dev-durable-actualite article .view-display-id-press_release_mise_avant,
    .page-communiques-de-presse article .view-display-id-press_release_mise_avant {
      padding-right: 0;
      max-width: 100%;
      margin: 30px 0 0 0; } }
  .page-sustainability-news article .view-display-id-press_release_mise_avant img,
  .page-press-releases article .view-display-id-press_release_mise_avant img,
  .page-dev-durable-actualite article .view-display-id-press_release_mise_avant img,
  .page-communiques-de-presse article .view-display-id-press_release_mise_avant img {
    padding-right: 0;
    float: left;
    width: 400px;
    max-width: 60%;
    height: auto; }
    @media screen and (max-width: 1023px) {
      .page-sustainability-news article .view-display-id-press_release_mise_avant img,
      .page-press-releases article .view-display-id-press_release_mise_avant img,
      .page-dev-durable-actualite article .view-display-id-press_release_mise_avant img,
      .page-communiques-de-presse article .view-display-id-press_release_mise_avant img {
        display: block;
        float: none;
        margin: auto;
        max-width: 100%;
        padding: 0;
        width: 100%; } }

.page-sustainability-news article .featured-block,
.page-press-releases article .featured-block,
.page-dev-durable-actualite article .featured-block,
.page-communiques-de-presse article .featured-block {
  padding-right: 0; }
  .page-sustainability-news article .featured-block .featured-block-content,
  .page-press-releases article .featured-block .featured-block-content,
  .page-dev-durable-actualite article .featured-block .featured-block-content,
  .page-communiques-de-presse article .featured-block .featured-block-content {
    float: left;
    width: 275px;
    max-width: calc(40% - 20px);
    margin-left: 20px;
    margin-bottom: 30px;
    position: relative;
    min-height: 305px; }
    @media screen and (max-width: 1023px) {
      .page-sustainability-news article .featured-block .featured-block-content,
      .page-press-releases article .featured-block .featured-block-content,
      .page-dev-durable-actualite article .featured-block .featured-block-content,
      .page-communiques-de-presse article .featured-block .featured-block-content {
        float: none;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        max-width: 90%;
        min-height: auto; } }

/**
 * @file
 * Panel Styling
 */
/* ---------------- IMPORT -----------------*/
.node-type-talents .panels-flexible-column, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
.i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column, .node-type-talents > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
.i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .node-type-brands .section-content .pane-block, .node-type-sustainability .section-content .panel-pane.image_top,
.node-type-sustainability .section-content .panel-pane.objectifs_block,
.node-type-sustainability .section-content .panel-pane.kering_fondation_block, .node-type-press .section-content .panel-pane, .i18n-ja.node-landing-page .section-content .pane-bean-panels.tier-large,
.i18n-zh-hans.node-landing-page .section-content .pane-bean-panels.tier-large {
  float: left;
  width: 366px;
  max-width: calc(33% - 15px);
  margin: 0 15px 15px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (max-width: 1023px) {
    .node-type-talents .panels-flexible-column, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column, .node-type-talents > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .node-type-brands .section-content .pane-block, .node-type-sustainability .section-content .panel-pane.image_top,
    .node-type-sustainability .section-content .panel-pane.objectifs_block,
    .node-type-sustainability .section-content .panel-pane.kering_fondation_block, .node-type-press .section-content .panel-pane, .i18n-ja.node-landing-page .section-content .pane-bean-panels.tier-large,
    .i18n-zh-hans.node-landing-page .section-content .pane-bean-panels.tier-large {
      clear: none !important;
      float: left !important;
      margin: 0 15px 15px 0 !important;
      max-width: calc(46% - 15px) !important; } }
  @media screen and (max-width: 767px) {
    .node-type-talents .panels-flexible-column, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column, .node-type-talents > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news, .node-type-brands .section-content .pane-block, .node-type-sustainability .section-content .panel-pane.image_top,
    .node-type-sustainability .section-content .panel-pane.objectifs_block,
    .node-type-sustainability .section-content .panel-pane.kering_fondation_block, .node-type-press .section-content .panel-pane, .i18n-ja.node-landing-page .section-content .pane-bean-panels.tier-large,
    .i18n-zh-hans.node-landing-page .section-content .pane-bean-panels.tier-large {
      float: none !important;
      margin: 15px auto 15px auto !important;
      height: auto !important;
      max-width: 100% !important;
      clear: both; }
      .node-type-talents .panels-flexible-column .tier, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column .tier,
      .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column .tier,
      .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column .tier,
      .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column .tier, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column .tier, .node-type-talents > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier,
      .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier,
      .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier,
      .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier, .node-type-brands .section-content .pane-block .tier, .node-type-sustainability .section-content .panel-pane.image_top .tier,
      .node-type-sustainability .section-content .panel-pane.objectifs_block .tier,
      .node-type-sustainability .section-content .panel-pane.kering_fondation_block .tier, .node-type-press .section-content .panel-pane .tier, .i18n-ja.node-landing-page .section-content .pane-bean-panels.tier-large .tier,
      .i18n-zh-hans.node-landing-page .section-content .pane-bean-panels.tier-large .tier {
        height: auto !important; } }
  .node-type-talents .panels-flexible-column:after, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column:after,
  .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column:after,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column:after,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column:after, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column:after, .node-type-talents > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news:after, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news:after,
  .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news:after,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news:after,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news:after, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news:after, .node-type-brands .section-content .pane-block:after, .node-type-sustainability .section-content .panel-pane.image_top:after,
  .node-type-sustainability .section-content .panel-pane.objectifs_block:after,
  .node-type-sustainability .section-content .panel-pane.kering_fondation_block:after, .node-type-press .section-content .panel-pane:after, .i18n-ja.node-landing-page .section-content .pane-bean-panels.tier-large:after,
  .i18n-zh-hans.node-landing-page .section-content .pane-bean-panels.tier-large:after {
    content: "";
    display: table;
    clear: both; }

.node-landing-page .pane-bean-video-ppr-bands-emotion, .node-type-brands .section-content .pane-bean-panels.last, .i18n-ja.node-type-group .section-content .pane-bean-panels.last,
.i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last, .node-type-sustainability .section-content .panel-pane, .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video, .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-releases,
.node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-one-top, .i18n-ja .pane-ppr-rubrique-ppr-rubrique-news,
.i18n-zh-hans .pane-ppr-rubrique-ppr-rubrique-news {
  float: left;
  width: 745px;
  max-width: calc(66% - 15px);
  margin: 0 15px 15px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (max-width: 1023px) {
    .node-landing-page .pane-bean-video-ppr-bands-emotion, .node-type-brands .section-content .pane-bean-panels.last, .i18n-ja.node-type-group .section-content .pane-bean-panels.last,
    .i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last, .node-type-sustainability .section-content .panel-pane, .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video, .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-releases,
    .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-one-top, .i18n-ja .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-zh-hans .pane-ppr-rubrique-ppr-rubrique-news {
      float: none !important;
      clear: both !important;
      margin: 15px 0 15px 0 !important;
      max-width: calc(92% - 15px) !important; } }
  @media screen and (max-width: 767px) {
    .node-landing-page .pane-bean-video-ppr-bands-emotion, .node-type-brands .section-content .pane-bean-panels.last, .i18n-ja.node-type-group .section-content .pane-bean-panels.last,
    .i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last, .node-type-sustainability .section-content .panel-pane, .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video, .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-releases,
    .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-one-top, .i18n-ja .pane-ppr-rubrique-ppr-rubrique-news,
    .i18n-zh-hans .pane-ppr-rubrique-ppr-rubrique-news {
      float: none !important;
      margin: 15px auto 15px auto !important;
      height: auto !important;
      max-width: 100% !important;
      clear: both; }
      .node-landing-page .pane-bean-video-ppr-bands-emotion .tier, .node-type-brands .section-content .pane-bean-panels.last .tier, .i18n-ja.node-type-group .section-content .pane-bean-panels.last .tier,
      .i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last .tier, .node-type-sustainability .section-content .panel-pane .tier, .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video .tier, .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-releases .tier,
      .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-one-top .tier, .i18n-ja .pane-ppr-rubrique-ppr-rubrique-news .tier,
      .i18n-zh-hans .pane-ppr-rubrique-ppr-rubrique-news .tier {
        height: auto !important; } }
  .node-landing-page .pane-bean-video-ppr-bands-emotion:after, .node-type-brands .section-content .pane-bean-panels.last:after, .i18n-ja.node-type-group .section-content .pane-bean-panels.last:after,
  .i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last:after, .node-type-sustainability .section-content .panel-pane:after, .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video:after, .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-releases:after,
  .node-type-press .section-content .pane-ppr-rubrique-ppr-rubrique-press-one-top:after, .i18n-ja .pane-ppr-rubrique-ppr-rubrique-news:after,
  .i18n-zh-hans .pane-ppr-rubrique-ppr-rubrique-news:after {
    content: "";
    display: table;
    clear: both; }

.node-landing-page .pane-bean-video-ppr-bands-emotion, .node-type-brands .section-content .pane-bean-panels.last, .i18n-ja.node-type-group .section-content .pane-bean-panels.last,
.i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last {
  padding: 0; }

.node-type-talents .panels-flexible-column, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
.i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column {
  margin-bottom: 0 !important; }
  .node-type-talents .panels-flexible-column > .panels-flexible-column-inside, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside,
  .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column > .panels-flexible-column-inside {
    padding: 0;
    margin: 0; }
    .node-type-talents .panels-flexible-column > .panels-flexible-column-inside .panels-flexible-region, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside .panels-flexible-region,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside .panels-flexible-region,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside .panels-flexible-region,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside .panels-flexible-region, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column > .panels-flexible-column-inside .panels-flexible-region {
      float: none;
      width: 100%; }
    .node-type-talents .panels-flexible-column > .panels-flexible-column-inside .panel-separator, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside .panel-separator,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside .panel-separator,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside .panel-separator,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside .panel-separator, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column > .panels-flexible-column-inside .panel-separator {
      display: none; }
    .node-type-talents .panels-flexible-column > .panels-flexible-column-inside .panel-pane, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside .panel-pane,
    .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside .panel-pane,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column > .panels-flexible-column-inside .panel-pane,
    .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column > .panels-flexible-column-inside .panel-pane, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column > .panels-flexible-column-inside .panel-pane {
      margin-bottom: 15px; }
  .node-type-talents .panels-flexible-column .tier, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column .tier,
  .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column .tier,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column .tier,
  .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column .tier, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column .tier {
    margin-right: 0;
    margin-top: 0;
    clear: both;
    float: none;
    width: 100%;
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .node-type-talents .panels-flexible-column .tier, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column .tier,
      .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column .tier,
      .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 .panels-flexible-column .tier,
      .i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 .panels-flexible-column .tier, .node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-column .tier {
        height: auto !important; } }

.node-type-talents > .panels-flexible-row-inside > .panels-flexible-region:after, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region:after,
.i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region:after,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region:after,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region:after, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region:after {
  content: "";
  display: table;
  clear: both; }

.node-type-talents > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier, .i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier,
.i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-10 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier, .node-type-finance .panels-flexible-row-front-finance-3 > .panels-flexible-row-inside > .panels-flexible-region .pane-ppr-rubrique-ppr-rubrique-news .tier {
  margin-right: 0;
  margin-top: 0;
  width: 100%;
  max-width: 100%; }

#job-desktop,
#encourage-desktop,
#news-desktop,
#social-desktop,
.twitter-desktop {
  display: block; }

#job-mobile,
#social-mobile,
#news-mobile,
#encourage-mobile,
.twitter-mobile {
  display: none; }


#news-tablette,
#encourage-tablette,
#news-tablette,
#social-tablette,
.twitter-tablette {
  display: none; }

.panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-3,
.panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10,
.panel-flexible.front-finance .panels-flexible-row-front-finance-3 {
  max-width: 100%;
  margin-left: 90px !important;
  margin-right: 0;
  padding: 0;
  box-sizing: border-box;
  float: none;
  clear: both; }
  @media screen and (max-width: 1439px) {
    .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-3,
    .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10,
    .panel-flexible.front-finance .panels-flexible-row-front-finance-3 {
      margin-left: 50px !important; } }
  @media screen and (max-width: 767px) {
    .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-3,
    .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10,
    .panel-flexible.front-finance .panels-flexible-row-front-finance-3 {
      margin-left: 25px !important;
      margin-right: 25px; } }

.pane-content .quarter {
  width: 100%;
  margin: 0 15px 15px 0;
  padding: 0 15px 30px 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 189px; }
  .pane-content .quarter p {
    font-size: 16px; }

.node-landing-page .tier {
  position: relative;
  padding: 0 15px 30px 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: black;
  font-family: 'Radley', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  background-color: #f8f7f7; }
  .node-landing-page .tier:hover {
    background-color: #ebe9ea !important; }
  .node-landing-page .tier p,
  .node-landing-page .tier p.block-content {
    margin: 20px 0 0 0;
    padding-bottom: 30px;
    color: black;
    font-size: 16px;
    line-height: 1.5em; }
  .node-landing-page .tier.white-txt {
    background-color: #0d0d0d !important; }
    .node-landing-page .tier.white-txt:hover {
      background-color: #222222 !important; }
    .node-landing-page .tier.white-txt p,
    .node-landing-page .tier.white-txt h2,
    .node-landing-page .tier.white-txt h2.title-align-center,
    .node-landing-page .tier.white-txt a {
      color: #ffffff !important; }
  .node-landing-page .tier img {
    max-width: 100%;
    height: auto; }
  .node-landing-page .tier > a {
    display: block;
    height: 100%; }
  .node-landing-page .tier a {
    cursor: pointer; }
    .node-landing-page .tier a:hover {
      color: #a7a8ac; }

.node-landing-page .tier2-image-block {
  padding: 0; }
  .node-landing-page .tier2-image-block p {
    margin: 0;
    padding: 0; }

.node-landing-page .tier > div,
.node-landing-page .tier > a > div,
.node-landing-page .quarter > div,
.node-landing-page .quarter > a > div,
.node-landing-page .quarter > h2,
.node-landing-page .quarter > a > h2,
.node-landing-page .quarter > .block-content,
.node-landing-page .quarter > a > .block-content,
.node-landing-page .quarter > p,
.node-landing-page .quarter > a > p,
.node-landing-page .tier > h2,
.node-landing-page .tier > a > h2,
.node-landing-page .tier > .block-content,
.node-landing-page .tier > a > .block-content,
.node-landing-page .tier > a > p,
.node-landing-page .tier > p,
.node-landing-page .tier > ul.latest-news,
.node-landing-page .tier .left-top-new {
  width: 100%;
  margin: 20px auto 0;
  padding: 0 25px 0 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.node-landing-page .quarter.bourse {
  padding-left: 50px; }
  .node-landing-page .quarter.bourse div.stock-block,
  .node-landing-page .quarter.bourse h2 {
    padding-left: 0; }

.node-landing-page .panel-pane .quarter h2,
.node-landing-page .tier > h2,
.node-landing-page .tier > a > h2,
.node-landing-page .tier h2.title-align-center {
  font-size: 14px;
  font-family: 'gotham-black', Helvetica, Arial, sans-serif;
  font-weight: normal;
  background: none;
  text-align: left;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 15px;
  margin: 0;
  color: black;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) and (max-width: 1279px) {
    .node-landing-page .panel-pane .quarter h2,
    .node-landing-page .tier > h2,
    .node-landing-page .tier > a > h2,
    .node-landing-page .tier h2.title-align-center {
      font-size: 18px;
      padding-top: 40px; } }
  @media screen and (max-width: 767px) {
    .node-landing-page .panel-pane .quarter h2,
    .node-landing-page .tier > h2,
    .node-landing-page .tier > a > h2,
    .node-landing-page .tier h2.title-align-center {
      padding-top: 50px;
      font-size: 20px;
      line-height: 1em; } }
  @media screen and (max-width: 450px) {
    .node-landing-page .panel-pane .quarter h2,
    .node-landing-page .tier > h2,
    .node-landing-page .tier > a > h2,
    .node-landing-page .tier h2.title-align-center {
      padding-top: 30px;
      font-size: 16px;
      line-height: 1em; } }

.node-landing-page .tier.tier-half-height p {
  max-width: 100%;
  font-family: 'Radley', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 15px;
  text-align: left;
  color: black; }

.node-landing-page .tier.tier-half-height a {
  color: transparent; }

.node-landing-page .tier.rubrique_video_size {
  height: auto !important;
  padding: 0; }
  .node-landing-page .tier.rubrique_video_size #title_video_description {
    font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: white;
    padding: 15px;
    text-transform: none;
    margin: 0;
    background: none;
    text-align: left;
    font-weight: normal; }
  .node-landing-page .tier.rubrique_video_size .description {
    float: none;
    padding: 0;
    width: initial;
    margin-top: 0 !important; }
  .node-landing-page .tier.rubrique_video_size div.video_with_description {
    margin: 0 auto;
    width: 100%;
    padding: 0 0 56.25% 0;
    /* 16:9 */
    position: relative;
    background: black;
    text-align: center; }
    .node-landing-page .tier.rubrique_video_size div.video_with_description iframe,
    .node-landing-page .tier.rubrique_video_size div.video_with_description .ppr-player {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: white;
      font-size: 24px;
      text-align: center; }

/* BRAND PAGE */
.node-type-brands .section-content .pane-block.pane-ppr-rubrique-ppr-rubrique-luxury-brands .tier, .node-type-brands .section-content .pane-block.pane-ppr-rubrique-ppr-rubrique-sports-brands .tier {
  height: 420px !important; }

.node-type-brands .tier.brands-block,
.node-type-brands .tier.news-block {
  height: 420px; }

.node-type-brands .tier.rubrique_video_size {
  margin-top: 0; }
  .node-type-brands .tier.rubrique_video_size .description {
    display: none; }

.tier a {
  color: #000;
  text-decoration: none; }

.tier ul.latest-news > li {
  list-style: none;
  font-size: 11px;
  line-height: 1em; }
  .tier ul.latest-news > li ul {
    padding: 0;
    margin: 15px 0 0 0; }
    .tier ul.latest-news > li ul li {
      list-style: none;
      margin: 0; }
  .tier ul.latest-news > li p {
    margin: 5px 0 0 0;
    padding: 0;
    line-height: 1.2em; }

.tier .latest-news-infos li {
  margin-bottom: 15px;
  list-style: none;
  display: inline-block;
  font-family: 'gotham-bold', Helvetica, Arial, sans-serif; }

.tier .latest-news-infos li.brand-concern {
  text-transform: uppercase; }

.tier .date,
.tier .brand-concern {
  font-size: 11px !important;
  font-family: 'gotham-bold', Helvetica, Arial, sans-serif; }

.tier .chapo {
  line-height: 1.2em !important; }

/* End of Page customization */
.tier-block1 ul.latest-news > li {
  list-style-type: none;
  font-size: 1.1em;
  padding: 0 0 15px 0;
  margin-top: 14px; }

/* SUSTAINABILITY  PAGE */
.tier.news-one-top-block > h2 {
  padding-left: 35px; }

.tier.news-one-top-block .left-top-new,
.tier.news-one-top-block .right-list-news {
  display: inline-block;
  width: calc(50% - 35px);
  vertical-align: top;
  padding-right: 0; }
  .tier.news-one-top-block .left-top-new ul,
  .tier.news-one-top-block .right-list-news ul {
    padding: 0;
    margin: 0; }

.tier.news-one-top-block .left-top-new img {
  max-width: 100%;
  width: 270px;
  height: auto;
  padding-right: 0; }

.tier.news-one-top-block .right-list-news .latest-news > li {
  margin-top: 0; }
  .tier.news-one-top-block .right-list-news .latest-news > li + li {
    margin-top: 35px; }

.tier.news-one-top-block p.date {
  margin: 15px 0 0 0;
  padding: 0; }

.tier.news-one-top-block p.chapo {
  margin: 7px 0 0 0; }

.node-type-sustainability .section-content .tier {
  height: 460px; }
  .node-type-sustainability .section-content .tier li.slider-wrapper {
    list-style: none; }
    .node-type-sustainability .section-content .tier li.slider-wrapper img {
      display: block;
      margin: auto; }

@media screen and (max-width: 1023px) {
  .node-type-sustainability .kering_fondation_block.last {
    text-align: center;
    float: none !important;
    margin: 15px 0 15px 0 !important;
    max-width: calc(92% - 15px) !important; } }

@media screen and (max-width: 767px) {
  .node-type-sustainability .kering_fondation_block.last {
    float: none !important;
    margin: 15px auto 15px auto !important;
    height: auto !important;
    max-width: 100% !important;
    clear: both; }
    .node-type-sustainability .kering_fondation_block.last .tier {
      height: auto !important; } }

.node-type-sustainability .kering_fondation_block.last .tier:hover {
  background: #ffffff !important; }

.node-type-sustainability .pane-bean-panels.objectifs_block p.readmore {
  display: none; }

.node-type-sustainability .pane-bean-panels.image_top.last p.readmore a {
  color: transparent; }

.node-type-sustainability .pane-bean-panels.image_top.last .tier .image-top-margin {
  margin: 0; }

.node-type-sustainability .pane-bean-panels.image_top .tier {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }
  .node-type-sustainability .pane-bean-panels.image_top .tier h2,
  .node-type-sustainability .pane-bean-panels.image_top .tier p.block-content {
    padding-left: 35px; }
  .node-type-sustainability .pane-bean-panels.image_top .tier a > p:first-child {
    padding: 0;
    margin: 0; }
  .node-type-sustainability .pane-bean-panels.image_top .tier img {
    width: 100% !important;
    max-width: 100%;
    height: auto !important; }

.node-type-sustainability .tier.block-toolarge {
  overflow: hidden;
  background-color: #FFFFFF !important;
  height: 401px; }
  .node-type-sustainability .tier.block-toolarge.tier2-image-block {
    height: 435px; }
  .node-type-sustainability .tier.block-toolarge img {
    width: 100% !important;
    max-width: 100%;
    height: auto !important; }
  .node-type-sustainability .tier.block-toolarge p {
    margin: 0;
    padding-left: 0;
    padding-right: 0; }

@media screen and (max-width: 767px) {
  .node-type-sustainability .tier.kering_fondation_block {
    text-align: center; } }

.node-type-sustainability .tier.image-block-slider {
  height: 435px; }
  @media screen and (max-width: 1023px) {
    .node-type-sustainability .tier.image-block-slider {
      height: 460px; } }
  .node-type-sustainability .tier.image-block-slider .block_content {
    display: none; }
  .node-type-sustainability .tier.image-block-slider p {
    font-family: 'Radley', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: black;
    padding: 0 35px 20px 35px; }

/* TALENT PAGE */
.node-type-talents .panels-flexible-row-panel_front_talents-3 {
  margin-top: 15px; }

.node-type-talents .tier {
  height: 260px; }
  .node-type-talents .tier.last {
    margin-bottom: 0; }

.node-type-talents .tier.news-block.tier-block1 {
  height: 535px; }

.node-type-talents .panels-flexible-column-panel_front_talents-6 .pane-block.talents_news .tier {
  height: 425px; }

.node-type-talents .panels-flexible-column-panel_front_talents-6 .pane-block.encourage_talents .tier {
  height: 370px; }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .node-type-talents .panels-flexible-column-panel_front_talents-6 {
    width: 100% !important;
    max-width: calc(92% - 15px) !important; }
    .node-type-talents .panels-flexible-column-panel_front_talents-6 .pane-block {
      float: left;
      width: 366px;
      max-width: calc(46% - 15px);
      margin-right: 15px; }
      .node-type-talents .panels-flexible-column-panel_front_talents-6 .pane-block.encourage_talents .tier, .node-type-talents .panels-flexible-column-panel_front_talents-6 .pane-block.talents_news .tier {
        height: 400px; } }

.node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video .tier.rubrique_video_size {
  margin: 0 auto;
  width: 100%;
  padding: 0 0 56.25% 0;
  /* 16:9 */
  position: relative;
  background: black;
  text-align: center; }
  .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video .tier.rubrique_video_size h2 {
    display: none; }
  .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video .tier.rubrique_video_size iframe,
  .node-type-talents .panels-flexible-panel_front_talents .panels-flexible-row-panel_front_talents-10 .panels-flexible-region-panel_front_talents-region_video .tier.rubrique_video_size .ppr-player {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-size: 24px;
    text-align: center; }

.node-type-talents .pane-block.pane-bean-ppr-rubrique-jobs-offers .tier.tier-half-height {
  height: 535px; }
  .node-type-talents .pane-block.pane-bean-ppr-rubrique-jobs-offers .tier.tier-half-height h2 {
    font-size: 27px;
    padding-bottom: 20px;
    padding-top: 35%; }
    @media screen and (max-width: 767px) {
      .node-type-talents .pane-block.pane-bean-ppr-rubrique-jobs-offers .tier.tier-half-height h2 {
        padding-top: 30px; } }
  .node-type-talents .pane-block.pane-bean-ppr-rubrique-jobs-offers .tier.tier-half-height p {
    padding-left: 35px;
    margin: 0;
    font-family: 'gotham-light', Helvetica, Arial, sans-serif;
    font-size: 15px; }

.node-type-talents .style1 {
  color: #000 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-family: 'Radley', 'alto_pro_lt', Helvetica, Arial, sans-serif !important;
  width: auto !important;
  padding-left: 35px;
  margin: 15px 0 0 0 !important; }

.node-type-talents .style2 {
  font-size: 12px !important;
  line-height: 1.5em !important;
  font-family: "gotham-book", Helvetica, Arial, sans-serif !important;
  width: auto !important;
  padding-left: 35px;
  margin: 15px 0 30px 0 !important; }

/*GROUP PAGE */
.i18n-fr.node-type-group .panels-flexible-row-panel_front_talents-3,
.i18n-en.node-type-group .panels-flexible-row-panel_front_talents-3 {
  margin-top: 15px; }

.i18n-fr.node-type-group .panels-flexible-region-panel_front_talents-region_video-inside .panel-separator,
.i18n-en.node-type-group .panels-flexible-region-panel_front_talents-region_video-inside .panel-separator {
  display: none; }

.i18n-fr.node-type-group .panels-flexible-panel_front_talents .panels-flexible-column-inside,
.i18n-en.node-type-group .panels-flexible-panel_front_talents .panels-flexible-column-inside {
  padding: 0; }

.i18n-fr.node-type-group .pane-bean-ppr-rubrique-lifestyle .tier.tier-half-height p > strong,
.i18n-en.node-type-group .pane-bean-ppr-rubrique-lifestyle .tier.tier-half-height p > strong {
  font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
  font-size: 16px; }

.i18n-fr.node-type-group .pane-content,
.i18n-en.node-type-group .pane-content {
  vertical-align: top; }

.i18n-fr.node-type-group .tier,
.i18n-en.node-type-group .tier {
  height: 465px; }

.i18n-fr.node-type-group .tier.news-block,
.i18n-en.node-type-group .tier.news-block {
  height: 472px; }

.i18n-fr.node-type-group .pane-bean-ppr-rubrique-inspiration .tier.tier-half-height,
.i18n-en.node-type-group .pane-bean-ppr-rubrique-inspiration .tier.tier-half-height {
  height: 210px; }

.i18n-fr.node-type-group .pane-bean-ppr-rubrique-creativity .tier.tier-half-height,
.i18n-en.node-type-group .pane-bean-ppr-rubrique-creativity .tier.tier-half-height {
  height: 240px; }

/*PRESS PAGE */
.node-type-press .panel-separator {
  display: none; }

.node-type-press .tier.pressreleases {
  height: 235px;
  background-color: black !important; }
  .node-type-press .tier.pressreleases h2 {
    color: #FFFFFF; }

.node-type-press .tier.news-one-top-block {
  height: 472px; }

.node-type-press .tier-half-height {
  height: 235px; }

.node-type-press #twitter-block {
  float: right;
  height: auto;
  position: relative;
  padding: 0; }
  @media screen and (max-width: 1023px) {
    .node-type-press #twitter-block {
      float: left; } }
  @media screen and (max-width: 767px) {
    .node-type-press #twitter-block {
      float: none;
      margin-right: auto; } }
  .node-type-press #twitter-block .pane-content {
    width: 366px;
    max-width: 100%;
    position: relative;
    right: 0; }
    @media screen and (max-width: 767px) {
      .node-type-press #twitter-block .pane-content {
        right: auto; } }
    .node-type-press #twitter-block .pane-content .tier {
      padding-top: 15px;
      width: 100%; }

/** finance ***/
.node-type-finance .panels-flexible-row-front-finance-3 .panels-flexible-row-inside {
  padding-top: 15px; }

.node-type-finance .panels-flexible-row-front-finance-3 #block-shareholder .tier {
  height: 472px; }

.node-type-finance .panels-flexible-row-front-finance-3 .tier.news-block {
  height: 435px; }

.node-type-finance .panels-flexible-row-front-finance-3 .quarter {
  width: 100%;
  height: 210px;
  background-color: #f8f7f7; }
  .node-type-finance .panels-flexible-row-front-finance-3 .quarter a {
    color: initial !important;
    text-decoration: none; }

.node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-contact .tier {
  height: 270px; }

.node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-contact p {
  margin-top: 0;
  padding-bottom: 0;
  line-height: 1em; }
  .node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-contact p.contact {
    margin-top: 20px; }

.node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-2011-reference-doc .quarter {
  padding-left: 0;
  background-color: #f8f7f7 !important; }
  .node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-2011-reference-doc .quarter h2 {
    padding-left: calc(43% + 20px); }
  .node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-2011-reference-doc .quarter img {
    float: left;
    max-width: 43%;
    width: auto;
    height: 210px !important; }
  .node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-2011-reference-doc .quarter p.link-in-block {
    padding-left: calc(43% + 20px); }

.node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-agenda .quarter p {
  font-family: 'radley', Helvetica, Arial, sans-serif; }
  .node-type-finance .panels-flexible-row-front-finance-3 .pane-bean-ppr-rubrique-agenda .quarter p + p {
    margin-top: 15px; }

.node-type-finance .panels-flexible-row-front-finance-3 .DesktopRupriquePack .tier p + p {
  margin-top: 10px;
  line-height: 1em; }

.node-type-finance .panels-flexible-row-front-finance-3 .DesktopRubriqueKey p.link-in-block + p.link-in-block {
  margin-top: 10px; }

.node-type-finance .panels-flexible-row-front-finance-3 p.link-in-block,
.node-type-finance .panels-flexible-row-front-finance-3 p.link-block a {
  border: none;
  color: #000;
  font-family: 'gotham-light', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 19px;
  margin-bottom: 0; }
  .node-type-finance .panels-flexible-row-front-finance-3 p.link-in-block + p,
  .node-type-finance .panels-flexible-row-front-finance-3 p.link-block a + p {
    margin-top: 0; }

.node-type-finance .panels-flexible-row-front-finance-3 .lien_talents {
  display: inline-block;
  font-family: 'Radley', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.2em !important;
  text-decoration: none; }
  .node-type-finance .panels-flexible-row-front-finance-3 .lien_talents:hover {
    background: #ebe9ea; }

.i18n-ja.node-landing-page .section-content .pane-bean-panels.tier-large .tier,
.i18n-zh-hans.node-landing-page .section-content .pane-bean-panels.tier-large .tier {
  height: 420px; }

.i18n-ja.node-landing-page .tier.rubrique_video_size .description,
.i18n-zh-hans.node-landing-page .tier.rubrique_video_size .description {
  display: none; }

.i18n-ja.node-type-group .section-content .pane-bean-panels.last,
.i18n-zh-hans.node-type-group .section-content .pane-bean-panels.last {
  clear: both; }

.i18n-ja .pane-ppr-rubrique-ppr-rubrique-news .tier,
.i18n-zh-hans .pane-ppr-rubrique-ppr-rubrique-news .tier {
  height: 420px; }

/**
 * @file
 * Views Styling
 */
/* ---------------- IMPORT -----------------*/
/* ---------------- CUSTOM -----------------*/
/* Biography */
.block-biographies .advanced-search-form {
  display: block; }

.block-biographies .views-row {
  background-color: #f7f8f8;
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 20px 10px 0 20px;
  position: relative;
  vertical-align: top;
  width: 381px;
  max-width: calc(50% - 15px);
  height: 185px !important;
  box-sizing: border-box; }
  @media screen and (max-width: 1023px) {
    .block-biographies .views-row {
      max-width: 100%;
      height: auto !important;
      margin-right: 0; } }

.block-biographies .item-list ul li {
  margin: 0;
  padding: 0;
  font-family: 'gotham-light', Helvetica, Arial, sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  color: black; }

.block-biographies .readmore a {
  display: none; }

.block-biographies .views-row-even {
  margin-right: 0; }

.block-biographies .view-content .views-comex .views-field {
  float: left;
  max-width: calc(100% - 115px); }
  @media screen and (max-width: 450px) {
    .block-biographies .view-content .views-comex .views-field {
      float: none;
      max-width: 100%; } }
  .block-biographies .view-content .views-comex .views-field.views-field.views-field-title-field {
    margin-top: 15px; }

.block-biographies .view-content .views-field {
  margin: 0;
  float: none;
  box-sizing: border-box; }
  .block-biographies .view-content .views-field.views-field-field-poste {
    margin-top: 10px;
    margin-bottom: 15px; }
    .block-biographies .view-content .views-field.views-field-field-poste div {
      font-family: 'gotham-book', Helvetica, Arial, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      color: black; }
  .block-biographies .view-content .views-field.views-field.views-field-title-field {
    margin-top: 30px; }
    .block-biographies .view-content .views-field.views-field.views-field-title-field h3 {
      font-family: 'gotham-black', Helvetica, Arial, sans-serif;
      font-weight: normal;
      font-size: 14px;
      text-transform: capitalize; }

.block-biographies .view-content .views-comex .views-field-field-image {
  display: block;
  width: 100px;
  margin-right: 15px;
  margin-top: 0;
  padding: 0;
  margin-bottom: 15px; }
  @media screen and (max-width: 450px) {
    .block-biographies .view-content .views-comex .views-field-field-image {
      width: 100%; } }
  .block-biographies .view-content .views-comex .views-field-field-image > p {
    line-height: 0;
    margin: 0;
    padding: 0; }
  .block-biographies .view-content .views-comex .views-field-field-image img {
    max-width: 100%;
    height: auto; }
