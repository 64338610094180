/**
 * @file
 * Default Mega Menu Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.mega-menu {
  .mega-menu--list {
    .mega-menu--item {
      .mega-menu--item--link {
      }
      & > button {
      }
    }
  }
}

/* ---------------- CUSTOM -----------------*/

