/**
 * @file
 * Styles for system messages and progress bar.
 */

/* ---------------- Top of page link  -----------------*/
.top-of-page {
  a {
  }
}

/* ---------------- Message Styling -----------------*/
.message {
  & + .message {
  }
}

.messages__list {
}

.messages__item {
  & + .messages__item {
  }
}

.messages--status {
}

.messages--warning {
}

.messages--error {
  p.error {
  }
}

/* ---------------- Progress Bar Styling -----------------*/
.progress {
}

.progress__label {
}

.progress__track {
}

.progress__bar {
}

.progress__percentage {
}

.progress__description {
}

.ajax-progress {
  .throbber {
    tr & {
    }
  }
  .message {
  }
}

/* ---------------- Alert Styling -----------------*/
.alert-sm {
}

.alert a {
}

.alert-success {
}

.alert-info {
  a, a:hover, a:focus {
  }
}

.alert-warning {
  a, a:hover, a:focus {
  }
}

.alert-danger {
  a, a:hover, a:focus {
  }
}