/**
 * @file
 * Breadcrumb Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/

/* Breadcrumb */
.breadcrumb {
  padding: 30px 0 0 0;
  height: 80px;
  box-sizing: border-box;
  white-space: nowrap;
  @media screen and (max-width: 1023px) {
    padding: 20px 0 0 0;
    height: 60px;
  }
  ul {
    padding: 0;
    li {
      display: inline;
      font-size: 1em;
      line-height: 1em;
      border-right: #676767 1px solid;
      padding: 0 5px;
      font-weight: bold;
      &.first {
        padding: 0 5px 0 0;
      }
      &:last-child {
        color: #676767;
      }
      a {
        color: #676767;
        font-weight: normal;
        &.active {
          color: #333333;
          text-decoration: none;
          font-weight: bold;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
  div {
    color: #b4b4b8;
    border-right: 1px solid #a7a8ac;
    display: inline;
    font-size: 12px;
    padding: 0 7px;
    &.first {
      padding: 0 5px 0 0;
    }
    a {
      color: #676767;
      font-weight: normal;
    }
  }
}

/* Page customization */
.node-type-finance .breadcrumb ul li a {
  .breadcrumb ul li a {
    color: #676767;
    &.active {
      color: #333333;
    }
  }
}

.finance-blue {
  .breadcrumb {
    ul {
      li {
        border-color: #fff;
        a {
          color: #fff;
          &.active {
            color: #fff;
          }
        }
        &:last-child {
          color: #FFFFFF;
        }
      }
    }
  }
}

.node-type-press, .node-type-group, .node-type-brands, .node-type-talents {
  .breadcrumb ul li {
    border-color: #fff;
    a, a.active {
      color: #fff;
    }
    &:last-child {
      color: #FFFFFF;
    }
  }
}

.node-type-group .featured-content,
.node-type-brands .pane-bean-rubrique-brands,
.node-type-finance .pane-bean-ppr-rubrique-finance,
.node-type-sustainability .pane-bean-rubrique-sustainability,
.node-type-press .pane-bean-rubrique-press,
.node-type-talents .pane-bean-rubrique-talents {
  .breadcrumb {
    display: none;
  }
}

.node-type-press-release {
  .breadcrumb {
    display: block;
  }
}

.node-type-search {
  .article-item {
    .breadcrumb {
      position: relative;
      top: inherit;
      left: inherit;
      .last {
        margin-left: 5px;
      }
    }
  }
}

.region-main-block {
  .breadcrumb {
    span.last {
      color: #000;
      font-family: gotham-book;
      font-size: 12px;
      padding: 0 0 0 5px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}


/*
.node-type-finance .breadcrumb ul li a.active {
  color: #333333;
}
.breadcrumb ul {
  padding: 0
}
.breadcrumb ul li {
  display: inline;
  font-size: 1em;
  border-right: #676767 1px solid;
  padding-right: 5px;
}

.breadcrumb ul li a {
  color: #676767;
}

.breadcrumb ul li a.active {
  color: #333333;
}

.breadcrumb ul li:last-child {
  border: none;
}

.breadcrumb ul li a.active {
  text-decoration: none;
  font-weight: bold;
}*/
/*
.finance-blue .breadcrumb ul li a.active {
  color: #fff;
}
*/
/*
.node-type-press .breadcrumb ul li a,
.node-type-group .breadcrumb ul li a,
.node-type-brands .breadcrumb ul li a,
.node-type-talents .breadcrumb ul li a,
.node-type-press .breadcrumb ul li a.active,
.node-type-brands .breadcrumb ul li a.active,
.node-type-group .breadcrumb ul li a.active,
.node-type-talents .breadcrumb ul li a.active {
  color: #fff;
}
/*
.node-type-press .breadcrumb ul li,
.node-type-brands .breadcrumb ul li,
.node-type-group .breadcrumb ul li,
.node-type-talents .breadcrumb ul li,
.finance-blue .breadcrumb ul li {
  border-color: #fff;
}
*/

/*
.node-type-group .featured-content .breadcrumb,
.node-type-brands .pane-bean-rubrique-brands .breadcrumb,
.node-type-finance .pane-bean-ppr-rubrique-finance .breadcrumb,
.node-type-sustainability .pane-bean-rubrique-sustainability .breadcrumb,
.node-type-press .pane-bean-rubrique-press .breadcrumb,
.node-type-talents .pane-bean-rubrique-talents .breadcrumb {
  display: none;
}*/

/*
.node-type-search .article-item .breadcrumb .last {
  margin-left: 5px;
}

.breadcrumb {
  padding: 0;
  position: absolute;
  top: 39px;
  left:31px;
}*/

/*
.breadcrumb ul li {
  padding: 0 5px;
}

.breadcrumb ul li.first {
  padding: 0 5px 0 0;
}

.breadcrumb ul li:last-child {
  color: #676767;
}
*/

/*
.breadcrumb ul li a:hover {
  text-decoration:none;
}*/

/*
.breadcrumb li {
  font-weight:bold;
}
.breadcrumb li a {
  font-weight:normal;
}
.breadcrumb div {
  color: #b4b4b8;
  border-right: 1px solid #a7a8ac;
  display: inline;
  font-size: 12px;
  padding: 0 7px;
}

.breadcrumb div.first {
  padding: 0 5px 0 0;
}.region-main-block .breadcrumb span.last {
   color: #000;
   font-family: gotham-book;
   font-size: 12px;
   line-height: 12.5px;
   padding: 0 5px;
 }

.breadcrumb div a {
  color: #676767;
  font-weight: normal;
}*/
/* ---------------- CUSTOM  -----------------*/