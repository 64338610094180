/**
 * @file
 * Default Menu Styling
 */

/* ---------------- DEFAULT -----------------*/
/*
 * Menu
 */
/* General */
#MobileRubriqueBourse,
.MobileRubriqueKey,
.MobileRubriquePack,
#MobileRubriqueAgenda,
#MobileRubriqueRefDoc,
#MobileRubriqueContact,
#MobileRubriqueAlerte,
#MobileRubriqueNews {
  display: none;
}

#block-ppr-header-footer-refonte-header-footer-top-menu {
  .content {
    ul {
      li {
        margin: 0;
        display: table-cell;
        padding: 0 16px 25px;
        text-align: center;
        a {
          font-family: 'gotham-medium', Helvetica, Arial, sans-serif;
          font-size: 14px;
          text-transform: uppercase;
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: 12px;
          }
        }
        &.kmag {
          a.selectedMenu {
            background: none;
          }
        }
        &.leaf {
          a {
            letter-spacing: 1px;
          }
        }
        &.group {
          ul {
            background-image: url("../images/menu_illustration.png");
          }
        }
        &.brand {
          ul {
            background-image: url("../images/brand_illustration.png");
          }
        }
        &.sustainability {
          ul {
            background-image: url("../images/sustainability_illustration.png");
          }
        }
        &.finance {
          ul {
            background-image: url("../images/finance_illustration.png");
          }
        }
        &.talent {
          ul {
            background-image: url("../images/menu_illustration.png");
          }
        }
        &.press {
          ul {
            background-image: url("../images/press_illustration.png");
          }
        }
        ul {
          background: no-repeat right top;
        }
      }
      /*a.selectedMenu {
        background: url("../images/arrow-menu.png") no-repeat center bottom;
        padding-bottom: 25px;
      }*/
    }
  }
  ul.contextual-links {
    display: none;
  }
  .content ul.menu {
    li {
      ul.maintainHover {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        width: auto;
        background-color: #fff;
        border-top: 1px solid #000;
        z-index: 1000;
        display: block;
        margin: 0;
        min-height: 330px;
        padding: 48px 10% 35px 90px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
         @media screen and(max-width: 1023px) {
           padding-left: 50px;
           padding-right: 50px;
         }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          left: 65px;
        }
        li {
          position: relative;
          text-align: left;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          @media screen and (min-width: 768px) {
            display: inline-block;
            vertical-align: top;
            width: 32%;
            margin: 0 0 35px 0;
            padding: 0 15px 0 0;
            font-size: 20px;
          }
          &.about-kering {
            width: 133px;
          }
          &.management {
            margin-left: 138px;
            min-height: 42px;
            width: 157px;
          }
          &.history {
            margin-left: 122px;
            min-height: 42px;
            width: 132px;
          }
          &.activity-report {
            width: 119px;
          }
          &.kering-partnership {
            margin-left: 151px;
            width: 251px;
          }
          &.luxury {
            width: 556px;
            ul {
              li {
                display: inline-block;
                width: 266px;
              }
            }
          }
          &.sport-lifestyle {
            margin-left: 0;
            width: 244px;
          }
          &.news {
            width: 135px;
          }
          &.video {
            margin-left: 136px;
            width: 165px;
          }
          &.recognition {
            margin-left: 110px;
          }
          &.epl-menu {
            margin-bottom: 15px;
          }
          &.action {
            margin-left: 39px;
          }
          &.stakeholders {
            margin-bottom: 72px;
          }
          &.governance {
            margin-bottom: 85px;
          }
          &.target {
            margin-bottom: 95px;
            margin-left: 37px;
            width: 255px;
          }
          &.foundation {
            margin-left: 36px;
          }
          ul {
            background-image: none !important;
            border-top: inherit;
            display: block;
            height: inherit;
            left: inherit;
            margin: .6em 0 0;
            padding: inherit;
            position: relative;
            top: 0;
            width: 100%;
            li {
              display: inherit;
              float: inherit;
              font-size: inherit;
              margin: 0;
              min-height: inherit;
              padding: inherit;
              text-align: left;
              width: inherit;
              a {
                font-family: 'gotham-book', Helvetica, Arial, sans-serif;
                font-size: 11px;
                line-height: 23px;
                &:hover {
                  font-family: 'gotham-ultra', Helvetica, Arial, sans-serif;
                  color: #000;
                }
              }
            }
          }
        }
      }
      & > ul.menu  {
        @media screen and (max-width: 767px) {
          display: none;
          position: static;
        }
          a {
            font-size: 18px;
            line-height: 21px;
            @media screen and (max-width: 767px) {
              color: #cacaca;
              font-size: 14px !important;
              line-height: 18px !important;
            }
            &:hover {
              color: #cacaca;
              @media screen and (max-width: 767px) {
                font-size: 14px !important;
                line-height: 18px !important;
              }
            }
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              font-size: 13px;
            }
            & + ul {
              @media screen and (max-width: 767px) {
                display: none;
                position: static;
              }
              li {

                a {
                  @media screen and (max-width: 767px) {
                    font-family: gotham-medium;
                    color: #000000;
                    font-size: 11px !important;
                    line-height: 18px !important;
                    text-transform: none !important;
                    padding: 0 0 15px 0 !important;
                  }
                  &:hover {
                    @media screen and (max-width: 767px) {
                      font-family: gotham-medium;
                      font-size: 11px !important;
                      line-height: 18px !important;
                      text-transform: none !important;
                      padding: 0 0 15px 0 !important;
                    }
                  }
                }
              }
            }
          }
      }
      ul {
        display: none;
        li {
          ul {
            li {
              display: block;
            }
          }
        }
      }
    }
    #menu-talent {
      ul.maintainHover {
        li {
          padding: 0 20px 0 0;
        }
      }
    }
    #menu-sustainability {
      & > a {
        white-space: nowrap;
      }
      ul.maintainHover {
        li {
          float: left;
        }
      }
    }
  }
  #menu-luxury {
    float: left;
    margin: 0 0 15px;
    width: 69%;
    ul {
      li {
        float: left;
        width: 50%;
      }
    }
  }
  #menu-sport-lifestyle {
    margin: 0 0 4.6em 0;
    width: 31%;
  }
  #menu-kering-eyewear {
    width: 31%;
  }
}
#block-ppr-header-footer-refonte-header-footer-top-menu {
  .content ul {
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      text-align: left;
    }
    @media screen and (min-width: 769px) and (max-width: 1023px) {
      margin-top:0;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
    li {
      margin: 0;
      display: table-cell;
      padding: 0 16px 25px;
      /* FR */
      text-align: center;
      @media screen and (min-width: 768px) and (max-width: 1279px) {
        padding-left: 0;
        padding-right: 0;
        width: 17%;
      }
      a {
        font-size: 14px;
        @media screen and (min-width: 1024px) and (max-width: 1175px) {
          font-size: 12px;
        }
        &.selectedMenu {
          background: url("../images/arrow-menu.png") no-repeat center bottom;
          padding-bottom: 25px;
          @media screen and (max-width: 767px) {
            background: none;
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}

.i18n-ja {
  #block-ppr-header-footer-refonte-header-footer-top-menu {
    ul {
      a.selectedMenu {
        background: none;
        padding-bottom: auto;
      }
      li.leaf {
        a {
          white-space: nowrap;
        }
      }
    }
  }
  #block-ppr-header-footer-refonte-header-footer-search-top {
    display: none;
  }
}

.i18n-en {
  #block-ppr-header-footer-refonte-header-footer-top-menu {
    ul.menu {
      li ul.maintainHover {
        li.governance {
          margin-bottom: 120px;
        }
      }
    }
  }
}


.i18n-en #block-ppr-header-footer-refonte-header-footer-top-menu ul.menu li ul.maintainHover li.governance {
  margin-bottom: 120px;
}
.i18n-zh-hans, .i18n-ja {
  header .header-wrapper #block-ppr-header-footer-refonte-header-footer-top-menu .content ul li a.selectedMenu {
    background: none;
    padding-bottom: 0;
  }
}