/**
 * @file
 * Branding Styling
 */

/* ---------------- DEFAULT -----------------*/
.site-logo {
}

.site-name {
}

.site-slogan {
}

/* ---------------- CUSTOM  -----------------*/

