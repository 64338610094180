/**
 * @file
 * Nodes Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.node {
}

.node--promoted {
}

.node--sticky {
}

.node--unpublished {
}

.node__meta {
}

.node__submitted {
}

.node__content {
}

/* ---------------- CUSTOM -----------------*/
/* .node--type-[node.bundle] { }
 .node--view-mode-[view_mode] { }
 */
.node-type-brand {
  .main-wrapper > .section.firstsection {
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
    .content-wrapper article {
      @media screen and (max-width: 767px) {
        width: 100% !important;
        max-width: 100%;
      }
      .block-content-article {
        max-width: 100% !important;
        .article-item-wrapper {
          @media screen and (max-width: 767px) {
            background: #ffffff url("../images/background_1025.png") repeat-y;
            background-position: -45px 0 !important;
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.node-type-search {
  .block-content-article {
    background: #fff;
    font-size: 14px;
    line-height: 22px;
    padding: 0;
    margin: 15px auto;
    width: 900px;
    max-width: 100%;
    .form-wrapper {
      background: #f1f1f1;
      padding: 30px 60px 15px;
      font-size: 1.1em;
      @media screen and (max-width: 767px) {
        padding: 15px 30px 10px;
      }
    }
  }
  .article-item {
    .breadcrumb {
      position: relative;
      top: inherit;
      left: inherit;
      padding: 0;
      height: auto;
      white-space: normal;
    }
    .block-content-description {
      font-size: 15px;
      line-height: 26px;
      font-family: Radley, Verdana, Arial, sans-serif;
      color: #666;
    }
  }
  .article-item-wrapper {
    padding-left: 30px;
    padding-top: 30px;
    max-width: 90%;
    .article-item {
      border-bottom: #fff 21px solid;
      color: #7f7f7F;
      padding-left: 52px;
      &.clearfix.views-row {
        border-bottom: 1px solid #7f7f7f;
        color: #7f7f7f;
        padding: 15px 20px;
      }
    }
  }
}

.page-search-content article p.readmore.return-link {
  width: 862px;
  text-align: center;
  padding-left: 0;
  margin: 30px auto;
}

.node-type-custom-page {
  .article-item-wrapper {
    img {
      max-width: 100%;
    }
  }
}

.node-type-filter-achievements .block-content-article .article-item-wrapper ul.achievements-list li {
  background: #f8f7f7;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  width: calc(33% - 15px);
  height: 250px;
  max-width: 200px;
  overflow: hidden;
  &:hover {
    background-color: #333333;
    .views-field-title-field {
      background-color: #333333;
    }
  }
  .views-field-field-content {
    padding: 0 15px 15px 15px;
    width: calc(100% - 30px);
    word-break: break-all;
  }
  @media screen and (max-width: 767px) {
    width: calc(50% - 15px);
  }
  @media screen and (max-width: 450px) {
    width: calc(100% - 15px);
  }
  .views-field-field-image {
    a {
      display: block;
      height: 100%;
    }
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.page-sustainability-news,
.page-press-releases,
.page-dev-durable-actualite,
.page-communiques-de-presse {
  .block-content-article {
    .view-content {
      margin-top: 30px;
      margin-bottom: 60px;
      .views-row {
        background-color: #f7f8f8;
        display: inline-block;
        box-sizing: content-box;
        margin-bottom: 15px;
        margin-right: 15px;
        overflow: hidden;
        padding: 0 30px 220px 30px;
        position: relative;
        vertical-align: top;
        width: 265px;
        max-width: calc(50% - 80px);
        @media screen and (max-width: 850px) {
          display: block;
          width: 265px;
          max-width: calc(92% - 15px);
          margin: 0 auto 15px auto;
        }
        .category,
        .brand {
          color: #000;
          display: inline;
          font-family: 'gotham-book', Helvetica, Arial, sans-serif;
          font-size: 11px;
          text-transform: uppercase;
          margin-right: 5px;
        }
        ul.article-info {
          display: inline;
        }
        .brand {
          font-family: 'gotham-bold', Helvetica, Arial, sans-serif;
        }
      }
    }
    .views-field-field-image {
      img {
        bottom: 36px;
        height: auto;
        max-width: 100%;
        overflow: visible;
        position: absolute;
        width: calc(100% - 30px);
      }
    }
    #views_infinite_scroll-ajax-loader {
      margin-top: -60px;
      position: absolute;
      text-align: center;
      width: 100%;
    }
    .search-result-footer {
      position: relative;
      margin-top: -30px;
      p.readmore {
        text-align: center;
      }
      #readmore_link {
        display: inline-block;
        border: 1px #000000 solid;
        padding: 0.5em;
        &:hover {
          color: #6b6b6b;
          border: 1px #6b6b6b solid;
        }
      }
    }
  }
  article {
    .view-display-id-press_release_mise_avant {
      background-color: black;
      width: calc(100% - 15px);
      padding-right: 15px;
      margin-right: 15px;
      margin-top: 30px;
      @media screen and (max-width: 1023px) {
        padding-right: 0;
        max-width: 100%;
        margin: 30px 0 0 0;
      }
      img {
        padding-right: 0;
        float: left;
        width: 400px;
        max-width: 60%;
        height: auto;
        @media screen and (max-width: 1023px) {
          display: block;
          float: none;
          margin: auto;
          max-width: 100%;
          padding: 0;
          width: 100%;
        }
      }
    }
    .featured-block {
      padding-right: 0;
      .featured-block-content {
        float: left;
        width: 275px;
        max-width: calc(40% - 20px);
        margin-left: 20px;
        margin-bottom: 30px;
        position: relative;
        min-height: 305px;
        @media screen and (max-width: 1023px) {
          float: none;
          margin-left: auto;
          margin-right: auto;
          width: 90%;
          max-width: 90%;
          min-height: auto;
        }
      }
    }
  }

}