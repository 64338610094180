/**
 * @file
 * File Component Styling - Default style for file module.
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.file {
  > span {
    &:first-child {
    }
    &:last-child {
    }
  }
  > .tabledrag-changed {
    &, &:last-child {
    }
  }
}

.file-size {
}

.image-widget.row {
}

// File by type
.file--general,
.file--application-octet-stream {
}

.file--package-x-generic {
}

.file--x-office-spreadsheet {
}

.file--x-office-document {
}

.file--x-office-presentation {
}

.file--text-x-script {
}

.file--text-html {
}

.file--text-plain {
}

.file--application-pdf {
}

.file--application-x-executable {
}

.file--video {
}

.file--text {
}

.file--image {
}

/* ---------------- CUSTOM  -----------------*/