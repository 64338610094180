/**
 * @file
 * Main Layout Styling
 */
/* ---------------- DEFAULT -----------------*/
#content-toolbar {
  position: absolute;
  right: 0;
  top: auto;
  padding: 30px 1em 0.5em 1em;
  z-index: 99;
  @media screen and (max-width: 1439px) {
    position: absolute;
    clear: both;
    max-width: 691px;
    width: 68%;
    text-align: left;
    right: auto;
    box-sizing: border-box;
    margin: 0;
    margin-left: 335px;
    padding: 30px 0;
    #block-ppr-header-footer-header-footer-share {
      float: right;
      display: block;
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 1023px) {
    margin-left: 265px;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 0.5em 0 0 0;
    margin: 1em auto 0 auto;
    position: relative;
    width: 80%;
  }
  .block {
    float: none;
    line-height: 33px;
    @media screen and (max-width: 1023px) {
      display: inline-block;
    }
  }
  &:after {
    content: "";
    clear: both;
    display: block;
    height: 0;
    width: 0;
  }
  #block-ppr-header-footer-header-footer-zoom,
  #block-ppr-header-footer-header-footer-speaker {
    display: inline;
    .content {
      display: inline;
    }
  }
  .rsbtn {
    display: inline;
  }
}