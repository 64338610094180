/**
 * @file
 * Pager Styling
 */

/* ---------------- IMPORT -----------------*/
// Site variables
//@import "../0-init/init";

/* ---------------- DEFAULT -----------------*/
.pager {
}

.pager__items {
}

.pager__item {
  &.is-active {
  }
  &.pager__item--next {
  }
  &.pager__item--last {
  }
  a {
  }
}

/* ---------------- CUSTOM  -----------------*/