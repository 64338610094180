/**
 * @file
 * header Styling
 */

/* ---------------- DEFAULT -----------------*/
header {
  margin: auto;
  position: relative;
  width: 1440px;
  max-width: 100%;
  background-color: #fff;
  @media screen and (max-width: 767px) {
    ul.menu {
      li {
        padding: 0 16px 25px;
      }
    }
  }
  div.header-toolbar {
    top: 25px;
    text-align: left;
    ul {
      li.header-toolbar-search {
        background-position: -56px 10px;
        margin: 0 5px 0 -30px;
        height: 36px;
        width: 36px;
        border: 1px solid #B2B2B2;
        &.activesearch {
          margin-bottom: -12px;
          height: 50px;
          width: 38px;
          border: none;
          background: #f1f1f1 -55px 11px;
        }
        ul.header-toolbar-search-wrapper {
          position: absolute;
          display: block;
          top: 50px;
          padding: 0;
          li {
            background: #f1f1f1;
            display: block;
            height: auto;
            margin: 0 auto;
            padding: 5px 0;
            position: relative;
            right: 400px;
            text-align: right;
            width: 424px;
          }
        }
      }
    }
  }
  .language_switcher {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      ul {
        li.header-toolbar-language-switcher {
          ul {
            li {
              margin: 0 0 0 10px;
              a {
                font-size: 15px;
                padding: 12px 4px;
              }
            }
          }
        }
      }
    }
  }
}
.header-wrapper {
  position: relative;
  max-width: 100%;
  height: 168px;
  @media screen and (max-width: 1439px) {
    height: 130px;
  }
  @media screen and (max-width: 767px) {
    height: 80px;
  }
  i18n-ja &,
  .i18n-zh-hans & {
    @media screen and (max-width: 767px) {
      height: 120px;
    }
  }
}
.region-header {
  display: block;
  box-sizing: border-box;
  margin-left: 165px;
  margin-right: 375px;
  padding-top: 75px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
  background-color: #fff;
  @media screen and (min-width: 1024px)  and (max-width: 1439px) {
    margin-right: 290px;
    margin-left: 145px;
    padding-top: 50px;
  }
  @media screen and (max-width: 1023px) {
    padding: 30px 0 0 0;
    width: 100%;
    margin: 0;
    ul.menu {
      margin-bottom: 0;
      margin-top: 0;
      display: table;
      width: 100%;
      text-align: center;
      padding: 0;
      li {
        padding: 0 0 25px;
        a {
          font-size: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin: 0;
    box-sizing: border-box;

  }
  @media screen and (max-width: 767px) {
    padding-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding-left: 0;
  }
}
.logo {
  float: left;
  display: block;
  height: inherit;
  margin-left: 15px;
  width: 150px;
  padding-top: 60px;
  box-sizing: border-box;
  @media screen and (min-width: 1024px)  and (max-width: 1439px) {
    padding-top: 45px;
    width: 130px;
  }
  @media screen and (max-width: 1023px) {
    width: 150px;
    padding-top: 50px;
    margin-left: 50px;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    z-index: 800;
    padding-top: 20px;
    width: 130px;
    margin-left: calc((100% - 160px) / 2);
  }
  img {
    vertical-align: middle;
    width: 100% !important;
    max-width: 100%;
    -webkit-backface-visibility: hidden;
    height: auto;
    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }
  }
}
.region-header-right {
  float: right;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 375px;
  background: #000000;
  z-index: 1000;
  height: 168px;
  @media screen and (max-width: 1439px) {
    height: 130px;
    right: -75px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 0;
  }
  @media screen and (max-width: 767px) {
    right: 0;
    height: 80px;
    width: 80px;
  }
  .i18n-ja &,
  .i18n-zh-hans & {
    @media screen and (max-width: 767px) {
      display: none;
      height: 100px;
    }
  }
}

.i18n-ja {
  header {
    .logo {
      img {
        margin-top: 0;
      }
    }
  }
  #search {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .i18n-zh-hans header .header-wrapper .logo img {
    margin-top: -12px;
  }
}

.i18n-fr {
  header div#block-ppr-header-footer-header-footer-stock .stock-block {
    margin-right: 20px;
  }
}

.i18n-zh-hans, .i18n-ja {
  header .header-wrapper {
    #block-ppr-header-footer-refonte-header-footer-top-menu {
      ul li {
        margin-left: 0;
      }
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        ul > li {
          &:first-of-type {
            margin-left: 0;
          }
          margin-left: 0;
          > a {
            font-size: 10px

          }
        }
      }
    }

    #block-ppr-header-footer-header-footer-header-socials {
      right: -150px;
    }
    #block-ppr-header-footer-refonte-header-footer-lg-switch {
      right: -270px;
    }
    #block-ppr-header-footer-refonte-header-footer-search-top {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .i18n-ja {
    header {
      .logo img {
        margin-top: 0;
      }
      .region-header-right {
        height: 79px;
        width: 80px;
        background: #000 640px 0;
      }
      .region-header {
        padding-top: 0;
      }
    }
    .region-header {
      width: 100%;
    }
    #block-ppr-header-footer-refonte-header-footer-top-menu {
      ul.menu li {
        display: block;
        width: 100%;
        &.facebook, &.twitter, &.instagram, &.youtube {
          display: none;
        }
      }
      .social ul.menu li {
        display: inline-block;
        width: 16%;
      }
    }
  }
  .i18n-zh-hans {
    header {
      .logo {
        top: 10px;
        position: absolute;
      }
      #block-ppr-header-footer-refonte-header-footer-search-top {
        display: block;
      }
    }
    .region-header {
      width: 100%;
    }
    .block-ppr-header-footer-refonte-header-footer-top-menu {
      ul.menu li {
        display: block;
        width: 100%;
        &.facebook, &.twitter, &.instagram, &.youtube {
          display: none;
        }
      }
      .social {
        ul.menu li {
          display: inline-block;
          width: 16%;
        }
      }
    }

  }
}

/*
#block-ppr-header-footer-refonte-header-footer-top-menu,
#block-ppr-header-footer-refonte-header-footer-search-top,
#block-ppr-header-footer-refonte-header-footer-lg-switch,
#block-ppr-header-footer-header-footer-stock {
  display: block;
}

#block-ppr-header-footer-refonte-header-footer-top-menu ul {
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}
#block-ppr-header-footer-header-footer-header-socials ul li{
  margin: 0 0 0 24px;
  padding: 0;
}

#block-ppr-header-footer-header-footer-header-socials ul li a {
  background-image: url("../images/kering-refonte-sprite.png");
  color: transparent;
  display: block;
  height: 30px;
  text-indent: -9999px;
  width: 26px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.facebook a {
  background-position: 295px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.twitter a {
  background-position: 190px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.sinaweibo a {
  background-position: 408px 348px;
  height: 24px;
  padding-top: 0;
  width: 41px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.youku a {
  background-position: 332px 242px;
  height: 24px;
  width: 41px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.instagram a {
  background-position: 242px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.linkedin a {
  background-position: 137px 107px;
}

#block-ppr-header-footer-header-footer-header-socials ul li.youtube a {
  background-position: 84px 107px;
}*/

/* End of Sub menu ---------*/
/* ---------------- MOBILE -----------------*/

/* ---------------- TABLET -----------------*/

/* ---------------- DESKTOP -----------------*/

