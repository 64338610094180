/**
 * @file
 * Font Variables
 */

@font-face {
  font-family: 'altopro_con_regular';
  src: url('../fonts/altoprocon-normal-webfont.eot');
  src: url('../fonts/altoprocon-normal-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/altoprocon-normal-webfont.woff') format('woff'), url('../fonts/altoprocon-normal-webfont.ttf') format('truetype'), url('../fonts/altoprocon-normal-webfont.svg#alto_pro_con_norregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'alto_pro_thin';
  src: url('../fonts/altopro-thin-webfont.eot');
  src: url('../fonts/altopro-thin-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/altopro-thin-webfont.woff') format('woff'), url('../fonts/altopro-thin-webfont.ttf') format('truetype'), url('../fonts/altopro-thin-webfont.svg#alto_pro_thregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'alto_pro_lt';
  src: url('../fonts/altopro-light-webfont.eot');
  src: url('../fonts/altopro-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/altopro-light-webfont.woff') format('woff'), url('../fonts/altopro-light-webfont.ttf') format('truetype'), url('../fonts/altopro-light-webfont.svg#alto_pro_ltregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SimSun';
  src: url('../fonts/SimSun.eot');
  src: url('../fonts/SimSun.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimSun.woff') format('woff'),
  url('../fonts/SimSun.ttf') format('truetype'),
  url('../fonts/SimSun.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD;
}

@font-face {
  font-family: 'keringregular';
  src: url('../fonts/kering-regular-webfont.eot');
  src: url('../fonts/kering-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kering-regular-webfont.woff2') format('woff2'),
  url('../fonts/kering-regular-webfont.woff') format('woff'),
  url('../fonts/kering-regular-webfont.ttf') format('truetype'),
  url('../fonts/kering-regular-webfont.svg#keringregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'altopro';
  src: url('../fonts/AltoPro-Normal.eot');
  src: url('../fonts/AltoPro-Normal.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AltoPro-Normal.woff2') format('woff2'),
  url('../fonts/AltoPro-Normal.woff') format('woff'),
  url('../fonts/AltoPro-Normal.ttf') format('truetype'),
  url('../fonts/AltoPro-Normal.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-black';
  src: url('../fonts/gotham-black.eot');
  src: url('../fonts/gotham-black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-black.woff') format('woff'),
  url('../fonts/gotham-black.ttf') format('truetype'),
  url('../fonts/gotham-black.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-ultra';
  src: url('../fonts/gotham-ultra.eot');
  src: url('../fonts/gotham-ultra.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-ultra.woff') format('woff'),
  url('../fonts/gotham-ultra.ttf') format('truetype'),
  url('../fonts/gotham-ultra.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url('../fonts/SourceHanSansCN-Bold.otf');
  src: url('../fonts/SourceHanSansCN-Bold.otf?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url('../fonts/SourceHanSansCN-Normal.otf');
  src: url('../fonts/SourceHanSansCN-Normal.otf?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-book';
  src: url('../fonts/gotham-book.eot');
  src: url('../fonts/gotham-book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-book.woff') format('woff'),
  url('../fonts/gotham-book.ttf') format('truetype'),
  url('../fonts/gotham-book.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-bold';
  src: url('../fonts/gotham-bold.eot');
  src: url('../fonts/gotham-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-bold.woff') format('woff'),
  url('../fonts/gotham-bold.ttf') format('truetype'),
  url('../fonts/gotham-bold.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-medium';
  src: url('../fonts/gotham-medium.eot');
  src: url('../fonts/gotham-medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-medium.woff') format('woff'),
  url('../fonts/gotham-medium.ttf') format('truetype'),
  url('../fonts/gotham-medium.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-light';
  src: url('../fonts/gotham-light.eot');
  src: url('../fonts/gotham-light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-light.woff') format('woff'),
  url('../fonts/gotham-light.ttf') format('truetype'),
  url('../fonts/gotham-light.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-book-italic';
  src: url('../fonts/gotham-book-italic.eot');
  src: url('../fonts/gotham-book-italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham-book-italic.woff') format('woff'),
  url('../fonts/gotham-book-italic.ttf') format('truetype'),
  url('../fonts/gotham-book-italic.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Radley';
  src: url('../fonts/Radley-Regular.eot');
  src: url('../fonts/Radley-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Radley-Regular.woff') format('woff'),
  url('../fonts/Radley-Regular.ttf') format('truetype'),
  url('../fonts/Radley-Regular.svg#radleyregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AdobeFanHeitiStd-Bold';
  src: url('../fonts/AdobeFanHeitiStd-Bold.eot');
  src: url('../fonts/AdobeFanHeitiStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AdobeFanHeitiStd-Bold.woff') format('woff'),
  url('../fonts/AdobeFanHeitiStd-Bold.ttf') format('truetype'),
  url('../fonts/AdobeFanHeitiStd-Bold.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AdobeHeitiStd-Regular';
  src: url('../fonts/AdobeHeitiStd-Regular.eot');
  src: url('../fonts/AdobeHeitiStd-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AdobeHeitiStd-Regular.woff') format('woff'),
  url('../fonts/AdobeHeitiStd-Regular.ttf') format('truetype'),
  url('../fonts/AdobeHeitiStd-Regular.svg#altopro') format('svg');
  font-weight: normal;
  font-style: normal;
}
